import { API, Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap"

import Loader from "../../components/Loader/Loader";
import "./PlanSelection.scss";

const PlanSelection = () => {
    const dispatch = useDispatch();
    const { route } = useAuthenticator((context) => [context.route]);

    const checkout = async (plan) => {
        const apiName = "CrewbiteAPI";
        const path = "subscription-checkout-session";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                plan
            }
        }
        try {
            const response = await API.post(apiName, path, myInit)
            window.location.href = response.session_url
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
        <Helmet>
            <title>Plan Selection - Crewbite Inc.</title>
        </Helmet>

        {route === 'authenticated' ? (
        <Container fluid className="plan-selection-container">
            <Row className="first-row">
                <>
                    <h1 className="title">Subscription</h1>
                    <p>15-days free trial for any plans with 100% money back guarantee. No contract needed.</p>
                </>
            </Row>
            <Row className="plan-selection-row">

                <div  className="freemium">
                    <h5>BASIC</h5>
                    <div className="image"><img alt="Crewbite freemium suscription plan" src={require("../../icons/free.png")} /></div>
                    <h6>FREE!</h6>
                    <ul>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Public profile setup</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>View up to 8 profiles/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Send up to 2 gig invite messages/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>1 active job posting at the same time</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>1 job posting/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 2 active productions at the same time</p></li>
                    </ul>
                </div>

                <div  className="indie-surfers">
                    <h5>INDIE SURFERS</h5>
                    <div className="image"><img alt="Crewbite indie surfers suscription plan" src={require("../../icons/surfing.png")} /></div>
                    <h6>CAD 8/MONTH</h6>
                    <ul>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Public profile setup</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>View up to 80 profiles/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Send up to 30 gig invite messages/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 5 active job postings at the same time</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 15 job postings/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 8 active productions at the same time</p></li>
                    </ul>
                    <button onClick={()=>checkout("indie_surfers")}>Subscribe</button>
                </div>

                <div  className="established-pros">
                    <h5>ESTABLISHED PROS</h5>
                    <div className="image"><img alt="Crewbite established pros suscription plan" src={require("../../icons/go-pro.png")} /></div>
                    <h6>CAD 29/MONTH</h6>
                    <ul>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Public profile setup</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>View unlimited profiles</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Send up to 150 gig invite messages/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 20 active job postings at the same time</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 50 job postings/month</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 25 active productions at the same time</p></li>
                    </ul>
                    <button onClick={()=>checkout("established_pros")}>Subscribe</button>
                </div>

                <div  className="busy-bees">
                    <h5>BUSY BEES</h5>
                    <div className="image"><img alt="Crewbite busy bees suscription plan" src={require("../../icons/bee.png")} /></div>
                    <h6>CAD 59/MONTH</h6>
                    <ul>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Public profile setup</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>View unlimited profiles</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Send unlimited gig invite messages</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 100 active job postings at the same time</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Unlimited job postings</p></li>
                        <li><img alt="check icon" src={require("../../icons/check.png")}/> <p>Up to 100 active productions at the same time</p></li>
                    </ul>
                    <button onClick={()=>checkout("busy_bees")}>Subscribe</button>
                </div>

            </Row>
            <Row className="why-upgrade-row">
                <h3 className="subtitle">Why upgrade?</h3>
                <p className="text">Hire trustworthy crew who's available for work. Get access to Crewbite's database, list job postings, and hire crew based on all your production needs.</p>
                <p className="text">Need help? Contact <a href="mailto:support@crewbite.com">support@crewbite.com</a>, we will get back to you within 48 hrs</p>
            </Row>
        </Container>
        ) : ( <Loader />)}
        </>
    )
}

export default PlanSelection;
