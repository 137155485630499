import { Link } from "react-router-dom";
import NotFoundIcon from "../../icons/not_found.svg";
import { Helmet } from "react-helmet-async";

import "./NotFound.scss";

const NotFound = () => {
    return (
        <>
        <Helmet>
            <title>Crewbite Inc.</title>
            <meta name="robots" content="noindex, follow"/>
        </Helmet>
        <div className="not_found">
            <img alt="not found icon" src={NotFoundIcon} className="not_found_icon" />
            <h1>Sorry</h1> 
            <p>The page that you're looking for is not around anymore.</p>
            <p>Click the button below to go to home page</p>
            <Link to={"/"} className="go_home">Back to Home</Link>
        </div>
        </>
    )
}

export default NotFound;