import { API } from "aws-amplify";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Invitation from "../Invitation/Invitation";

const PastConnections = (props) => {

    const [popper,setPopper] = useState(null)
    const [email,setEmail] = useState("")

    const handleConnectionName = async (e,email) => {
        const user_name = await checkEmail(email)
        if (user_name) {
            if (!props.newConnections.some(con=>con.user_name===user_name)) {
                props.handleNewConnections(user_name)
                setEmail("")
            }
        } else if (user_name===false){
            setPopper(e.target);
        }
    }

    const checkEmail = async (email) => {
        const apiName = "CrewbiteAPI";
        const hasProfilePath = "has-profile-by-email"
            
        const hasProfileParams = { 
            queryStringParameters: {
                email
            }
        }
        try {
            if (email) {
                const response = await API.get(apiName, hasProfilePath, hasProfileParams)
                return response 
            }
        } catch (error) {
            props.handleErrorMessage(error.response.data.message)
        }

    }

    return (
        <>
        <div className="connectionsInput">
            <input 
                type="text" 
                name="connections" 
                id="connections" 
                placeholder="Enter e-mail" 
                maxLength="100" 
                value={email} 
                onChange={(e)=> {
                    setPopper(null)
                    setEmail(e.target.value)
                }}
                onKeyDown={(e)=> {
                    if (e.key === "Enter") {
                        handleConnectionName(e,email)
                    }
                }}
            />
            {props.newConnections && props.newConnections.length <= 10 ? (
                <>
                    <p 
                        className="add-row" 
                        onClick={(e)=>{
                            handleConnectionName(e,email)
                        }}
                    >add new</p>
                    <Invitation element={popper} email={email} closePopper={()=>setPopper(null)}/>
                </>
            ) : (
                <p className="add-row">Limit of 10 connections reached</p>
            )}
        </div>

        {props.newConnections && props.newConnections.length>0 &&
        <>
            <div className="connectionTags">
                {props.newConnections.map(connection=>(
                    <div className="connection" key={connection.id}>
                        <p>{connection.user_name}</p>
                        <CloseIcon onClick={()=>props.handleRemoveConnection(connection.id)}/>
                    </div>
                ))}
            </div>
        </>
        }
        </>
    )
}

export default PastConnections;