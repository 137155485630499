import { Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { useEffect } from "react";


const InviteMember = (props) => {
    const dispatch = useDispatch();

    const [user,setUser] = useState("")

    useEffect(()=> {
        if (props.applicant) setUser(props.applicant)
    },[props.applicant])

    const inviteMember = async (e) => {
        e.preventDefault()

        const apiName = "CrewbiteAPI";
        const path = "invite-project-member";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                project_id: props.id,
                user_id: user
            }
        }

        try {
            await API.post(apiName, path, myInit)
            props.closeForm()
        } catch (error) { 
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <Modal className="success-modal create_project" aria-labelledby="contained-modal-title-vcenter" centered show={props.showInviteModal}>
            <Modal.Body>   
                <CloseIcon className="closeButton" onClick={()=>props.closeForm()} />    
                <form onSubmit={inviteMember} className="input_username" autoComplete="none">
                    <h1 className="title">Invite Production Member</h1>
                    <input 
                        type="text" 
                        name="user" 
                        placeholder="Enter username" 
                        maxLength="60"
                        value={user} 
                        onChange={(e)=>setUser(e.target.value)} 
                        required
                        autoComplete="off"
                    />
                    <div className="buttons">
                        <button type="button" className="cancel" onClick={()=>props.closeForm()}>Cancel</button>
                        <button type="submit" className="close">Invite</button>
                    </div>
                </form> 
            </Modal.Body>
        </Modal> 
    )
}

export default InviteMember