import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Col, Container, Modal, Row } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

import Loader from "../../components/Loader/Loader";
import EditJob from "./EditJob";
import PaginationItems from "../../components/Pagination/PaginationItems";
import InviteMember from "../Project/InviteMember";

import "./JobDetails.scss";

const JobDetails = () => {

    const { route } = useAuthenticator((context) => [context.route]);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams()

    const [edit, setEdit] = useState(false)
    const [active, setActive] = useState(1)
    const [applicants,setApplicants] = useState([])
    const [showModalError,setShowModalError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [details, setDetails] = useState(undefined)
    const [numOfElements,setNumOfElements] = useState()
    const [showInviteModal,setShowInviteModal] = useState(false)

    const items_per_page = 10

    useEffect(()=> {
        if (id && route==="authenticated") {           
            getJobDetails(searchParams.get("status"))
        }
    },[searchParams,id,route])

    useEffect(()=> {
        if (details) setApplicants(details.applicants.slice((active-1) * items_per_page, (active * items_per_page)))
    },[details,active])

    //Handlers
    const closeEdit = (success) => {
        if (success) getJobDetails(searchParams.get("status"))
        setEdit(false)
    }

    const changePage = (page) => {
        setActive(page) 
    } 

    //Api calls
    const getJobDetails = async (status) => {
        const apiName = "CrewbiteAPI";
        const path = status === "open" ? "get-job" : "get-closed-job";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                job_id: id
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            response.job_id = id
            setNumOfElements(response.applicants.length)
            setDetails(response)
        } catch (error) { 
            setErrorMessage(
                <>
                    <p>{error.response.data.message}</p>
                    <div className="buttons">
                        <button className="close" onClick={()=>navigate(-1)}>Return</button>
                    </div>
                </>
            )
            setShowModalError(true) 
        }
    }

    const getContactInfo = async (user_id) => {
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const apiName = "CrewbiteAPI"
        const path = "get-contact-info-by-id"
        const myInit = {
          headers:{
            Authorization: token,
          },
          queryStringParameters: {
            user_id,
            job_id: id
          }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            setApplicants(prev => {
                const newApplicants = [...prev]
                for (let applicant of newApplicants) {
                    if (applicant.applicant_id === user_id) {
                        applicant.contact = response
                        break;
                    }
                }
                return newApplicants
            })
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
        <Helmet>
            <title>{`${details ? details.production_title + " -" : ""} Crewbite Inc.`}</title>
        </Helmet>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalError}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModalError(false)} />
                <div>{errorMessage}</div>
            </Modal.Body>
        </Modal>

        {details &&
        <Container>
            {route === "authenticated" ? (
            <>
            <InviteMember 
                showInviteModal={showInviteModal} 
                id={details.project_id} 
                applicant={showInviteModal}
                closeForm={() => {
                    setShowInviteModal(false)
                }}
            />
            <Row>
                <Col sm={12} md={4}>
                {edit ? (
                    <EditJob handleClose={closeEdit} job={details}/>
                ) : (
                    <div className="details">
                        <h1 className="title">{details.production_title}</h1>
                        <p><span>Position required</span> {details.industry_role}</p>
                        {details.producers && <p><span>Producer</span> {details.producers}</p>}
                        <p><span>Location</span> {details.location_addr}</p>
                        <p><span>Date</span> {details.production_date.replace(","," - ")}</p>
                        {details.salary && <p><span>Salary</span> {details.salary}</p>}
                        
                        <ul className="metadata">
                            {details.metadata && details.metadata.length > 0 && details.metadata.map(data => {
                                let text
                                switch (data) {
                                    case "covid_vac":
                                        text = "Covid-19 Vaccination Required"
                                        break;
                                    case "volunteer_work": 
                                        text = "This is a voluntary work"
                                        break;
                                    case "union":
                                        text = "Union member preferred"
                                        break;
                                    case "remote":
                                        text = "This is a remote position"
                                        break;
                                    default:
                                        break;
                                }
                                return text && <li key={data}>{text}</li>
                            })}
                        
                        </ul>

                        {details.description && <div><span>Additional Notes</span> <p>{parse(details.description.replaceAll("\n","<br/>"))}</p></div>}
                        {details.job_auto_exp && <p><span>Expiring on</span> {(new Date(details.job_auto_exp*1000)).toLocaleString()}</p>}
                        
                        <div className="buttons">
                            {location.state && <button onClick={()=>navigate(location.state.prev)} className="close">Close</button>}
                            {searchParams.get("status") === "open" && <button onClick={()=>setEdit(true)} className="edit">Edit</button>}
                        </div>
                    </div>
                )}
                </Col>
                <Col sm={12} md={8}>
                    <div className="applicants">
                        {numOfElements > 0 ? ( 
                            <>
                                <h5>Applicants: {numOfElements}</h5>
                                {applicants.map(applicant=> (
                                    <div className="applicant" key={applicant.applicant_id}>
                                        <Link to={`/profile/${applicant.applicant_id}`}>
                                            <img 
                                                alt={`${applicant.applicant_name} Crewbite profile picture`} 
                                                className="profile-pic" 
                                                src={`https://crewbite-user-profile-pictures.s3.amazonaws.com/${applicant.applicant_id}-icon.jpg?${new Date().getTime()}`}
                                            />
                                        </Link>
                                        <Link className="applicant_name" to={`/profile/${applicant.applicant_id}`}>{applicant.applicant_name}</Link>
                                        <p>{applicant.applicant_loc}</p>
                                        <p className="applied-on">Applied on: {(new Date(applicant.created_at * 1000)).toLocaleDateString()}</p>
                                        {!applicant.contact ? (
                                        <p
                                            className="contact" 
                                            onClick={(e)=> {
                                                getContactInfo(applicant.applicant_id)
                                            }}
                                        >
                                            View Contact Info
                                        </p>
                                        ) : (
                                        <div className="contact-details">
                                            <p>Email:</p><p>{applicant.contact.email}</p>
                                            {applicant.contact.phone_number && <p>Phone #: {applicant.contact.phone_number}</p>}
                                        </div>
                                        )}
                                        <p onClick={()=>setShowInviteModal(applicant.applicant_id)} className="invite">Invite to production</p>
                                    </div>
                                ))}

                                {numOfElements > items_per_page && 
                                    <PaginationItems 
                                        numOfElements={numOfElements} 
                                        active={active} 
                                        changePage={changePage}
                                        items_per_page={items_per_page}
                                    />
                                }
                            </>
                        ) : (
                            <p>No applicants</p>
                        )} 
                    </div>  
                </Col>
            </Row>
            </>
            ) : (<Loader />)}
        </Container>}
        </>
    )
}

export default JobDetails;