import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

import "react-datepicker/dist/react-datepicker.css";
import "./CalendarProfile.scss"

const today = (new Date()).setHours(0,0,0,0);

const CalendarProfile = (props) => {
    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [unAvailable,setUnAvailable] = useState();
    const [dateString,setDateString] = useState(null) 
    const [clear,setClear] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [edit,setEdit] = useState(false)
    const dispatch = useDispatch();

    useEffect(()=>{
        getCalendar()
    },[props.id])

    useEffect(()=>{
        if (dateString) {
            let dates = []
            for (let i=0; i<dateString.length-1;i++) {
                if (dateString[i]==="1"){
                    dates.push(addDays(today,i))
                }
            }
            setUnAvailable([...dates])
        }  
    },[dateString])

    const getCalendar = async () => {
        const apiName = "CrewbiteAPI";
        const path = props.editable ? "get-calendar" : "get-calendar-by-id";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: { 
                start_day: Math.floor(today/86400000)
            }
        }
        if (!props.editable) myInit.queryStringParameters["user_id"] = props.id;

        try {
            const response = await API.get(apiName, path, myInit)
            setDateString(response)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const updateCalendar = async (calendar_str) => {
        const apiName = "CrewbiteAPI";
        const path = "update-calendar";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: { 
                start_day: Math.floor(today/86400000),
                calendar_01_str: calendar_str
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setStartDate(null);
            setEndDate(null);
            setShowModal(true)
            setEdit(false)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const postDays = () => {
        const diff1 = Math.round((startDate - today) / (1000 * 3600 * 24))
        const diff2 = Math.round((endDate - startDate) / (1000 * 3600 * 24)) + 1
        let values = ""
        
        for (let i = 0; i<diff2;i++) {
            values += !clear ? "1" : "0"
        }

        let newDays = dateString.split('')
        newDays.splice(diff1,diff2,values)

        setDateString(newDays.join(''))
        updateCalendar(newDays.join(''))
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        if (!clear) {
            if (!unAvailable.some(date=>date>=start&&date<=end)&&!start<today) {
                setStartDate(start);
                setEndDate(end);
            }  
        } else {
            setStartDate(start);
            setEndDate(end);
        }      
    };

    return (
        <>
            <div className="calendar calendar-profile">
                {props.editable ? (
                !edit ? (
                    <>
                    <DatePicker
                        minDate={today}
                        excludeDates={unAvailable}
                        inline
                        disabledKeyboardNavigation
                    />
                    <button className="edit" onClick={()=>setEdit(true)}>Edit</button>
                    </>
                ) : (
                    <>
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        minDate={today}
                        startDate={startDate}
                        endDate={endDate}
                        excludeDates={!clear ? unAvailable : []}
                        highlightDates={clear ? unAvailable : []}
                        selectsRange
                        inline
                    />
                    <div className="radio">
                        <input type="radio" id="add" name="edit-calendar" checked={!clear} onChange={()=>setClear(false)}/>
                        <label htmlFor="add">Remove availabilities</label>  
                    </div>
                    <div className="radio">
                        <input type="radio" id="remove" name="edit-calendar" checked={clear} onChange={()=>setClear(true)}/>
                        <label htmlFor="remove">Restore availabilities</label>  
                    </div>
                    <div className="buttons">
                        <button className="clear" onClick={()=>setEdit(false)}>Cancel</button>
                        <button className="save" disabled={!(startDate && endDate)} onClick={postDays}>Confirm</button> 
                    </div>
                    
                    </>
                )) : (
                    <DatePicker
                        minDate={today}
                        excludeDates={unAvailable}
                        inline
                        disabledKeyboardNavigation
                    />
                )}   
            </div>
            <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
                <Modal.Body>
                    <CloseIcon className="closeButton" onClick={()=>setShowModal(false)} />
                    <img alt="save calendar icon" src={require("../../icons/save.png")}/>
                    <h5 className="modal-heading">Saved!</h5>
                    <p>Your new schedule has been saved.</p>
                </Modal.Body>
            </Modal>
        </>
    );
  };
  export default CalendarProfile;