/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onNewMessage = /* GraphQL */ `
  subscription OnNewMessage($receiver_id: String!, $channel_id: String) {
    onNewMessage(receiver_id: $receiver_id, channel_id: $channel_id) {
      receiver_id
      msg_id
      channel_id
    }
  }
`;
