import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CrewbiteTips = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#4d6b76',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: '10px',
      maxWidth: '200px'
    },
}));

const Tips = (props) => {
    return (
      <CrewbiteTips title={props.content} arrow placement="right-end">
          <img alt="Crewbite tip icon" src={require("../../icons/tip.png")} width="15px" className="tip-icon"/>
      </CrewbiteTips>
    )
}

export default Tips
