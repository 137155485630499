import { useEffect, useState } from "react"
import { API, Auth } from "aws-amplify"
import { useDispatch } from "react-redux";
import { Autocomplete, TextField } from "@mui/material"

const ChooseProject = (props) => {
    const dispatch = useDispatch();

    const [projectTitle,setProjectTitle] = useState("")
    const [newProject,setNewProject] = useState(false)
    const [projectOptions,setProjectOptions] = useState([])

    useEffect(()=>{
        getProjects()
    },[])

    //Handlers
    const autocompleteInputs = async (id) => {
        if (id) {
            const project = await getProjectData(id)
            let [dates,location,producers] = [[null,null],null,null]
            if (project.production_date) dates = project.production_date.split(",")
            if (project.location_addr) location = project.location_addr
            if (project.producers) producers = project.producers
            props.handleAutocomplete(dates,location,producers)
        }
    }

    //Api calls
    const getProjects = async () => {
        const apiName = "CrewbiteAPI";
        const path = "list-all-projects";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                owned_only: true
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            setProjectOptions(response)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const getProjectData = async (id) => {
        const apiName = "CrewbiteAPI";
        const path = "get-project";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                project_id: id
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            return response
        } catch (error) { 
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
            <div className="project_inputs">
                <div className="choose_project">
                    <input 
                        type="radio" 
                        name="new_project" 
                        id="yes" 
                        checked={newProject} 
                        onChange={(e)=>{
                            setNewProject(e.target.checked)
                            setProjectTitle("")
                            props.handleProjectId(null)
                        }}
                    />
                    <label htmlFor="yes">Yes</label>
                    <input 
                        className="project_input" 
                        type="text" 
                        value={newProject ? projectTitle : ""} 
                        onChange={(e)=>{
                            setProjectTitle(e.target.value)
                            props.handleNewProject(e.target.value)
                        }}
                        placeholder="New production title here" 
                        maxLength="100"
                        required={!projectTitle}
                        disabled={!newProject}
                    />
                </div>
                <div className="choose_project">
                    <input 
                        type="radio" 
                        name="new_project" 
                        id="no" 
                        checked={!newProject} 
                        onChange={(e)=>{
                            setNewProject(!e.target.checked)
                            setProjectTitle("")
                        }}
                    />
                    <label htmlFor="no">No</label>
                    <div className="project_input_autocomplete">
                        <Autocomplete
                            disablePortal
                            type="text" 
                            options={projectOptions}
                            getOptionLabel={(option) => !newProject ? option.project_title || "" : ""}
                            value={!newProject ? projectTitle : ""}
                            inputValue={!newProject ? projectTitle : ""}
                            onChange={(e,value)=>{
                                setProjectTitle(value?.project_title || "")
                                props.handleProjectId(value?.project_id || null)
                                autocompleteInputs(value?.project_id)
                            }}
                            fullWidth={true}
                            renderInput={(params) => <TextField {...params} placeholder="Choose from existing productions you own" required={!projectTitle}/>}
                            disabled={newProject}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseProject