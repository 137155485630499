import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { v4 as uuid } from 'uuid';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

import { Container,Row,Col, Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

import CalendarProfile from "../../components/Calendar/CalendarProfile";
import CropImage from "../../components/CropImage/CropImage";
import SendEmail from "../../components/SendEmail/SendEmail";
import OverLimits from "../../components/OverLimits/OverLimits";
import EditProfile from "./EditProfile";
import Loader from "../../components/Loader/Loader";

import "./Profile.scss";

const Profile = () => {
    const dispatch = useDispatch();
    const myId = useSelector((state) => state.id)
    const subscription = useSelector(state => state.isPremium)
    const { route } = useAuthenticator((context) => [context.route]);
    const { id } = useParams()
    const navigate = useNavigate()

    const [profile,setProfile] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [overLimitsModalShow,setOverLimitsModalShow] = useState(false);
    const [profileModalShow,setProfileImgModalShow] = useState(false);
    const [coverModalShow,setCoverModalShow] = useState(false);
    const [profilePic,setProfilePic] = useState()
    const [coverPic,setCoverPic] = useState()
    const [edit,setEdit] = useState()
    const [connections, setConnections] = useState([])
    const [showApiMeter,setShowApiMeter] = useState(false)
    const [notSubscribed,setNotSubscribed] = useState(false)

    useEffect(()=> {
        if (route === 'authenticated' && id && myId) {
            getProfile() 
        }
    },[route, myId, id])

    useEffect(()=>{
        setProfilePic(`https://crewbite-user-profile-pictures.s3.amazonaws.com/${id}-compact.jpg?${new Date().getTime()}`)
        setCoverPic(`https://crewbite-user-profile-pictures.s3.amazonaws.com/${id}-cover.jpg?${new Date().getTime()}`)
    },[id])

    useEffect(()=>{
        if (profile) {
            if (profile.past_connections) {      
                getConnectionProfiles()
            } else {
                setConnections([])
            }
        }
    },[profile])

    useEffect(()=>{
        if (subscription) {
            setNotSubscribed(!subscription.subscription_status || (subscription.subscription_status && subscription.subscription_cancel_at_period_end))
        }
    },[subscription])

    //Handlers
    const handleEdit = (success) => {
        setEdit(false)
        if (success) getProfile()
    }

    const handleSendMessagePopper = async () => {
        if (await checkApiMeter()) setModalShow(true)
    }

    //Api calls
    const manageSubscription = async () => {
        const apiName = "CrewbiteAPI";
        const path = "customer_portal";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        
        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.post(apiName, path, myInit)
            window.location.href = response.session_url
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const checkApiMeter = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-api-meter";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                paths: "send-invite-message"
            }
        };
        try {
            const response = await API.get(apiName, path, myInit)
            if (response.invite_messages > 0) {
                return true
            } else {
                setShowApiMeter(true)
                return false
            }
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const getProfile = async () => {
        const apiName = "CrewbiteAPI";
        const path = myId === id ? "get-profile" : "get-full-profile-by-id";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {}
        }

        if (myId !== id) myInit.queryStringParameters["user_id"] = id;

        try {
            const response = await API.get(apiName, path, myInit)
            setProfile(response)
        } catch (error) {
            if (error.response.data.message === "user_id NOT FOUND") {
                navigate("/not-found")
            }
            if (error.response.status === 466) {
                setOverLimitsModalShow(true)
            } else {
                dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            }  
        }
    }

    const getConnectionProfiles = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-masked-profile-by-id";

        const pastConnections = []
        
        for (let connection of profile.past_connections) {
            const myInit = { 
                queryStringParameters: { 
                    user_id: connection
                }
            }
            try {
                const response = await API.get(apiName, path, myInit)
                response.id = connection
                pastConnections.push(response)
            } catch (error) {
                dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            }
        }

        setConnections(pastConnections)
    }

    return (
        <>
        <Helmet>
            <title>{`${profile ? profile.first_name + " " + profile.last_name + " -" : ""} Crewbite Inc.`}</title>
            <meta name="robots" content="noindex, follow"/>
        </Helmet>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showApiMeter}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowApiMeter(false)} />
                <div>
                    <br/>
                    <p>You run out of quota usage for the following category: Invite Messages Limit.</p>
                    {notSubscribed ? (
                        <button className="upgrade" onClick={()=>navigate("/plan-selection")}>Go Premium</button> 
                    ) : (
                        <button className="upgrade" onClick={manageSubscription}>Upgrade Plan</button> 
                    )}
                </div>
            </Modal.Body>
        </Modal>

        <OverLimits
            show={overLimitsModalShow}
            onHide={() => setOverLimitsModalShow(false)}
        />

        {profile && route === "authenticated" ? (
        <Container className="profileContainer">
            <Row className="pictures-row">
                <div onClick={()=>setCoverModalShow(true)} style={{backgroundImage:`url(${coverPic})`}} id="cover-pic">
                    <CropImage 
                        show={coverModalShow}
                        onHide={() => {
                            setCoverModalShow(false)
                            myId===id && setCoverPic(`https://crewbite-user-profile-pictures.s3.amazonaws.com/${id}-cover.jpg?${new Date().getTime()}`)
                        }}
                        id={id}
                        type="cover"
                        editable={myId===id}
                    />
                </div> 
                <div onClick={()=>setProfileImgModalShow(true)} style={{backgroundImage:`url(${profilePic})`}} id="profile-pic">
                    <CropImage 
                        show={profileModalShow}
                        onHide={() => {
                            setProfileImgModalShow(false)
                            myId===id && setProfilePic(`https://crewbite-user-profile-pictures.s3.amazonaws.com/${id}-compact.jpg?${new Date().getTime()}`)
                        }}
                        id={id}
                        type="profile"
                        editable={myId===id}
                    />
                </div>  
            </Row>

            <Row className="info-row">
                <Col className="profileInfo">
                {edit ? <EditProfile setEdit={handleEdit} profile={profile}/> : 
                    <>
                    {myId===id && <button onClick={()=>setEdit(true)} className="edit-content">Edit profile</button>}
                    <div className="line1">
                        {profile.first_name && profile.last_name && 
                            <h1 className="name">{profile.first_name} {profile.last_name}
                                {profile.gender_id.length>0 && 
                                <span className="gender">
                                    {profile.gender_id.map(gen =>  {
                                        switch (gen) {
                                            case "female":
                                                return "(Female)"
                                            case "male": 
                                                return "(Male)"
                                            case "nonbinary":
                                                return "(Non-binary)"
                                        }
                                    })}
                                </span>}
                            </h1>
                        }
                        {myId!==id && 
                        <>
                            <SendEmail
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                id={id}
                            />
                            <button className="send-message" onClick={handleSendMessagePopper}><img alt="send email icon" src={require("../../icons/email.png")}/>Send Message</button>
                        </>
                        }
                    </div>
                    
                    {profile.industry_role && 
                        <p className="roles">{profile.industry_role.join(', ')}</p> 
                    
                    }

                    {profile.location_addr && 
                        <p className="location"><img alt="crew location icon" src={require("../../icons/location.png")}/> {profile.location_addr}</p>
                    }

                    {profile.metadata &&
                        <div className="metaData">
                        {profile.metadata.map(meta =>  {
                            let text
                            switch (meta) {
                                case "covid_vac":
                                    text = "Covid-19 Vaccinated"
                                    break;
                                case "volunteer_work": 
                                    text = "Available for voluntary work"
                                    break;
                                case "union":
                                    text = "Union member"
                                    break;
                                default:
                                    break;
                            }
                            return text && <p key={meta}><img alt="check icon" src={require("../../icons/check.png")}/> {text}</p>
                        })}
                        </div>
                    }

                    <div className="icons">
                        {profile.instagram && 
                            <a 
                                href={(profile.instagram.includes("https://") || profile.instagram.includes("http://") ? "" : "https://") + profile.instagram} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img alt="instagram link" src={require("../../icons/instagram.png")}/>
                            </a>
                        }
                        {profile.demo_reel &&
                            <a 
                                href={(profile.demo_reel.includes("https://") || profile.demo_reel.includes("http://") ? "" : "https://") + profile.demo_reel}
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img alt="demo reel link" src={require("../../icons/film.png")}/>
                            </a>
                        }
                        {profile.imdb_profile &&
                            <a 
                                href={(profile.imdb_profile.includes("https://") || profile.imdb_profile.includes("http://") ? "" : "https://") + profile.imdb_profile} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img alt="imdb link" src={require("../../icons/imdb.png")}/>
                            </a>
                        }
                    </div>

                    {profile.about_me && 
                    <>
                        <h3 className="about-header">About {profile.first_name}</h3>
                        <p className="text">{parse(profile.about_me.replaceAll("\n","<br/>"))}</p>
                    </>
                    }

                    {profile.skill_sets && profile.skill_sets.length > 0 &&
                    <>
                        <h3 className="skills">Qualifications & Skills</h3>
                        <ul className="skills-list">
                            {profile.skill_sets.map(skill=>
                                <li key={uuid()} className="skill-item">{skill}</li>
                            )}
                        </ul>
                    </>
                    }

                    {(profile.metadata.includes("lgbtqia2") || 
                        profile.metadata.includes("bipoc") ||
                        profile.ethics_id.length>0
                        ) &&
                    <>
                        <h3 className="about-header">Identity & Ethnicity</h3>
                        <ul className="identity-list">
                            {profile.ethics_id.map(ethics =>  {
                                let text
                                switch (ethics) {
                                    case "indigenous":
                                        text = "American Indian or Alaska Native"
                                        break;
                                    case "asian":
                                        text = "Asian"
                                        break;
                                    case "black":
                                        text = "Black or African American"
                                        break;
                                    case "latino":
                                        text = "Hispanic or Latino"
                                        break;
                                    case "islander":
                                        text = "Native Hawaiian or Other Pacific Islander"
                                        break;
                                    case "white":
                                        text = "White"
                                        break;
                                    default:
                                        break;
                                }
                                return text && <li key={ethics}>{text}</li>
                            })}
                            {profile.metadata.map(meta => {
                                let text
                                switch (meta) {
                                    case "bipoc":
                                        text = "Black, Indigenous, and people of colour"
                                        break;
                                    case "lgbtqia2":
                                        text = "I belong to the LGBTQIA2+ community"
                                        break;
                                    default:
                                        break;
                                }
                                return text && <li key={meta}>{text}</li>
                            })}
                        </ul>
                    </>
                    }

                    {connections && connections.length > 0 &&
                    <>
                        <h3 className="working-with">I have been working with...</h3>
                        <ul className="list">
                            {connections.map(connection => 
                                <li key={uuid()} className="list-item" onClick={()=>navigate(`/profile/${connection.id}`)}>
                                    <img 
                                        alt="previous connection profile picture" 
                                        src={`https://crewbite-user-profile-pictures.s3.amazonaws.com/${connection.id}-icon.jpg?${new Date().getTime()}`}
                                    /> 
                                    <span>{connection.first_name} {connection.last_name}</span>
                                    <span>{connection.industry_role.join(', ')}</span>
                                </li>
                            )}
                        </ul>
                    </>
                    }
                    </>
                }
                </Col>
                
                <Col className="calendar-row">
                    {myId===id ? (
                    <>
                        <h3 className="calendar-header">Edit your calendar</h3>
                        <p className="calendar-text">Click on edit to change your availability.</p>
                    </>
                    ) : (
                        <h3 className="calendar-header">Schedule & Working Availability</h3>
                    )}
                    <CalendarProfile editable={myId===id} id={id}/>
                </Col>
                
            </Row>
        </Container>
        ) : (<Loader />)}
        </>
    )
}

export default Profile;
