import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap"
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSearchParams } from "react-router-dom";
import { memo } from "react";

import CrewPreview from "../CrewPreview/CrewPreview";
import FilterTags from "./FilterTags";
import CalendarFilter from "../Calendar/CalendarFilter";

import Cities from '../../data/cities.json';
import industryRoles from '../../data/industryRoles.json';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { RiArrowDropDownLine as DownIcon, RiArrowDropUpLine as UpIcon }  from "react-icons/ri";
import TextField from '@mui/material/TextField';
import { Popper } from "@mui/material";

import "./Filter.scss";
import PaginationItems from "../Pagination/PaginationItems";


const Filter = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [position, setPosition] = useState([])
    const [role, setRole] = useState(null)
    const [crewNameInput,setCrewNameInput] = useState("")
    const [crewName, setCrewName] = useState("")
    const [city,setCity] = useState(null)
    const [radius,setRadius] = useState("150")
    const [union,setUnion] = useState(false)
    const [covid,setCovid] = useState(false)
    const [voluntary,setVoluntary] = useState(false)
    const [dates,setDates] = useState([null,null])
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElDefinitions, setAnchorElDefinitions] = useState(null)
    const [active, setActive] = useState(props.active)
    const [lgbtqia2,setLgbtqia2] = useState(false)
    const [bipoc,setBipoc] = useState(false)
    const [ethnicity,setEthnicity] = useState("")
    const [ethnicityOptions,setEthnicityOptions] = useState([])
    const [gender,setGender] = useState("")
    const [showAdvancedFilters,setShowAdvancedFilters] = useState(false)
    const [reload,setReload] = useState(false)

    const options = [].concat(...Object.values(industryRoles)).map(role => {
        const category = Object.keys(industryRoles).find(key => industryRoles[key].includes(role));
        return {
            category,
            role
        }
    })

    useEffect(()=> {
        const filters = props.filters
        if (filters.position) setPosition(filters.position.split(','))
        if (filters.city) setCity(filters.city)
        if (filters.radius) setRadius(filters.radius)
        if (filters.crewName) setCrewName(filters.crewName)
        if (filters.ethnicity) setEthnicityOptions(filters.ethnicity.split(','))
        if (filters.gender) setGender(filters.gender)
        if (filters.union && JSON.parse(filters.union)) setUnion(JSON.parse(filters.union))
        if (filters.covid && JSON.parse(filters.covid)) setCovid(JSON.parse(filters.covid))
        if (filters.lgbtqia2 && JSON.parse(filters.lgbtqia2)) setLgbtqia2(JSON.parse(filters.lgbtqia2))
        if (filters.bipoc && JSON.parse(filters.bipoc)) setBipoc(JSON.parse(filters.bipoc))
        if (filters.voluntary && JSON.parse(filters.voluntary)) setVoluntary(JSON.parse(filters.voluntary))
        if (filters.startDate && JSON.parse(filters.startDate) && JSON.parse(filters.endDate)) setDates([JSON.parse(filters.startDate),JSON.parse(filters.endDate)])
    },[props.filters])

    useEffect(()=>{
        if (position.length > 0 || city || crewName || ethnicityOptions.length > 0 || gender || union || covid || voluntary || lgbtqia2 || bipoc || dates[1] || reload) {
            const params = {
                page:1,
                sort:props.filters.sort
            }

            if (position.length > 0) params.position = position.join(",")
            if (city)  {
                params.city = city
                params.radius = radius
            }
            if (crewName) params.crewName = crewName
            if (ethnicityOptions.length > 0) params.ethnicity = ethnicityOptions.join(",")
            if (gender) params.gender = gender
            if (union) params.union = union
            if (covid) params.covid = covid
            if (voluntary) params.voluntary = voluntary
            if (lgbtqia2) params.lgbtqia2 = lgbtqia2
            if (bipoc) params.bipoc = bipoc
            if (dates[0] && dates[1]) {
                params.startDate = dates[0]
                params.endDate = dates[1]
            }
            setReload(true)
            setActive(1)
            props.getFilters(params)
        }
    },[position.length,city,radius,crewName,ethnicityOptions.length,gender,union,covid,voluntary,lgbtqia2,bipoc,dates[1]])

    //Handlers
    const resetAll = () => {
        setPosition([]);
        setCity("")
        setUnion(false)
        setCovid(false)
        setVoluntary(false)
        setLgbtqia2(false)
        setBipoc(false)
        setCrewName("")
        setEthnicityOptions([]);
        setGender("");
        setDates([null,null])
    }

    const getDays = (start,end) => {
        setDates([start,end])
    }
    
    const handlePopper = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };
    const handlePopperDefinitions = (e) => {
        setAnchorElDefinitions(anchorElDefinitions ? null : e.currentTarget);
    };

    const handleSort = (value) => {
        props.sortResults(value)
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl);
    const openDefinitions = Boolean(anchorElDefinitions)
    const id = open ? 'simple-popper' : undefined;
    const idDefinitions = openDefinitions ? 'simple-popper' : undefined;

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    const handleTags = (tag,value) => {
        switch (tag) {
            case "position":
                setPosition(prev => prev.filter(r=>r!==value));
                break;
            case "city":
                setCity("")
                break;
            case "union":
                setUnion(false)
                break;
            case "covid":
                setCovid(false)
                break;
            case "voluntary":
                setVoluntary(false)
                break;
            case "lgbtqia2":
                setLgbtqia2(false)
                break;
            case "bipoc":
                setBipoc(false)
                break;
            case "crew-name":
                setCrewName("")
                break;
            case "ethnicity":
                setEthnicityOptions(prev => prev.filter(e=>e!==value));
                break;
            case "gender":
                setGender("");
                break;
            case "dates":
                setDates([null,null])
                break;
            default:
                break;
        }
    }

    const changePage = (page) => {
        setActive(page)
        if (props.filters) {
            const copyFilters = props.filters
            copyFilters.page=page
            setSearchParams(copyFilters) 
        }
    }

    return (
        <Container className="filter-container">
            <Row className="filter-row">
                <Col sm={12} md={4} className="filter">
                    <form className="form" autoComplete="off">
                        <div className="select-position">
                            <h5 className="heading">Position</h5>
                            <div className="inputs">
                                <div className="roleInput">
                                    <Autocomplete
                                        disablePortal
                                        options={position.length < 20 ? (options.sort((a,b)=>-b.category.localeCompare(a.category))) : ([])}
                                        groupBy={(option) => option.category}
                                        getOptionLabel={(option) => option.role || ""}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="Role"/>}
                                        value={role}
                                        onChange={(e,value) => {
                                            setRole(value.role)
                                            setPosition(prev => 
                                                value.role && !prev.some(val => val === value.role) ? [...prev, value.role] : prev
                                            )
                                            setTimeout(()=>{
                                               setRole(null) 
                                            },50)
                                        }}
                                    />
                                </div>
                            </div>
                            {position.length >= 20 && <p className="roles-error">You can filter a maximum of 20 roles</p>}
                        </div>
                            
                        <div className="select-location">
                            <h5 className="heading">Location</h5>
                            <div className="inputs">

                                <div className="cityInput">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        disablePortal
                                        id="city"
                                        options={Cities}
                                        value = {city}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="City" />}
                                        onChange={(e,value)=>setCity(value)}
                                    />
                                </div>

                                <div className="radiusInput">
                                    <label htmlFor="radius">Radius</label>
                                    <ArrowDropDownIcon className="radius-arrow"/>
                                    <select 
                                        onChange={(e)=>setRadius(e.target.value)} 
                                        name="radius" 
                                        id="radius" 
                                        value={radius}
                                    >
                                        <option value="150">150KM</option>
                                        <option value="300">300KM</option>
                                        <option value="500">500KM</option>
                                        <option value="1000">1000KM</option>
                                        <option value="20000">Any Place</option>
                                    </select>    
                                </div>
                            </div>
                        </div>

                        <div className="crew-name">
                            <label className="heading" htmlFor="name">Name</label>
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Search by name" 
                                maxLength="100" 
                                value={crewNameInput} 
                                onChange={(e)=>setCrewNameInput(e.target.value)} 
                                onKeyDown={(e)=> {
                                    if (e.key === "Enter") {
                                        setCrewName(e.target.value)
                                        setCrewNameInput("")
                                    }
                                }}
                            />
                            <img 
                                alt="search icon" 
                                src={require("../../icons/search.png")} 
                                className="input-icon"
                                onClick={()=> {
                                    setCrewName(crewNameInput)
                                    setCrewNameInput("")
                                }}
                            />
                        </div>

                        <div className="availability">
                            <h5 className="heading">Availability</h5>
                            <div className="calendar">
                                <CalendarFilter dates={dates} getDays={getDays}/>
                            </div>  
                        </div>

                        <div className="meta-data">

                            <div className="checkbox">
                                <input 
                                    type="checkbox" 
                                    name="union" 
                                    id="union" 
                                    checked={union} 
                                    onChange={(e)=>setUnion(e.target.checked)}
                                />
                                <label htmlFor="union">Union member</label>
                            </div>

                            <div className="checkbox">
                                <input 
                                    type="checkbox" 
                                    name="covid" 
                                    id="covid" 
                                    checked={covid} 
                                    onChange={(e)=>setCovid(e.target.checked)}
                                />
                                <label htmlFor="covid">Covid-19 Vaccinated</label>
                            </div>

                            <div className="checkbox">
                                <input 
                                    type="checkbox" 
                                    name="voluntary" 
                                    id="voluntary" 
                                    checked={voluntary} 
                                    onChange={(e)=>setVoluntary(e.target.checked)}
                                />
                                <label htmlFor="voluntary">Available for voluntary work</label>
                            </div>
                        </div>

                        <div className="open_advanced_filters" onClick={()=>setShowAdvancedFilters(prev=>!prev)}>
                            <p>Advanced filters {!showAdvancedFilters ? <DownIcon className="icon"/>:<UpIcon className="icon"/>}</p>
                        </div>
                        
                        {showAdvancedFilters &&
                            <div className="advanced_filters">
                                <div className="checkbox">
                                    <input 
                                        type="checkbox" 
                                        name="lgbtqia2" 
                                        id="lgbtqia2" 
                                        checked={lgbtqia2} 
                                        onChange={(e)=>setLgbtqia2(e.target.checked)}
                                    />
                                    <label htmlFor="lgbtqia2">LGBTQIA2+ community</label>
                                </div>
                                <div className="checkbox">
                                    <input 
                                        type="checkbox" 
                                        name="bipoc" 
                                        id="bipoc" 
                                        checked={bipoc} 
                                        onChange={(e)=>setBipoc(e.target.checked)}
                                    />
                                    <label htmlFor="bipoc">Black, Indigenous, and people of colour</label>
                                </div>
                                <div className="ethnicity-selection">
                                    <h5 className="heading" id="race">Ethnicity <span onClick={handlePopperDefinitions}>See definitions</span></h5>
                                    <Popper id={idDefinitions} open={openDefinitions} anchorEl={anchorElDefinitions}>
                                            <div className="popper-definitions">
                                                <p><span>American Indian or Alaska Native.</span> A person having origins in any of the original peoples of North and South America (including Central America), and who maintains tribal affiliation or community attachment.</p>
                                                <p><span>Asian.</span> A person having origins in any of the original peoples of the Far East, Southeast Asia, or the Indian subcontinent including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand, and Vietnam.</p>
                                                <p><span>Black or African American.</span> A person having origins in any of the black racial groups of Africa. Terms such as “Haitian” or “Negro” can be used in addition to “Black or African American.”</p>
                                                <p><span>Hispanic or Latino.</span> A person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin, regardless of race. The term, “Spanish origin,” can be used in addition to “Hispanic or Latino.”</p>
                                                <p><span>Native Hawaiian or Other Pacific Islander.</span> A person having origins in any of the original peoples of Hawaii, Guam, Samoa, or other Pacific Islands.</p>
                                                <p><span>White.</span> A person having origins in any of the original peoples of Europe, the Middle East, or North Africa.</p>
                                            </div>                     
                                        </Popper>
                                    <ArrowDropDownIcon className="radius-arrow"/>
                                    <select 
                                        value={ethnicity}
                                        onChange={(e) => {
                                            setEthnicityOptions(prev => 
                                                e.target.value && !prev.some(val => val === e.target.value) ? [...prev, e.target.value] : prev
                                            )
                                            setEthnicity("")
                                        }}
                                        name="ethnicity" 
                                        id="ethnicity-select"
                                    >
                                        <option value="">Search by ethnicities</option>
                                        <option value="indigenous">American Indian or Alaska Native</option>
                                        <option value="asian">Asian</option>
                                        <option value="black">Black or African American</option>
                                        <option value="latino">Hispanic or Latino</option>
                                        <option value="islander">Native Hawaiian or Other Pacific Islander</option>
                                        <option value="white">White</option>
                                    </select> 
                                </div>

                                <div className="gender-selection">
                                    <h5 className="heading" id="race">Gender</h5>
                                    <ArrowDropDownIcon className="radius-arrow"/>
                                    <select 
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        name="gender" 
                                        id="gender-select"
                                    >
                                        <option value="">Search by gender</option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                        <option value="nonbinary">Non-binary</option>
                                    </select> 
                                </div>
                            </div>
                        }
                        
                        <input onClick={resetAll} className="search" type="button" value="Reset Filters"/>
                    </form>
                </Col>
                <Col sm={12} md={8} className="crewInfo">    
                    <div className="tags">
                        <FilterTags 
                            handleTags={handleTags}
                            position={position}
                            city={city}
                            union={union}
                            covid={covid}
                            voluntary={voluntary}
                            lgbtqia2={lgbtqia2}
                            bipoc={bipoc}
                            crewName={crewName}
                            ethnicity={ethnicityOptions}
                            gender={gender}
                            dates={dates}
                        />
                    </div>
                    {!props.loading && props.crew ? (
                        props.crew.length > 0 ? (
                                <div className="preview-col">
                                    <div className="sort">
                                        <p className="text">{props.numOfElements} available crew found</p>
                                        <p className="open-popper" onClick={handlePopper}>Sorting: {props.sort} <img alt="sort crew icon" src={require('../../icons/filter.png')} /> </p>
                                        <Popper id={id} open={open} anchorEl={anchorEl}>
                                            <div className="popper">
                                                <p onClick={()=>handleSort("default")} className={props.sort==="default" ? "active-sort" : ""}>Default</p>
                                                <p onClick={()=>handleSort("a-z")} className={props.sort==="a-z" ? "active-sort" : ""}>Ascending (A to Z)</p>
                                                <p onClick={()=>handleSort("z-a")} className={props.sort==="z-a" ? "active-sort" : ""}>Descending (Z to A)</p> 
                                                <p onClick={()=>handleSort("distance")} className={props.sort==="distance" ? "active-sort" : ""}>Distance (Nearest first)</p>  
                                            </div>                     
                                        </Popper>
                                    </div>
                                    
                                    {props.crew.map(element => (                   
                                        <CrewPreview key={element.user_id} crew={element}/>
                                    ))}                    
                                </div>
                        ) : (
                            <Row className="no-match">
                                <h3>No matches found</h3>
                            </Row>
                    )) : (
                        <Spinner className="small-loader" animation="border" />
                    )}

                    {props.numOfElements > props.items_per_page && 
                        <PaginationItems 
                            numOfElements={props.numOfElements} 
                            active={active} 
                            changePage={changePage}
                            items_per_page={props.items_per_page}
                        />
                    } 
                </Col>
            </Row>
        </Container>
    )
}

export default memo(Filter);