import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

const Devices = () => {
    const dispatch = useDispatch();

    const [devices,setDevices] = useState(undefined)

    useEffect(()=> {
        getListOfDevices()
    },[])

    const getListOfDevices = async () => {
        const apiName = "CrewbiteAPI";
        const path = "list-registered-devices";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            setDevices(response)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const forgetDevices = async () => {
        const apiName = "CrewbiteAPI";
        const path = "forget-all-devices";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setDevices([])
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        devices ? (
            devices.length>0 &&
                <div className="devices-list">
                    <h3 className="devices-heading">Authorized Devices</h3>
                    <div className="devices-table">
                        {devices.map(device=> (
                            <div className="device" key={device.device_key}>
                                <p className="name">
                                    {device.device_name.includes("(") && device.device_name.includes(")") ? (
                                        device.device_name.substring(
                                            device.device_name.indexOf("(") + 1, 
                                            device.device_name.indexOf(")")
                                        ) 
                                    ) : device.device_name}
                                </p>
                                <p className="ip">IP address: {device.last_sign_in_ip}</p>
                                <p className="time">Last login: {(new Date(device.last_sign_in_time)).toLocaleString()}</p>
                            </div>
                        ))}
                    </div>
                    <button className="forget" onClick={forgetDevices}>Forget all devices</button>
                </div>
        ) : (
            <Spinner className="small-loader" animation="border" />
        )
    )
} 

export default Devices;