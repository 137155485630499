import { API, Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

import { Col, Container, Modal, Row } from "react-bootstrap"
import CloseIcon from "@mui/icons-material/Close";
import { IoWarningOutline } from "react-icons/io5";

import Loader from "../../components/Loader/Loader"

import "./ApplyJob.scss"

const ApplyJob = () => {

    const { route } = useAuthenticator((context) => [context.route]);
    const hasProfile = useSelector(state => state.hasProfile)
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch();

    const [job, setJob] = useState(undefined)
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [showModalError,setShowModalError] = useState(false)
    const [errorMessage,setErrorMessage] = useState()
    const [email,setEmail] = useState()
    const [phone,setPhone] = useState()

    useEffect(()=>{
        if (id && route === 'authenticated')  getJobDetails(id)
    },[id,route])

    const getJobDetails = async (job_id) => {
        const apiName = "CrewbiteAPI";
        const path = "get-job-by-public";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                job_id
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            response.job_id = job_id
            setJob(response)
        } catch (error) {
            setErrorMessage(
                <>
                    <p>{error.response.data.message}</p>
                    <div className="buttons">
                        <button className="close" onClick={()=>navigate(-1)}>Return</button>
                    </div>
                </>
            )
            setShowModalError(true)
        }

    }

    const checkProfile = async () => {
        if (!hasProfile) {
            setErrorMessage(
            <div className="create-profile">
                <p>You need to create a profile before you Apply</p> 
                <Link to={"/create-profile"} className="create-p-button">Create Profile</Link>
            </div>)
            setShowModalError(true)
        } else {
            await getContactInfo()
            setShowModalConfirmation(true)
        }
    }

    const getContactInfo = async () => {
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const apiName = "CrewbiteAPI"
        const path = "get-contact-info"
        const myInit = {
          headers:{
            Authorization: token
          }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            setEmail(response.email)
            if (response.phone_number) {
                setPhone(response.phone_number)
            }
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const applyForJob = async () => {
        const apiName = "CrewbiteAPI";
        const path = "apply-job";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                job_id: id
            }
        }

        try {
            await API.post(apiName, path, myInit)
            setShowModalConfirmation(false)
            setShowModal(true)
            setJob(prev => {
                const newJob = prev
                newJob.already_applied = true
                return newJob
            })
        } catch (error) {
            setShowModalConfirmation(false)
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }

    }

    return (
        <>
        <Helmet>
            <title>{`${job ? job.production_title + " -" : ""} Crewbite Inc.`}</title>
        </Helmet>

        <Container>
            {job && route === 'authenticated' ? (
            <Row>
                <Col sm={12} md={10}>
                    <div className="details">
                        <h1 className="title">{job.production_title}</h1>
                        <p><span>Position required</span> {job.industry_role}</p>
                        {job.producers && <p><span>Producer</span> {job.producers}</p>}
                        <p><span>Location</span> {job.location_addr}</p>
                        <p><span>Date</span> {job.production_date.replace(","," - ")}</p>
                        {job.salary && <p><span>Salary</span> {job.salary}</p>}
                        <ul className="metadata">
                            {job.metadata && job.metadata.length > 0 && job.metadata.map(data => {
                                let text
                                switch (data) {
                                    case "covid_vac":
                                        text = "Covid-19 Vaccination Required"
                                        break;
                                    case "volunteer_work": 
                                        text = "This is a voluntary work"
                                        break;
                                    case "union":
                                        text = "Union member preferred"
                                        break;
                                    case "remote":
                                        text = "This is a remote position"
                                    default:
                                        break;
                                }
                                return text && <li key={data}>{text}</li>
                            })}
                        </ul>
                        {job.description && <div><span>Additional Notes</span> <p>{parse(job.description.replaceAll("\n","<br/>"))}</p></div>}
                        <div className="buttons">
                            {!job.already_applied ? (
                                <button className="apply" onClick={()=>checkProfile()}>Apply</button>
                            ) : (
                                <button className="already-applied" disabled>Applied</button>
                            )}
                            {location.state && 
                                <p onClick={()=>navigate(location.state.prev)} className="back">{"< Back to Job Listing"}</p>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            ) : (<Loader/>)}                   
        </Container>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalConfirmation}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModalConfirmation(false)} />
                <IoWarningOutline className="warning-icon"/>
                <h5 className="modal-heading">Job application is about to be submitted!</h5>
                <p>By confirming this action, Your contact information will be sent to this employer:</p>
                <ul className="contact_info">
                    {email && <li>Email: {email}</li>}
                    {<li>Phone: {phone || "(not set)"}</li>}
                </ul>
                <p className="update_contact">You can update your contact information <a href="/settings" className="settings_link">here</a> </p>
                <div className="buttons">
                    <button type="button" className="cancel" onClick={()=>setShowModalConfirmation(false)}>Cancel</button>
                    <button type="button" className="close" onClick={()=>applyForJob()}>Confirm</button>
                </div>
            </Modal.Body>
        </Modal>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModal(false)} />
                <img alt="thumbs up icon" src={require("../../icons/thumbs-up.png")}/>
                <h5 className="modal-heading">Applied!</h5>
                <p>A notification has been sent to this employer.</p>
                <p>You can view all your submitted applications at <Link className="popover-link" to={"/my-job-applications"}>My Job Applications</Link></p>
            </Modal.Body>
        </Modal>    
            
        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalError}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModalError(false)} />
                <div>{errorMessage}</div>
            </Modal.Body>
        </Modal>  
        </>
    )
}

export default ApplyJob;