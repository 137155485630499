import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Nav, Navbar } from "react-bootstrap";
import { ClickAwayListener, Popper } from "@mui/material";

import ProfileNav from "./ProfileNav";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { TbMessageCircle2 } from "react-icons/tb"
import UnionMap from "../../data/union_map.json";

import "./Navigation.scss";
import "./MobileNav.scss";

function useWidth() {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWith() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWith);
    updateWith();
    return () => window.removeEventListener('resize', updateWith);
  }, []);
  return width;
}

function Navigation() {

  const user_id = useSelector(state => state.id)
  const menu = useSelector(state => state.menu)
  const newMessages = useSelector(state => state.newMessages)
  const pending_invitations = useSelector(state => state.pending_invitations)
  const union = useSelector(state => state.union)

  const { pathname } = useLocation()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null)
  const [collapsed,setCollapsed] = useState(false)
  const [expanded,setExpanded] = useState(false)
  const width = useWidth();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(()=> {
    if (width <= 992) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }
  },[width])

  useEffect(()=>{
    if (!menu) {
      setAnchorEl(null)
    }
  },[menu])


  //Handlers
  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget)
    dispatch({type:"OPEN_MENU",payload: !anchorEl})
  }


  return (
    <Navbar id="crewbite-navbar" expanded={expanded} collapseOnSelect expand="lg">
      <Navbar.Brand eventkey="logo" id="logo" className="logo" onClick={()=>setExpanded(false)}>
        <img
          onClick={()=>navigate("/")}
          alt="Crewbite navbar logo"
          src={require(`../../icons/${UnionMap[union].corner_logo}`)}
        />
      </Navbar.Brand>

      {collapsed && (
        !user_id ? (
          <Nav.Link as={Link} eventkey="signin" className="nav-link collapsed_signin" to="/signin" state={{page: "signUp"}}>Sign Up</Nav.Link>
        ) : (
          <>
            <div className="new_message collapsed_new_message">
              <TbMessageCircle2 className="messages_icon" onClick={()=>navigate("/messages")}/>
              <div className={newMessages?.length > 0 ? "unread_messages" : undefined}></div>
            </div>
            <div className="new_invitation collapsed_new_invitation">
              <MdOutlineNotificationsNone className="notifications_icon" onClick={()=>navigate("/pending-actions")}/>
              <div className={pending_invitations ? "unread_messages" : undefined}></div>
            </div>
          </>
        )
      )}

      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=>setExpanded(prev=>!prev)}>
        <img alt="menu icon" src={require("../../icons/menu.png")}/>
      </Navbar.Toggle>

      <Navbar.Collapse id="basic-navbar-nav" onClick={()=>setExpanded(false)}>
        <Nav className="me-auto links">
          <Nav.Link as={Link} eventkey="find" className={`nav-link ${pathname==="/find-job" ? "active-page" : ""}`} to="/find-job">Find a Job</Nav.Link>
          <Nav.Link as={Link} eventkey="hire" className={`nav-link ${pathname==="/hire-crew" ? "active-page" : ""}`} to="/hire-crew">Hire Crew & Resources</Nav.Link>
          <Nav.Link as={Link} eventkey="post" className={`nav-link ${pathname==="/post-a-job" ? "active-page" : ""}`} to="/post-a-job">Post a Job</Nav.Link>
          <Nav.Link as={Link} eventkey="about" className={`nav-link ${pathname==="/about-us" ? "active-page" : ""}`} to="/about-us">About Us</Nav.Link>
        </Nav>

        <div className="user-links">
        {!user_id ? (
          <Nav.Link as={Link} eventkey="signin" className="nav-link signin-button" to="/signin">Sign In/Create Profile</Nav.Link>
        ) : (
          collapsed ? (
            <div className="mobile-profile-settings">
              <ProfileNav mobile={true}/>
            </div>
          ) : (
          <>
            <div className="new_message"><TbMessageCircle2 className="messages_icon" onClick={()=>navigate("/messages")}/><div className={newMessages?.length > 0 ? "unread_messages" : undefined}></div></div>
            <div className="new_invitation"><MdOutlineNotificationsNone className="notifications_icon" onClick={()=>navigate("/pending-actions")}/><div className={pending_invitations ? "unread_messages" : undefined}></div></div>

            <Popper id={id} open={open} anchorEl={anchorEl} className="profile-settings">
              <ClickAwayListener onClickAway={()=>setAnchorEl(null)}>
                <div>
                  <ProfileNav />
                </div>
              </ClickAwayListener>
            </Popper>
            <div onClick={handleMenu} className="profile-icons">
              <AiOutlineUser className="profile-nav"/>
              <p>My Account</p>
            </div>
          </>
          ))}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
