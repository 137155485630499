import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import project_configuration from "../../configurations/project_configuration";
import { useCookies } from 'react-cookie';

import CloseIcon from '@mui/icons-material/Close';
import Footer from "../../components/Footer/Footer";
import HomeFilter from "./HomeFilter";
import UnionMap from "../../data/union_map.json";

import "./Home.scss"

const Home = () => {
    const [ windowHeight, setWindowHeight ] = useState(window.innerHeight);
    const [ showModal,setShowModal ] = useState(false)
    const [ cookies, setCookie ] = useCookies(['closed_create_profile_popper']);

    const user_id = useSelector(state => state.id)
    const hasProfile = useSelector(state => state.hasProfile)
    const union = useSelector(state => state.union)
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(()=> {
        if (location.hash) {
            const hash = location.hash.substring(1);
            const contactSecction = document.getElementById(hash)
            contactSecction?.scrollIntoView();
        }
    },[location])
    
    useEffect(()=>{
        if ((hasProfile === false || user_id === undefined) && !cookies.closed_create_profile_popper) {
            setTimeout(()=>{
                setShowModal(true)
            },10000)
        }
    },[hasProfile,user_id])

    useEffect(()=>{
        const elements = document.getElementsByClassName('animated-row');
        const images = document.getElementsByClassName('animated-img');
        const otherElements = document.getElementsByClassName('other-animations');

        window.onresize = () => {
            setWindowHeight(window.innerHeight);
        }

        if (elements) checkPosition(elements);
        if (images) checkPosition(images,true);
        if (otherElements) checkPosition(otherElements,true);

        window.onscroll = () => {
            if (elements) checkPosition(elements);
            if (images) checkPosition(images,true);
            if (otherElements) checkPosition(otherElements,true);
        }
    },[windowHeight])

    const checkPosition = (elements,fadeOnly) => {
        for (let element of elements) {
            let positionFromTop = element.getBoundingClientRect().top;
            if (positionFromTop - windowHeight <= 0) {
                element.classList.add('fadein');
                if (!fadeOnly) element.classList.add('translate');
            } else {
                element.classList.remove('fadein');
                if (!fadeOnly) element.classList.remove('translate');
            }
        }
    }

    return (
        <>
        <Helmet>
            <title>Crewbite Inc. We connect crew with film productions</title>
            <link rel="canonical" href={`https://www.${project_configuration.main_url}/`}/>
            <meta
                name="description"
                content="Hire crew for your film production. Whether you're looking for camera, PA, catering, or even stunts for your show, Crewbite will be here for you. We are dedicated to helping you to find the right people and resources that you need to create your film."
            />;
        </Helmet>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
            <Modal.Body>
                <CloseIcon 
                    className="closeButton" 
                    onClick={()=> {
                        setShowModal(false)
                        setCookie('closed_create_profile_popper', true, { maxAge:  86400})
                    }}
                />
                <br/>
                <h5 className="modal-heading">You don't have a profile yet!</h5>
                <p>Create a free profile in 3 minutes</p>
                <button 
                    className="upgrade" 
                    onClick={()=>{
                        navigate("create-profile")
                        setCookie('closed_create_profile_popper', true, { maxAge:  86400})
                    }}
                >
                    Create Profile
                </button>
            </Modal.Body>
        </Modal>

        <Container fluid className="homeContainer">
            <Row className="welcome-row"> 
                <Col className="welcome-col">   
                    <img className="bg-image animated-img" alt="crewbite home page background image" src={require(`../../images/${UnionMap[union].landing_page_background_image}`)}/>
                    <h1 className="title">{UnionMap[union].landing_page_title}</h1>
                    <p className="text other-animations">{UnionMap[union].landing_page_subtitle}</p>
                    <HomeFilter />
                    <Link className="create-profile other-animations" to={"/create-profile"}>Looking for gigs? Create profile now!</Link>
                </Col>
            </Row>
            <Row className="one-platform animated-row">
                <img alt="one platform, all creative needs" src={require("../../icons/video.png")}/>
                <h1 className="title">One platform,<br/> all creative needs</h1>
            </Row>
            <Row id="how" className="how-row animated-row">
                <Col className="how-col">
                    <h2 className="subtitle">How does Crewbite works?</h2>
                    <p className="text">Our goal is to make filmmaking easier for everyone by having the simplest flow.</p>
                    <div className="how-img animated-row">
                        <h3 className="how-title">Find Job</h3>
                        <div className="how-images">
                            <div className="how-element">
                                <img alt="user orange icon" src={require("../../icons/user-2-orange.png")}/>
                                <p>Create Profile</p>
                            </div>
                            <img className="arrow" alt="arrow icon" src={require("../../icons/arrows.png")}/>
                            <div className="how-element">
                                <img alt="job search orange icon" src={require("../../icons/job-search-orange.png")}/>
                                <p>Search our job list</p>
                            </div>
                            <img className="arrow" alt="arrow icon" src={require("../../icons/arrows.png")}/>
                            <div className="how-element">
                                <img alt="submit orange icon" src={require("../../icons/submit - orange.png")}/>
                                <p>Apply for a job</p>
                            </div>
                            <img className="arrow" alt="arrow icon" src={require("../../icons/arrows.png")}/>
                            <div className="how-element">
                                <img alt="like orange icon" src={require("../../icons/like-orange.png")}/>
                                <p>Get the job!</p>
                            </div>
                        </div>
                    </div>
                    <div className="how-img animated-row">
                        <h3 className="how-title">Find Crew and Resources</h3>
                        <div className="how-images">
                            <div className="how-element">
                                <img alt="user orange icon" src={require("../../icons/user-2-orange.png")}/>
                                <p>Create Profile</p>
                            </div>
                            <img className="arrow" alt="arrow icon" src={require("../../icons/arrows.png")}/>
                            <div className="how-element">
                                <img alt="research orange icon" src={require("../../icons/research-orange.png")}/>
                                <p>Search our profile database</p>
                            </div>
                            <img className="arrow" alt="arrow icon" src={require("../../icons/arrows.png")}/>
                            <div className="how-element">
                                <img alt="message orange icon" src={require("../../icons/message-orange.png")}/>
                                <p>Get connected with industry professionals</p>
                            </div>
                            <img className="arrow" alt="arrow icon" src={require("../../icons/arrows.png")}/>
                            <div className="how-element">
                                <img alt="like orange icon" src={require("../../icons/like-orange.png")}/>
                                <p>Get your crew and resources!</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row id="why" className="why-row animated-row">
                <h2 className="subtitle">Why Crewbite?</h2>
                <Col className="why-box" lg={4} sm={12}>
                    <div className="boxContent">
                        <h3 className="heading">Fast and Reliable</h3>
                        <p className="text">We understand how time-consuming it can get to check crew credibility and availability or to find a job. Just sign up, fill the requirement that you need, and get the reliable crew and job that you want.</p>
                    </div>
                </Col>
                <Col className="why-box" lg={4} sm={12}>
                    <div className="boxContent">
                        <h3 className="heading">Experienced</h3>
                        <p className="text">We have been helping tonnes of local film productions, just like yours.</p>
                    </div>
                </Col>
                <Col className="why-box" lg={4} sm={12}>
                    <div className="boxContent">
                        <h3 className="heading">Trustworthy</h3>
                        <p className="text">We are professional film maker who understands the industry needs. This is why we created Crewbite.</p>
                    </div>
                </Col>
            </Row>
            <Row id="testimonials" className="testimonials-row animated-row">
                <Col className="testimonials-col">
                    <h2 className="subtitle">Testimonials</h2>
                    <div className="testimonial-carousel">
                        <Carousel indicators={false} variant="dark" fade>
                            <Carousel.Item>
                                <div className="testimonial">
                                    <p>“Recently, I was stranded with a last minute cancellation from a crew member. Luckily that situation was solved fast thanks to Jasmin and her Crewbite team. Within just one hour of posting the job details on Crewbite, I received multiple applications from qualified candidates. I can’t wait for the platform to develop nationwide and abroad!”</p>
                                    <p className="testimonial-name"><span>Gabriel Galand</span> Producer</p>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="testimonial">
                                    <p>“I've worked with Jasmin many times in the past and her work ethic still surprises me till today. We need more people in the industry like her and I can't wait to see what Crewbite will bring us.”</p>
                                    <p className="testimonial-name"><span>Matthew</span> Producer</p>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="testimonial">
                                    <p>“Crewbite created so many opportunities for me to look for and connect with ongoing productions. It releases a lot of my stress finding gigs and connecting with people. I would definitely recommend this to more people to gather our community together!”</p>
                                    <p className="testimonial-name"><span>Nancy</span> Sound Production</p>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </Col>
            </Row>
            <Row className="our-partners animated-row">
                <h2 className="subtitle">Our Partners</h2>
                <div className="partner_logos">
                    <img alt="Crewbite's partner Vancouver Asian Film Festival (VAFF) Logo" src={require("../../images/VAFF-Logo.png")}/>
                    <img alt="Crewbite's partner Keto Caveman Logo" src={require("../../images/keto-caveman-logo.png")}/>
                </div>
            </Row>
            <Row >
                <Footer />  
            </Row>

        </Container>
        </>
    )
}

export default Home;
