import React, { useState, useCallback, useEffect } from 'react';
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from './canvasUtils';
import { API, Auth } from 'aws-amplify';
import Cropper from 'react-easy-crop';

import { Modal } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import Slider from '@mui/material/Slider';

import './CropImage.scss';
import { useDispatch } from 'react-redux';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

export const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

const CropImage = (props) => {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [pic,setPic] = useState()

  useEffect(()=> {
    if (props.show) {
      getImages()
    }
    
  },[props.show])

  const getImages = () => {
    if (props.type === "project") {
      try {
        setPic(`https://crewbite-project-pictures.s3.amazonaws.com/${props.id}-full.jpg?${new Date().getTime()}`)
      } catch {
        setPic("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQz5rERSKIXzR2z06gG4WowiKr3rnV6dl6_LERkWyVDb_LAWPWcgwa9Yj3NhpjJqag9x0o&usqp=CAU")
      }
    } else if (props.type === "profile") {
      try {
        setPic(`https://crewbite-user-profile-pictures.s3.amazonaws.com/${props.id}-full.jpg?${new Date().getTime()}`)
      } catch {
        setPic("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQz5rERSKIXzR2z06gG4WowiKr3rnV6dl6_LERkWyVDb_LAWPWcgwa9Yj3NhpjJqag9x0o&usqp=CAU")
      }
    }
    else {
      try {
        setPic(`https://crewbite-user-profile-pictures.s3.amazonaws.com/${props.id}-cover.jpg?${new Date().getTime()}`)
      } catch {
        setPic("https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")
      }

    }
  }

  const updatePicture = async (picture) => {
    const apiName = "CrewbiteAPI";
    const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

    let path = ""
    let body = {}

    if (props.type === "project") {
      path = "update-project-picture"
      body = { 
          project_id: props.id,
          picture_base64: picture
      }
    } else {
      path = props.type === "profile" ? "update-profile-picture" : "update-cover-picture";
      body = { 
        picture_base64: picture
    }
    }
    
    const myInit = { 
        headers:{
            Authorization: token
        },
        body
    }
    try {
      await API.post(apiName, path, myInit)
      setImageSrc(null)
      getImages()
    } catch (error) {
      dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      const picture = croppedImage.replace("data:image/jpeg;base64,","")
      updatePicture(picture)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }

      setImageSrc(imageDataUrl)
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='cropContainer'>
            {imageSrc ? (    
              <>
                <p className='close' onClick={()=>setImageSrc(null)}>Cancel</p>
                <div className='cropper'>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={props.type === "cover" ? 2.5 / 1 : 1 / 1}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div>
                  <div className='sliders'>
                    <div className='slider'>
                      <span className='slider-text'>Zoom</span>
                      <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                    </div>
                    <div className='slider'>
                      <span className='slider-text'>Rotate</span>
                      <Slider
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        onChange={(e, rotation) => setRotation(rotation)}
                      />
                    </div>
                  </div>
                  <button onClick={showCroppedImage} className="save">Save Photo</button>
                </div>
              </>
              ) : (
                <>
                <div className={props.type === 'cover' ? 'cover-pic' : 'profile-pic'} style={{backgroundImage:`url(${pic})`}}></div>
                {props.editable &&
                  <div className='edit' >
                    <p><EditIcon className='icon' /> Edit</p>
                    <input type="file" onChange={onFileChange} accept="image/*" />
                  </div>
                  }
                </>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}




export default CropImage;