import { useEffect, useState } from "react";
import {API, Auth} from "aws-amplify";
import { useDispatch } from "react-redux";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Col, Container, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';

import Cities from '../../data/cities.json';
import industryRoles from '../../data/industryRoles.json';
import CalendarFilter from "../../components/Calendar/CalendarFilter";

import "./EditJob.scss"


const EditJob = (props) => {
    const dispatch = useDispatch();

    const [producers,setProducers] = useState(props.job.producers)
    const [position, setPosition] = useState(props.job.industry_role)
    const [city,setCity] = useState(props.job.location_addr)
    const [voluntary,setVoluntary] = useState(props.job.metadata ? props.job.metadata.some(m=>m==="volunteer_work"): false)
    const [union,setUnion] = useState(props.job.metadata ? props.job.metadata.some(m=>m==="union") : false)
    const [covid,setCovid] = useState(props.job.metadata ? props.job.metadata.some(m=>m==="covid_vac") : false)
    const [remote,setRemote] = useState(props.job.metadata ? props.job.metadata.some(m=>m==="remote") : false)
    const [dates,setDates] = useState([null,null])
    const [prevDates,setPrevDates] = useState(props.job.production_date.split(","))
    const [description,setDescription] = useState(props.job.description || "")
    const [salary,setSalary] = useState(props.job.salary)
    const [notification,setNotification] = useState(JSON.parse(props.job.notification))

    const options = [].concat(...Object.values(industryRoles)).map(role => {
        const category = Object.keys(industryRoles).find(key => industryRoles[key].includes(role));
        return {
            category,
            role
        }
    })
    
    //Handlers
    const getDays = (start,end) => {
        setDates([start,end])
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    //Api cals
    const handleSubmit = async (e) => {       
        e.preventDefault()
        
        const metaData = []

        if (voluntary) metaData.push("volunteer_work")
        if (union) metaData.push("union")
        if (covid) metaData.push("covid_vac")
        if (remote) metaData.push("remote")

        const body = {
            job_id: props.job.job_id
        }                         
        if (producers) body["producers"] = producers                              
        if (city) body["location_addr"] = city                 
        if (description) body["description"] = description                              
        if (position) body["industry_role"] = position                           
        if (dates[0]&&dates[1]) body["production_date"] = `${(new Date(dates[0]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")},${(new Date(dates[1]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")}`          
        if (metaData) body["metadata"] = metaData                     
        body["salary"] = salary ? salary : ""         
        body["notification"] = notification                               

        const apiName = "CrewbiteAPI";
        const path = "update-job-posting";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body
        };
        try {
            await API.post(apiName, path, myInit)
            props.handleClose(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
            <Container className="hire-crew-container post-job-container edit-container">
                <Row className="hire-crew-row">
                    <Col className="hire-crew-col">
                        <h1 className="subtitle">Edit Job Posting</h1>
                        <form onSubmit={handleSubmit} className="form">           
                            <h5 className="heading">Production Title <span className="required">*</span></h5>
                            <input 
                                className="post-job-input" 
                                type="text" 
                                defaultValue={props.job.production_title} 
                                disabled
                            />

                            <h5 className="heading">Producer(s)</h5>
                            <input 
                                className="post-job-input" 
                                type="text" 
                                placeholder="Name of the producer" 
                                value={producers} 
                                maxLength="100"
                                onChange={(e)=>setProducers(e.target.value)}
                                autoComplete="none"
                            />

                            <div className="select-location">
                                <h5 className="heading">Location <span className="required">*</span></h5>
                                <div className="inputs">

                                    <div className="cityInput">
                                        <Autocomplete
                                            filterOptions={filterOptions}
                                            disablePortal
                                            id="city"
                                            options={Cities}
                                            value = {city}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="City" required/>}
                                            onChange={(e,value)=>setCity(value)}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="checkbox">
                                <input type="checkbox" name="remote" id="remote" checked={remote} onChange={(e)=>setRemote(e.target.checked)}/>
                                <label htmlFor="remote">Remote work</label>
                            </div>

                            <div className="select-position">
                                <h5 className="heading">Position <span className="required">*</span></h5>
                                <div className="inputs">
                                    <div className="roleInput">
                                        <Autocomplete
                                            disablePortal
                                            options={options.sort((a,b)=>-b.category.localeCompare(a.category))}
                                            groupBy={(option) => option.category}
                                            getOptionLabel={(option) => option.role || position}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} required/>}
                                            onChange={(e,value) => setPosition(value?.role || null)}
                                            value={position}
                                        />
                                    </div>
                                </div>
                            </div>

                            <h5 className="heading">Salary</h5>
                            <input 
                                className="post-job-input" 
                                type="text" 
                                placeholder="Please state the currency" 
                                value={salary}
                                maxLength="60"
                                onChange={(e)=>setSalary(e.target.value)}
                            />

                            <h5 className="heading">Production Date <span className="required">*</span></h5>
                            <div className="availability">
                                <CalendarFilter prevDates={prevDates} getDays={getDays} required={true}/>
                            </div>
                            
                            <div className="meta-data">
                                <div className="checkbox">
                                    <div>
                                        <input type="checkbox" name="voluntary" id="voluntary" checked={voluntary} onChange={(e)=>setVoluntary(e.target.checked)}/>
                                        <label htmlFor="voluntary">Voluntary work</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" name="union" id="union" checked={union} onChange={(e)=>setUnion(e.target.checked)}/>
                                        <label htmlFor="union">Union member preferred</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" name="covid" id="covid" checked={covid} onChange={(e)=>setCovid(e.target.checked)}/>
                                        <label htmlFor="covid">Covid-19 Vaccination Required</label>
                                    </div>
                                </div>
                            </div>
                            
                            <h5 className="heading">Information and Requirements</h5>
                            <textarea 
                                className="requirements post-job-input" 
                                placeholder="Detail of the production"
                                value={description}
                                maxLength="3000"
                                onChange={(e)=>setDescription(e.target.value)}
                            />

                            <div className="notifications">
                                <input type="checkbox" name="notifications" id="notifications" checked={notification} onChange={(e)=>setNotification(e.target.checked)}/>
                                <label htmlFor="notifications">Enable email notifications for every application submitted</label>
                            </div>

                            <div className="buttons">
                                <button type="button" className="back" onClick={()=>props.handleClose(false)}>Back</button>
                                <input className="submit" type="submit" value="Save"/>
                            </div>
                            
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default EditJob;