import { useEffect, useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5"

const SignUpForm = () => {

    const [showPassword,setShowPassword] = useState(false)
    const [showPasswordConfirmation,setShowPasswordConfirmation] = useState(false)
    const [openPasswordError,setOpenPasswordError] = useState(false)
    const [password,setPassword] = useState("")
    const [passwordConfirmation,setPasswordConfirmation] = useState("")


    useEffect(()=> {
        setOpenPasswordError(password !== passwordConfirmation) 
    },[password,passwordConfirmation])

    const validatePassword = (value) => {
        setPassword(value)

        const lowerCaseLetters = /[a-z]/g;
        const upperCaseLetters = /[A-Z]/g;
        const numbers = /[0-9]/g;
        const specialCharacters= ["^", "$", "*", ".", "[", "]", "{", "}", "(", ")", "?", "-", '"', "!", "@", "#", "%", "&", "/", "\\", ",", ">", "<", "'", ":", ";", "|", "_", "~", "`", "+", "="]
        
        const password_lowercase = document.getElementById("password_lowercase")
        const password_uppercase = document.getElementById("password_uppercase")
        const password_number = document.getElementById("password_number")
        const password_special_char = document.getElementById("password_special_char")
        const password_min8 = document.getElementById("password_min8")

        let valid = true
    
        if (value.match(lowerCaseLetters)) {
          password_lowercase.classList.remove("invalid")
          password_lowercase.classList.add("valid")
        } else {
          password_lowercase.classList.add("invalid")
          password_lowercase.classList.remove("valid")
          valid = false
        }
    
        if (value.match(upperCaseLetters)) {
          password_uppercase.classList.remove("invalid")
          password_uppercase.classList.add("valid")
        } else {
          password_uppercase.classList.add("invalid")
          password_uppercase.classList.remove("valid")
          valid = false
        }
    
        if (value.match(numbers)) {
          password_number.classList.remove("invalid")
          password_number.classList.add("valid")
        } else {
          password_number.classList.add("invalid")
          password_number.classList.remove("valid")
          valid = false
        }
    
        if (specialCharacters.some(char=>value.includes(char))) {
          password_special_char.classList.remove("invalid")
          password_special_char.classList.add("valid")
        } else {
          password_special_char.classList.add("invalid")
          password_special_char.classList.remove("valid")
          valid = false
        }
    
        if (value.length >= 8) {
          password_min8.classList.remove("invalid")
          password_min8.classList.add("valid")
        } else {
          password_min8.classList.add("invalid")
          password_min8.classList.remove("valid")
          valid = false
        }

        openPopper(!valid)
      }
    
      const openPopper = (value) => {
        const popper = document.getElementById("password_popper")
        if (value) {
          popper.classList.add("open");
        } else {
          popper.classList.remove("open")
        }
      };
    
    return (
        <>
            <input 
              className="amplify-input amplify-field-group__control" 
              type="text" 
              placeholder="Username" 
              name="username" 
              maxLength="60"
              required
            />
            <div className="password_container">
              <input 
                  onChange={(e)=>validatePassword(e.target.value)} 
                  onFocus={(e)=>validatePassword(e.target.value)}
                  onBlur={()=>openPopper(false)}
                  className="amplify-input amplify-field-group__control" 
                  type={showPassword ? "text" : "password"}
                  placeholder="Password" 
                  name="password" 
                  id="password_input"
                  required
              />
              <button 
                  onClick={()=>setShowPassword(prev=>!prev)} 
                  type="button" 
                  className="amplify-button amplify-field-group__control amplify-field__show-password show_password"
              >
                  {showPassword ? <IoEyeOffOutline className="icon"/> : <IoEyeOutline className="icon"/>}
              </button> 
              
              <div id="password_popper">
                  <ul>
                      <li id="password_number" className="invalid">Contains a number</li>
                      <li id="password_special_char" className="invalid">Contains a special character {'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > <'} {"' : ; | _ ~ ` + ="}</li>
                      <li id="password_uppercase" className="invalid">Contains a uppercase letter</li>
                      <li id="password_lowercase" className="invalid">Contains a lowercase letter</li>
                      <li id="password_min8" className="invalid">Minimum length 8 Characters</li>
                  </ul>
              </div>
            </div>

            <div className="password_container">
              <input 
                  onChange={(e)=>setPasswordConfirmation(e.target.value)}
                  className="amplify-input amplify-field-group__control" 
                  type={showPasswordConfirmation ? "text" : "password"} 
                  placeholder="Confirm Password" 
                  name="confirm_password" 
                  id="password_confirmation_input"
                  required
              />
              <button 
                  onClick={()=>setShowPasswordConfirmation(prev=>!prev)} 
                  type="button" 
                  className="amplify-button amplify-field-group__control amplify-field__show-password show_password"
              >
                  {showPasswordConfirmation ? <IoEyeOffOutline className="icon"/> : <IoEyeOutline className="icon"/>}
              </button> 

              {openPasswordError && 
                  <div id="password_confirmation_popper">
                      <p>Password must match</p>
                  </div>
              }
            </div>

            <input 
              className="amplify-input amplify-field-group__control" 
              type="email" 
              placeholder="Email (cannot be changed afterwards)" 
              name="email" 
              maxLength="100"
              required
            />
        </>
    )
}

export default SignUpForm