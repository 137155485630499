import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap";

import Loader from "../../components/Loader/Loader";
import PaginationItems from "../../components/Pagination/PaginationItems";

import "./MyJob.scss";

const MyJob = () => {
    const dispatch = useDispatch();
    const { route } = useAuthenticator((context) => [context.route]);

    const [searchParams, setSearchParams] = useSearchParams()
    const [active, setActive] = useState(1)
    const [jobs,setJobs] = useState(undefined)
    const [numOfElements,setNumOfElements] = useState()

    const items_per_page = 15

    useEffect(()=> {
        const page = searchParams.get("page")
        if (route === 'authenticated') {

            if (page) {
                setActive(+page)
                getJobs(+page) 
            } else {
                getJobs(1) 
            }
        }
        
    },[searchParams,route])

    //Handlers

    const changePage = (page) => {
        setSearchParams({page})
    }

    //Api calls
    const getJobs = async (page) => {

        const apiName = "CrewbiteAPI";
        const path = "get-my-applications";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers: {
                Authorization: token
            },
            queryStringParameters: { 
                start_index: (page - 1) * items_per_page,
                end_index: (page * items_per_page) - 1,
            }
        };

        try {
            const response = await API.get(apiName, path, myInit)
            setJobs(response.items)
            setNumOfElements(response.total_num)
        } catch(error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
        <Helmet>
            <title>My Job Applications - Crewbite Inc.</title>
        </Helmet>
        
        {!jobs || route !== "authenticated"? ( <Loader/> ) : (
            <Container className="my-jobs-container">
                <Row>
                    <h1 className="title">My Job Applications</h1>
                    <div className="my-jobs-div">
                        {jobs.length > 0 ? (
                            <>
                                <div className="titles">
                                    <p>Production</p>
                                    <p>Industry Role</p>
                                    <p>Location</p>
                                    <p className="applied_on">Applied on</p>
                                    <p className="status">Status</p>
                                    <div className="see_job_column"></div>
                                </div>
                                {jobs.map(job=> (
                                    <div className="my-jobs" key={job.job_id}>
                                        <h5 className="prod-title">{job.production_title}</h5>
                                        <h5>{job.job_industry_role}</h5>
                                        <p className="location"><img alt="job location icon" src={require("../../icons/location.png")}/> {job.job_loc}</p>
                                        <p className="created_at">{(new Date(job.created_at * 1000)).toLocaleDateString()}</p>
                                        <p className="status">{job.accepted === true ? "Accepted" : job.accepted === "5" ? "Job closed" : "Applied"}</p>
                                        {job.accepted !== "5" ? <Link to={`/job/${job.job_id}`} className="see-job">Job Details</Link> : <div className="details_space"></div>}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <h3 className="no-jobs">You have no Job Applications</h3>
                        )}
                    </div>
                    {numOfElements > items_per_page && 
                        <PaginationItems 
                            numOfElements={numOfElements} 
                            active={active} 
                            changePage={changePage}
                            items_per_page={items_per_page}
                        />
                        }
                </Row>
            </Container>
        )}
        </>
    )
}

export default MyJob;