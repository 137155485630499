import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap";
import project_configuration from "../../configurations/project_configuration";

import Filter from "../../components/Filter/Filter";

import "./HireCrew.scss";


const HireCrew = () => {
    const dispatch = useDispatch();

    const [crew,setCrew] = useState(undefined)
    const [filters,setFilters] = useState(undefined)
    const [searchParams, setSearchParams] = useSearchParams();
    const [numOfElements, setNumOfElements] = useState();
    const [sort,setSort] = useState("default")
    const [loading,setLoading] = useState(false)
    const [active,setActive] = useState(1)

    const items_per_page = 15

    const hireCrewUrl = `https://www.${project_configuration.main_url}/hire-crew`

    useEffect(()=> {
        const newFilters = Object.fromEntries([...searchParams])
        
        if (Object.keys(newFilters).length !== 0) {
            setActive(+newFilters.page)
            setSort(newFilters.sort)
            setFilters(newFilters)
            getCrew(newFilters)
        } else {
            setFilters({page:1,sort:"default"})
            getCrew({page:1,sort:"default"})
        }
    },[searchParams])

    //Handlers
    const getFilters = (filters) => {
        setSearchParams(filters)
    }

    const sortResults = (order) => {
        if (order === "distance" && !filters.city) {
            dispatch({type:'SHOW_ERROR', payload:"You must provide a location in order to sort by distance"})
        } else {
            if (filters && crew.length>1) {
                const copyFilters = filters
                copyFilters.sort=order
                setSearchParams(copyFilters) 
            }
        }
    }

    //Api calls
    const getCrew = async (data) => {
        setLoading(true)

        const meta = []

        if (data.union && JSON.parse(data.union)) meta.push("union")
        if (data.covid && JSON.parse(data.covid)) meta.push("covid_vac")
        if (data.voluntary && JSON.parse(data.voluntary)) meta.push("volunteer_work")
        if (data.lgbtqia2 && JSON.parse(data.lgbtqia2)) meta.push("lgbtqia2")
        if (data.bipoc && JSON.parse(data.bipoc)) meta.push("bipoc")
        
        const metaData = meta.join(',')

        const apiName = "CrewbiteAPI";
        const path = "query-profile";
        const myInit = { 
            queryStringParameters: { 
                start_index: (data.page - 1) * items_per_page,
                end_index: (data.page * items_per_page) - 1,
                sort: data.sort
            }
        };

        if (data.ethnicity) myInit.queryStringParameters["ethics_id_filters"] = data.ethnicity
        if (data.gender) myInit.queryStringParameters["gender_id_filters"] = data.gender
        if (data.position) myInit.queryStringParameters["industry_role"] = data.position
        if (data.city) myInit.queryStringParameters["location_point"] = data.city
        if (data.city) myInit.queryStringParameters["radius"] = data.radius
        if (data.crewName) myInit.queryStringParameters["name"] = data.crewName
        if (data.startDate && JSON.parse(data.startDate) && JSON.parse(data.endDate)) {
            myInit.queryStringParameters["calendar_start"] = data.startDate
            myInit.queryStringParameters["calendar_end"] = data.endDate
        }
        if (metaData) myInit.queryStringParameters["metadata_filters"] = metaData

        try {
            const response = await API.get(apiName, path, myInit)
            setCrew(response.items)
            setNumOfElements(response.total_num)
            setLoading(false)
        } catch(error) {
            setLoading(false)
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
        <Helmet>
            <title>Hire Crew and Resources | {filters?.position?.length > 0 ? filters.position + " |" : ""} {filters?.city ? filters.city + " |": ""} Crewbite</title>
            {filters && (
                (+filters.page!==1 || filters.sort!=="default" || filters.union || filters.voluntary || filters.covid || filters.crewName || filters.startDate) ? (
                    <meta name="robots" content="noindex"/>
                ) : (
                    (filters.position?.length > 0 || filters.city) ? (
                        <link 
                            rel="canonical" 
                            href={`${hireCrewUrl}?page=1&sort=default${filters.position?.length > 0 ? "&position=" + filters.position : ""}${filters.city ? "&city=" + filters.city + "&radius=" + filters.radius: ""}`}
                        />
                    ) : (
                        <link 
                            rel="canonical" 
                            href={hireCrewUrl}
                        />
                    )
                )
            )}
            <meta
                name="description"
                content={`Hire ${filters?.position?.length > 0 ? filters.position : "Crew"}${filters?.city ? " in " + filters.city : ""} for your film production`}
            />
        </Helmet>

        <Container fluid>
            <Row>
                {filters && 
                    <Filter 
                        filters={filters} 
                        getFilters={getFilters} 
                        numOfElements={numOfElements}  
                        sortResults={sortResults}  
                        sort={sort}
                        crew={crew}
                        loading={loading}
                        active={active}
                        items_per_page={items_per_page}
                    />}
            </Row>
            
        </Container>
        </>
    )
}

export default HireCrew;