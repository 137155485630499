import CloseIcon from '@mui/icons-material/Close';
import Tips from '../Tips/Tips';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';


const PhoneNumber = (props) => {

    const validatePhoneNumber = (inputNumber,phoneLength) => {
        if (inputNumber.length < phoneLength && inputNumber.length !== 0) {
            if (props.saveChange) props.handleSaveChange(false)
            return false;
        } else {
            props.handleSaveChange(true)
            return true;
        }
    };

    return (
        <div className="phoneInput-div">
            <label className="label-with-tip" htmlFor="phone">Phone Number
                <Tips content="By adding your phone number, you allow employers to see your phone number for the jobs you appliy"/>
            </label>
            <div className="phoneInput">
                <PhoneInput
                    name="number" 
                    value={props.phone} 
                    onChange={val=>props.handlePhoneChange(val)}
                    isValid={(inputNumber, country) => {
                        const phoneLength = Math.ceil(country?.format?.replace(/[^.]/g, "").length);
                        return validatePhoneNumber(
                        inputNumber,
                        phoneLength
                        );
                    }}
                /> 
                {props.phone && <CloseIcon className="closeButton" onClick={()=>props.handlePhoneChange("")}/>}
                {!props.saveChange && <p className="errorMessage">Please provide a valid phone number</p>}
            </div>
        </div>
    )
}

export default PhoneNumber;