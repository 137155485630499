import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

import CalendarFilter from '../../components/Calendar/CalendarFilter';
import Cities from '../../data/cities.json';
import industryRoles from '../../data/industryRoles.json';

import "./HomeFilter.scss"

const HomeFilter = () => {

    const navigate = useNavigate();

    const [position, setPosition] = useState(null)
    const [city,setCity] = useState("Vancouver, BC, CA")
    const [dates,setDates] = useState([null,null])

    const options = [].concat(...Object.values(industryRoles)).map(role => {
        const category = Object.keys(industryRoles).find(key => industryRoles[key].includes(role));
        return {
            category,
            role
        }
    })
    
    const getDays = (start,end) => {
        setDates([start,end])
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    const handleSubmit = (e) => {
        e.preventDefault()

        const parameters = `page=1&sort=default${position ? "&position=" + position : ""}${city ? "&city=" + city + "&radius=150" : ""}${dates[0]&&dates[1] ? "&startDate=" + dates[0] + "&endDate=" + dates[1] : ""}`
        navigate(`/hire-crew?${parameters}`)
    }

    return (
        <div className="other-animations find-crew-home-page">
            <h2 className="find-crew-heading">Find your crew and resources!</h2>
            <form onSubmit={handleSubmit} className="home-filter-form" autoComplete="off">
                
                <div className="select-position">
                    <div className="inputs">
                        <div className="roleInput">
                            <img alt="search icon" src={require("../../icons/search.png")} className="input-icon"/>
                            <Autocomplete
                                disablePortal
                                options={options.sort((a,b)=>-b.category.localeCompare(a.category))}
                                groupBy={(option) => option.category}
                                getOptionLabel={(option) => option.role}
                                sx={{width: "250px"}}
                                renderInput={(params) => <TextField {...params} label="Position" />}
                                onChange={(e,value) => setPosition(value.role)}
                            />
                        </div>
                    </div>
                </div>   

                <div className="select-location">
                    <div className="inputs">
                        <div className="cityInput">
                            <img alt="location icon" src={require("../../icons/location.png")} className="input-icon"/>
                            <Autocomplete
                                filterOptions={filterOptions}
                                disablePortal
                                id="city"
                                options={Cities}
                                value = {city}
                                sx={{width: "250px"}}
                                renderInput={(params) => <TextField {...params} label="Location" />}
                                onChange={(e,value)=>setCity(value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="availability">
                    <label className="availability-label">Availability</label>
                    <div className="calendar">
                        <CalendarFilter dates={dates} getDays={getDays}/>
                    </div>  
                </div>

                <input className="search" type="submit" value="Search"/>
            </form>
        </div>
    )
}

export default HomeFilter;