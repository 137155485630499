const initialState = {
    id: null,
    isPremium: {
        expiration: null,
        subscription_cancel_at_period_end: false,
        subscription_status: false,
        type: null
    },
    token: null,
    hasProfile: undefined,
    union: "default",
    menu: false,
    newMessages: undefined,
    error_message: undefined,
    pending_invitations: false
};

const setCredentials = (state = initialState, action) => {
    switch (action.type) {
        case "IS_PREMIUM":
            return {
                ...state,
                isPremium: action.payload
            }
        case "HAS_PROFILE":
            return {
                ...state,
                hasProfile: action.payload
            }
        case "SET_USER":
            return {
                ...state,
                id: action.payload.id,
                token: action.payload.token,
            }
        case "SET_UNION":
            return {
                ...state,
                union: action.payload
            }
        case "RESET_STATE":
            return initialState
        case "OPEN_MENU":
            return {
                ...state,
                menu: action.payload
            }
        case "ADD_NEW_MESSAGE":
            return {
                ...state,
                newMessages: [...(state.newMessages || []), action.payload]
            }
        case "READ_MESSAGE":
            return {
                ...state,
                newMessages: state.newMessages?.filter(m => m !== action.payload)
            }
        case "SHOW_ERROR":
            return {
                ...state,
                error_message: action.payload
            }
        case "HAS_INVITATION":
            return {
                ...state,
                pending_invitations: action.payload
            }
        default:
            return state;
    }
};

export default setCredentials;