import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./CalendarProfile.scss"
import "./CalendarFilter.scss"

const today = (new Date()).setHours(0,0,0,0);

const CalendarFilter = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(()=> {
      if (props.dates) {
        if (props.dates[0] === null) setStartDate(null)
        if (props.dates[1] === null) setEndDate(null)
      }
    },[props.dates])

    useEffect(()=>{
      if (props.prevDates && props.prevDates[1] != null) {
        setStartDate(new Date(props.prevDates[0]))
        setEndDate(new Date(props.prevDates[1]))
      }
    },[props.prevDates])

    useEffect(() => {

      if (startDate && endDate) {
        const epochStartDay = Math.floor(startDate/86400000);
        const epochEndDay = Math.floor(endDate/86400000);
        props.getDays(epochStartDay,epochEndDay)
      } else {
        props.getDays(null,null)
      }
      
    },[endDate])

    return (
      <div className="calendar">
        <div className="calendar1">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            minDate={today}
            startDate={startDate}
            endDate={endDate}
            placeholderText="mm/dd/yyyy"
            required = {props.required}
          />
        </div>
        <p>to</p>
        <div className="calendar2">
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate || today}
            placeholderText="mm/dd/yyyy"
            required = {props.required}
          />
        </div>
      </div>
    );
  };

  export default CalendarFilter