import { useEffect, useState } from "react"
import { API, Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri"

import CloseIcon from '@mui/icons-material/Close';

const ProjectMembers = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams()

    const [members,setMembers] = useState()
    const [titles,setTitles] = useState()
    const [showModalDelete,setShowModalDelete] = useState(false)

    useEffect(()=> {
        setMembers(props.members)
    },[props.members])

    useEffect(()=>{
        if (props.edit) {
            setTitles(members.map(member=> {
                return {
                    user_id:member.user_id,
                    title:member.employment_title,
                    edited:false
                }})
            )
        }
    },[props.edit])

    const handleTitleChange = (e, user_id) => {
        setTitles(prev=> {
            const newTitles = [...prev]
            for (let member of newTitles) {
                if (member.user_id === user_id) {
                    member.title = e.target.value
                    member.edited = true
                }
            }
            return newTitles
        })
    }

    const handleCancel = () => {
        props.cancelEdit()
    }

    const getContactInfo = async (user_id) => {
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const apiName = "CrewbiteAPI"
        const path = "get-contact-info-by-id"
        const myInit = {
          headers:{
            Authorization: token,
          },
          queryStringParameters: {
            user_id,
            project_id: id
          }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            setMembers(prev => {
                const newMembers = [...prev]
                for (let member of newMembers) {
                    if (member.user_id === user_id) {
                        member.contact = response
                        break;
                    }
                }
                return newMembers
            })
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const updateProject = async () => {
        setTitles(prev => {
            const newTitles = [...prev]
            for (let member of newTitles) {
                if (member.user_id && member.edited) {
                    updateMemberTitle(member.user_id, member.title)
                    member.edited = false
                }
            }   
            return newTitles
        })
        await props.updateProject()
    }

    const updateMemberTitle = async (user_id,employment_title) => {
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        const apiName = "CrewbiteAPI"
        const path = "update-project-member-title"

        const myInit = {
          headers:{
            Authorization: token,
          },
          body: {
            project_id: id,
            user_id,
            employment_title
          }
        }
        try {
            await API.post(apiName, path, myInit)
            setMembers(prev => {
                const newMembers = [...prev]
                for (let member of newMembers) {
                    if (member.user_id === user_id) {
                        member.employment_title = employment_title
                        break;
                    }
                }
                return newMembers
            })
            props.cancelEdit()
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const removeMember = async (user_id) => {
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const apiName = "CrewbiteAPI"
        const path = "remove-project-member"
        const myInit = {
          headers:{
            Authorization: token,
          },
          body: {
            project_id: id,
            user_id,
          }
        }
        try {
            await API.post(apiName, path, myInit)
            setMembers(prev => prev.filter(member => member.user_id !== user_id))
            setShowModalDelete(false)
            props.cancelEdit()
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        members ? (
            <>
                {props.edit &&
                    <div className="edit_buttons">
                        <button className="save_button" onClick={updateProject}>Save</button>
                        <button className="cancel_button" onClick={handleCancel}>Cancel</button>  
                    </div> 
                }
                <ul className="project_members_list">
                    {props.edit ? (
                        members.map(member => (
                            <li key={"edit" + (member.user_id || member.approver)}>
                                {member.item_status !== "0" && titles ? (
                                    <>
                                        <input 
                                            type="text" 
                                            placeholder="Enter new role" 
                                            maxLength="60"
                                            value={titles.find(title=>title.user_id === member.user_id).title}
                                            onChange={(e)=>handleTitleChange(e,member.user_id)}
                                        />
                                        <span>{`${member.first_name} ${member.last_name}`}</span> 
                                        <div className="delete_button">
                                            {props.myId !== member.user_id && <RiDeleteBinLine className="remove_button" onClick={()=>setShowModalDelete(member.user_id)}/>}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="bold">N/A</span>
                                        <span>{`${member.first_name} ${member.last_name}`}</span> 
                                        <span className="pending">Invitation Pending</span>
                                        <div className="delete_button">
                                            <button className="remove_button"></button>
                                        </div>
                                    </>
                                )}
                            </li>
                        ))) : (
                            members.map(member => (
                            <li key={member.user_id || member.approver}>
                                <span className="bold">{member.employment_title || "N/A"}</span>
                                <span>{`${member.first_name} ${member.last_name}`}</span> 
                                {member.item_status === "0" ? (
                                    <span className="pending">Invitation Pending</span>
                                ):(
                                    member.contact ? (
                                        <div className="contact_info">
                                            {member.contact.email && <span>email: {member.contact.email}</span>}
                                            {member.contact.phone && <span>phone: {member.contact.phone}</span>}
                                            <CloseIcon className="close_icon" onClick={()=>{
                                                setMembers(prev=>{
                                                    const newMembers = [...prev]
                                                    for (let m of newMembers) {
                                                        if (m.user_id === member.user_id) {
                                                            m.contact = null 
                                                            break
                                                        }
                                                    } 
                                                    return newMembers
                                                })
                                            }}/>
                                        </div>
                                    ) : ( 
                                        props.myId !== member.user_id ? (
                                            <button className="contact_button" onClick={()=>getContactInfo(member.user_id)} disabled={member.privacy_setting !== "1"}>View Contact Info</button>
                                        ) : (
                                            <div className="contact_button"></div>
                                        )
                                    )
                                )}
                            </li>
                        )))
                    }
                </ul>

                <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalDelete}>
                    <Modal.Body>
                        <CloseIcon className="closeButton" onClick={()=>setShowModalDelete(false)} />
                        <img alt="delete job icon" src={require("../../icons/trash.png")}/>
                        <h5 className="modal-heading">Remove Production Member?</h5>
                        <p>You are about to remove {showModalDelete} from the production.</p>
                        <div className="buttons">
                            <button className="cancel" onClick={()=>setShowModalDelete(false)}>Cancel</button>
                            <button className="close" onClick={()=>removeMember(showModalDelete)}>Continue</button>
                        </div>
                    </Modal.Body>
                </Modal> 
            </>
        ) : (
            <Spinner className="small-loader" animation="border" />
        )
    )
}

export default ProjectMembers;