
import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import IOSSwitch from "../IOSSwitch/IOSSwitch";
import Devices from "./Devices";
import PhoneInput from 'react-phone-input-2';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Spinner } from "react-bootstrap";

import 'react-phone-input-2/lib/style.css';
import "./ContactInfo.scss";

const ContactInfo = () => {
    const dispatch = useDispatch();

    const [email,setEmail] = useState(undefined)
    const [phone,setPhone] = useState("")
    const [prevPhone,setPrevPhone] = useState("")
    const [showModal,setShowModal] = useState(false)
    const [showModalConfirmation,setShowModalConfirmation] = useState(false)
    const [showMfaModal,setShowMfaModal] = useState(false)
    const [saveChange,setSaveChange] = useState(false)
    const [enable,setEnable] = useState(false)
    const [code,setCode] = useState("")

    useEffect(()=>{
        getContactInfo()
        getMfaStatus()
    },[])

    const handleEnable = () => {
        if (!enable) {
            validateMFA()
        } else {
            disableMFA()
        }
    }

    const validatePhoneNumber = (inputNumber,phoneLength) => {
        if (prevPhone.length===0 && inputNumber.length===0) {
            setSaveChange(false)
            return true;
        } else {
            if (inputNumber.length < phoneLength && inputNumber.length !== 0) {
                if (saveChange) setSaveChange(false)
                return false;
            } else if (prevPhone.replace("+","") !== inputNumber || inputNumber.length === 0){
                setSaveChange(true)
                return true;
            } else {
                setSaveChange(false)
                return true;
            }
        }
    };

    //Api calls
    const getMfaStatus = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-mfa-status";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            if (response) setEnable(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const validateMFA = async () => {
        const apiName = "CrewbiteAPI";
        const path = "enable-mfa-init";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setShowMfaModal(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const enableMFA = async () => {
        const apiName = "CrewbiteAPI";
        const path = "enable-mfa-verify";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body:{
                code
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setShowMfaModal(false)
            setEnable(prev=>!prev)
        } catch (error) {
            setShowMfaModal(false)
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const disableMFA = async () => {
        const apiName = "CrewbiteAPI";
        const path = "disable-mfa";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
        }
        try {
            await API.post(apiName, path, myInit)
            setEnable(prev=>!prev)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const getContactInfo = async () => {
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const apiName = "CrewbiteAPI"
        const path = "get-contact-info"
        const myInit = {
          headers:{
            Authorization: token
          }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            setEmail(response.email)
            if (response.phone_number) {
                setPrevPhone(response.phone_number)
                setPhone(response.phone_number)
            }
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const updatePhone = async () => {
        const apiName = "CrewbiteAPI";
        const path = "update-phone-number";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: { 
                phone_number: phone ? `+${phone}` : ""
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setPrevPhone(phone)
            setEnable(false)
            setShowModalConfirmation(false)
            setShowModal(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalConfirmation}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModalConfirmation(false)} />
                <img alt="telephone blue icon" src={require("../../icons/telephone-blue.png")}/>
                <h5 className="modal-heading">Crewbite guards your privacy with great effort.</h5>
                <p>Your contact information is only visible to the job owner after you submitted your job application.</p>
                <div className='buttons'>
                    <button className='cancel' onClick={()=>setShowModalConfirmation(false)}>Cancel</button>
                    <button className='close' onClick={updatePhone}>Continue</button>
                </div>
            </Modal.Body>
        </Modal>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModal(false)} />
                <img alt="save phone number icon" src={require("../../icons/save.png")}/>
                <h5 className="modal-heading">Saved!</h5>
                <p>Your phone number has been updated.</p>
            </Modal.Body>
        </Modal>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showMfaModal}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowMfaModal(false)} />
                <p className="verification-text">A verification code was just sent to {prevPhone}. Please input the code to enable MFA.</p>
                <input type="number" className="code-input" placeholder="Enter six digit code" value={code} maxLength="6" onChange={(e)=>setCode(e.target.value)}/>
                <button className="upgrade" onClick={enableMFA}>Enable</button>
            </Modal.Body>
        </Modal>

        {!email ? ( <Spinner className="small-loader" animation="border" /> ) : (
            <>
            <h2 className="subtitle">Privacy and Security</h2>
            <label htmlFor="email">E-mail</label>
            <input className="email-input" type="email" name="email" defaultValue={email} disabled/> 
    
            <label htmlFor="phone">Phone Number</label>
            <div className="phoneInput">
                <PhoneInput 
                    name="number" 
                    value={phone} 
                    onChange={val=>setPhone(val)}
                    isValid={(inputNumber, country) => {
                        const phoneLength = Math.ceil(country?.format?.replace(/[^.]/g, "").length);
                        return validatePhoneNumber(
                        inputNumber,
                        phoneLength
                        );
                    }}
                /> 
                {phone && <CloseIcon className="closeButton" onClick={()=>setPhone("")}/>}
            </div>
            <div className="buttons">
                <button 
                    className="save" 
                    disabled={!saveChange} 
                    onClick={()=>{
                        phone ? setShowModalConfirmation(true) : updatePhone()
                    }}
                > Save
                </button>  
            </div> 

            <div className="mfa">
                <IOSSwitch 
                    name="mfa-switch" 
                    className="mfa-switch" 
                    checked={enable} 
                    onClick={handleEnable}
                    disabled={!prevPhone}
                />
                <label className="mfa-label" htmlFor="mfa-switch">
                    <p className="enable">Enable Multi-Factor Authentication</p>
                    <p className="explanation">When turned on, you will have to authorize every login via a new device with an SMS verification code.</p>
                </label>
            </div>

            {enable && <Devices />}
            </>
        )}
        </>
    )
}

export default ContactInfo;