
import { Link } from "react-router-dom";
import "./CrewPreview.scss"

const CrewPreview = (props) => {
    
    const crew = props.crew

    return (
        <Link to={`/profile/${crew.user_id}`} className="crewCard">
            <div className="row1">
                <h3 className="name">{crew.masked_name}</h3>
            </div>
            <div className="row2">
                <div className="crewCard-info">
                    <img alt={`${crew.masked_name} Crewbite profile picture`} className="profile-picture" src={`https://crewbite-user-profile-pictures.s3.amazonaws.com/${crew.user_id}-icon.jpg`} />
                    <div className="text">
                        <p className="roles">{crew.industry_role_list.join(', ')}</p> 
                        <p className="location"><img alt="crew location icon" src={require("../../icons/location.png")}/> {crew.location_addr}</p>
                    </div>
                </div>
                <div className="metaData">
                    {crew.metadata && crew.metadata.map(meta => {
                        let text
                        switch (meta) {
                            case "covid_vac":
                                text = "Covid-19 Vaccinated"
                                break;
                            case "volunteer_work": 
                                text = "Available for voluntary work"
                                break;
                            case "union":
                                text = "Union member"
                                break;
                            default:
                                break;
                        }
                        return text && <p key={meta}><img alt="check icon" src={require("../../icons/check.png")}/> {text}</p>
                    })}
                </div>
            </div>
            
        </Link>

    )
}

export default CrewPreview