import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./PendingActions.scss";

const PendingActions = () => {
    const dispatch = useDispatch();

    const [actions,setActions] = useState([])

    const navigate = useNavigate()

    useEffect(()=>{
        getActions()
    },[])

    const getActions = async () => {
        const apiName = "CrewbiteAPI";
        const path = "list-all-action-items";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            setActions(response)
            dispatch({type:'HAS_INVITATION', payload:false});
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const acceptInvitation = async (project_id) => {
        const apiName = "CrewbiteAPI";
        const path = "accept-project-invitation";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body:{
                project_id
            }
        }

        try {
            await API.post(apiName, path, myInit)
            setActions(prev=> {
                const newActions = [...prev]
                for (let a of newActions) {
                    if (a.subject_id === project_id) {
                        a.item_status = "1"
                        break
                    }
                }
                return newActions
            })
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const rejectInvitation = async (project_id) => {
        const apiName = "CrewbiteAPI";
        const path = "/reject-project-invitation";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body:{
                project_id
            }
        }

        try {
            await API.post(apiName, path, myInit)
            setActions(prev=> {
                const newActions = [...prev]
                for (let a of newActions) {
                    if (a.subject_id === project_id) {
                        a.item_status = "2"
                        break
                    }
                }
                return newActions
            })
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <div className="project_invitations_container">
            <h1 className="title">PENDING INVITATIONS</h1>
            <div className="invitations">
                {actions.length > 0 ? (
                    actions.map(action => (
                        <div key={action.created_at} className="project_invitation">
                            <div className="column1">
                                {action.item_status === "0" ? (
                                    <>
                                        <p>You have been invited to <b>{action.project_title}</b></p>
                                        <div className="invitation_buttons">
                                            <button className="accept_invitation" onClick={()=>acceptInvitation(action.subject_id)}>Accept</button>
                                            <button className="reject_invitation" onClick={()=>rejectInvitation(action.subject_id)}>Reject</button> 
                                        </div>
                                        <p className="expiration_date">*This invitation will expire on {(new Date(action.exp_time*1000)).toLocaleDateString()}</p>
                                    </>
                                ) : ( action.item_status === "1" ? (
                                    <p>You have accepted the invitation and joined <b className="link_to_project" onClick={()=>navigate(`/project/${action.subject_id}`)}>{action.project_title}</b></p>
                                ) : (
                                    <p>You have rejected the invitation to join <b>{action.project_title}</b></p>
                                ))}
                            </div>
                            <div className="column2">
                                <p>{(new Date(action.created_at*1000)).toLocaleDateString()}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>You have no production invitations</p>
                )}
            </div>
        </div>
    )
}

export default PendingActions