import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "aws-amplify";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

import "./Unsubscribe.scss";


const Unsubscribe = () => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams()
    const [token,setToken] = useState()
    const [email,setEmail] = useState()
    const [showModalError,setShowModalError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [programmatic,setProgrammatic] = useState(true)
    const [promotional,setPromotional] = useState(true)

    const navigate = useNavigate();

    useEffect(()=> {
        const urlToken = searchParams.get("token")

        if (urlToken) {
            getEmailByToken(urlToken)
        } else {
            dispatch({type:'SHOW_ERROR', payload:"Token is invalid or expired"})
        }
    },[searchParams])

    
    const getEmailByToken = async (urlToken) => {
        const apiName = "CrewbiteAPI";
        const path = "get-email-by-token";

        const myInit = { 
            queryStringParameters: {
                token: urlToken
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            if (JSON.parse(response.token_valid)) {
                setToken(urlToken)
                setEmail(response.email)
            } else {
                dispatch({type:'SHOW_ERROR', payload:"Token is invalid or expired"})
            }
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const unsubscribe = async () => {
        const apiName = "CrewbiteAPI";
        const path = "update-email-preference-by-token";

        const myInit = { 
            body: {
                token,
                programmatic: !programmatic,       
                promotional: !promotional          
            }
        }

        try {
            await API.post(apiName, path, myInit)
            setErrorMessage(
                <>
                    {programmatic && <p>You have unsubscribed from email notifications</p>}
                    {promotional && <p>You have unsubscribed from promotional emails</p>}
                    <button className="go_home" onClick={()=>navigate("/")}>Go home</button>
                </>
            )
            setShowModalError(true) 
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
            <Helmet>
                <title>Unsubscribe - Crewbite Inc.</title>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalError}>
                <Modal.Body>
                    <CloseIcon className="closeButton" onClick={()=>setShowModalError(false)} />
                    <div>{errorMessage}</div>
                </Modal.Body>
            </Modal>
            
            {token &&
                <div className="unsubscribe_container">
                    <h1 className="title">Email Preferences</h1>
                    <h3 className="greeting">Hello, <b>{email}</b></h3>
                    <p className="text">Please check the boxes according to your email preferences and click the button below to unsubscribe from the selected services.</p>
                    <br/>
                    <form className="unsubscribe_form">
                        <p className="heading">Unsubscribe from:</p>

                        <div className="unsubscribe_checkbox">
                            <input type="checkbox" className="email_checkbox" checked={programmatic} onChange={()=>setProgrammatic(prev=>!prev)}/>
                            <label>
                                <p className="label_title">Email Notifications</p>
                                <p className="label_description">When selected, you will unsubscribe from receiving <b>Job Invitations</b> and <b>Job Application</b> updates</p>
                            </label>
                        </div>

                        <div className="unsubscribe_checkbox">
                            <input type="checkbox" className="email_checkbox" checked={promotional} onChange={()=>setPromotional(prev=>!prev)}/>
                            <label>
                                <p className="label_title">Promotional Emails</p>
                                <p className="label_description">When selected, you will unsubscribe from promotional emails</p>
                            </label>
                        </div>
                        
                        <button type="button" className="unsubscribe_button" onClick={unsubscribe}>Unsubscribe</button>
                    </form>
                </div>
            }
        </>
    )
}

export default Unsubscribe