import { useState } from "react";
import {API, Auth} from "aws-amplify";

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Col, Container, Modal, Row } from "react-bootstrap"
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { IoIosArrowBack } from 'react-icons/io';

import Cities from '../../data/cities.json';
import industryRoles from '../../data/industryRoles.json';
import { useDispatch, useSelector } from "react-redux";
import Tips from "../../components/Tips/Tips";
import CalendarFilter from "../../components/Calendar/CalendarFilter";
import ChooseProject from "./ChooseProject";

import "../FindCrew/FindCrew.scss";
import "./PostJob-Form.scss";

const PostJobForm = (props) => {
    const dispatch = useDispatch();
    const union_tag = useSelector(state=>state.union)

    const [projectId,setProjectId] = useState("")
    const [newProject,setNewProject] = useState("")
    const [producers,setProducers] = useState("")
    const [position, setPosition] = useState(null)
    const [city,setCity] = useState("")
    const [voluntary,setVoluntary] = useState(false)
    const [union,setUnion] = useState(false)
    const [covid,setCovid] = useState(false)
    const [dates,setDates] = useState([null,null])
    const [prevDates,setPrevDates] = useState([null,null])
    const [remote,setRemote] = useState(false)
    const [description,setDescription] = useState("")
    const [salary,setSalary] = useState("")
    const [notification,setNotification] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [unionMemberOnly,setUnionMemberOnly] = useState(false)

    const options = [].concat(...Object.values(industryRoles)).map(role => {
        const category = Object.keys(industryRoles).find(key => industryRoles[key].includes(role));
        return {
            category,
            role
        }
    })

    //Handlers
    const getDays = (start,end) => {
        setDates([start,end])
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    const handleProjectId = (id) => {
        setProjectId(id)
    }

    const handleNewProject = (name) => {
        setNewProject(name)
    }

    const handleAutocomplete = (dates,location,producers) => {
        if (dates[1]) setPrevDates([dates[0],dates[1]])
        if (location) setCity(location)
        if (producers) setProducers(producers)
    }

    //Api calls
    const createProject = async () => {
        const apiName = "CrewbiteAPI";
        const path = "create-project";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const date_format = dates[1] ? `${(new Date(dates[0]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")},${(new Date(dates[1]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")}` : undefined

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                title: newProject,
                location_addr: city,
                producers: producers,
                production_date: date_format
            }
        }

        try {
            const new_project_id = await API.post(apiName, path, myInit)
            return new_project_id
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        const metaData = []
        let new_project_id = null

        if (voluntary) metaData.push("volunteer_work")
        if (union) metaData.push("union")
        if (covid) metaData.push("covid_vac")
        if (remote) metaData.push("remote")

        if (!projectId && newProject) new_project_id = await createProject() 

        const body = {}
        body["project_id"] = projectId ? projectId : new_project_id                           
        if (producers) body["producers"] = producers                              
        if (city) body["location_addr"] = city                 
        if (description) body["description"] = description                              
        if (position) body["industry_role"] = position                           
        if (dates[0]&&dates[1]) body["production_date"] = `${(new Date(dates[0]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")},${(new Date(dates[1]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")}`            
        if (metaData) body["metadata"] = metaData                     
        if (salary) body["salary"] = salary 
        if (unionMemberOnly) body["union_tag"] = union_tag
        body["notification"] = notification                                  

        const apiName = "CrewbiteAPI";
        const path = "create-job-posting";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body
        };
        try {
            await API.post(apiName, path, myInit)
            props.closeForm(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }    
    }

    return (
        <>
            <Modal className="success-modal tips" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
                <Modal.Body>
                    <CloseIcon className="closeButton" onClick={()=>setShowModal(false)} />
                    <p>The best tip that Crewbite’s team can give you when posting a new job listing page is to be specific about what you're looking for.</p>
                    <br/>
                    <p>Be specific about the position that you are looking for, where the location is, and it would always be helpful to double check the information you are putting in before submitting the post. </p>
                    <br/>
                    <p>In order to help crew members better understand their responsibilities, provide all of the specific job requirements for the position. This will not only make your production run more smoothly, but it will also help you reduce the risk of future miscommunication.</p>
                    <br/>
                    <p>Need support? Email us at <span className="email">support@crewbite.com</span></p>
                </Modal.Body>
            </Modal>

            <Container className="hire-crew-container post-job-container">
                <Row className="hire-crew-row">
                    <Col className="hire-crew-col">
                    <div className="job_form_title"><IoIosArrowBack className="icon" onClick={()=>props.closeForm(false)}/><h1 className="title">Post a new Job</h1></div>
                        <div className="need-tips">
                            <p className="text">Need tips? <span onClick={()=>setShowModal(true)}>Click here</span></p>
                        </div>
                        
                        <form onSubmit={handleSubmit} className="form" autoComplete="none">
                            {union_tag && union_tag !== "default" &&
                                <div className="union-specific-radio">
                                    <div className="label-with-tip">
                                         <h1 className="heading">This job will be visible to <span className="required">*</span></h1>
                                         <Tips content="By enabling this checkbox, only the members of your union will be able to see and apply for this job"/>
                                    </div>
                                    <div className="option">
                                        <input 
                                            type="radio" 
                                            name="public" 
                                            id="public" 
                                            checked={!unionMemberOnly} 
                                            onChange={()=>setUnionMemberOnly(prev=>!prev)}
                                        />
                                        <label htmlFor="public">Everyone</label>
                                    </div>
                                    <div className="option">
                                        <input 
                                            type="radio" 
                                            name="union_only" 
                                            id="union_only" 
                                            checked={unionMemberOnly} 
                                            onChange={()=>setUnionMemberOnly(prev=>!prev)}
                                        />
                                        <label htmlFor="union_only">{union_tag} members only</label>
                                    </div>
                                </div>
                            }
                            <div className="label-with-tip">
                                <h1 className="heading">Create job under new production? <span className="required">*</span></h1>
                                <Tips content="You can create a new production or choose from your existing productions."/>
                            </div>

                            <ChooseProject 
                                handleProjectId={handleProjectId} 
                                handleNewProject={handleNewProject}
                                handleAutocomplete={handleAutocomplete}    
                            />

                            <h5 className="heading">Producer(s) <span className="required">*</span></h5>
                            <input 
                                className="post-job-input" 
                                type="text" 
                                placeholder="Name of the producer" 
                                value={producers} 
                                maxLength="100"
                                onChange={(e)=>setProducers(e.target.value)} 
                                required
                                autoComplete="none"
                            />
                            
                            <div className="select-location">
                                <h5 className="heading">Location <span className="required">*</span></h5>
                                <div className="inputs">

                                    <div className="cityInput">
                                        <Autocomplete
                                            filterOptions={filterOptions}
                                            disablePortal
                                            id="city"
                                            options={Cities}
                                            value = {city}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} placeholder="Select a city" required/>}
                                            onChange={(e,value)=>setCity(value)}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="checkbox remote-checkbox">
                                <div>
                                    <input type="checkbox" name="remote" id="remote" onChange={(e)=>setRemote(e.target.checked)}/>
                                    <label htmlFor="remote">Remote work</label>
                                </div>
                                <Tips content="By enabling remote work, all the crew in our database will be able to view and apply for your job posting. This will come in handy if you are looking for non-on-set roles such as editors, colourists, and VFX artists."/>
                            </div>

                            <div className="select-position">
                                <h5 className="heading">Position <span className="required">*</span></h5>
                                <div className="inputs">
                                    <div className="roleInput">
                                            <Autocomplete
                                                disablePortal
                                                options={(options.sort((a,b)=>-b.category.localeCompare(a.category)))}
                                                groupBy={(option) => option.category}
                                                getOptionLabel={(option) => option.role || position}
                                                fullWidth={true}
                                                renderInput={(params) => <TextField {...params} placeholder="Add one position only" required/>}
                                                onChange={(e,value) => setPosition(value?.role || null)}
                                            />
                                        </div>
                                </div>
                            </div>

                            <h5 className="heading">Salary</h5>
                            <input 
                                className="post-job-input" 
                                type="text" 
                                placeholder="Please state the currency" 
                                value={salary}
                                maxLength="60"
                                onChange={(e)=>setSalary(e.target.value)}
                                autoComplete="none"
                            />

                            <h5 className="heading">Production Date <span className="required">*</span></h5>
                            <div className="availability">
                                <CalendarFilter prevDates={prevDates} getDays={getDays} required={true}/>
                            </div>
                            
                            <div className="meta-data">
                                <div className="checkbox">
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="voluntary" 
                                            id="voluntary" 
                                            checked={voluntary} 
                                            onChange={(e)=>setVoluntary(e.target.checked)}
                                        />
                                        <label htmlFor="voluntary">Voluntary work</label>
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="union" 
                                            id="union" 
                                            checked={union}
                                            onChange={(e)=>setUnion(e.target.checked)}
                                        />
                                        <label htmlFor="union">Union member preferred</label>
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="covid" 
                                            id="covid" 
                                            checked={covid} 
                                            onChange={(e)=>setCovid(e.target.checked)}
                                        />
                                        <label htmlFor="covid">Covid-19 Vaccination Required</label>
                                    </div>
                                </div>
                            </div>
                            
                            <h5 className="heading">Information and Requirements</h5>
                            <textarea 
                                className="requirements post-job-input" 
                                placeholder="Detail of the production"
                                value={description}
                                maxLength="3000"
                                onChange={(e)=>setDescription(e.target.value)}
                            />

                            <div className="notifications">
                                <input 
                                    type="checkbox" 
                                    name="notifications" 
                                    id="notifications" 
                                    checked={notification} 
                                    onChange={(e)=>setNotification(e.target.checked)}
                                />
                                <label htmlFor="notifications">Enable email notifications for every application submitted</label>
                            </div>

                            <div className="buttons">
                                <button type="button" className="back" onClick={()=>props.closeForm(false)}>Back</button>
                                <input className="submit" type="submit" value="Post"/>
                            </div>
                            
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PostJobForm;
