import { Autocomplete, TextField } from "@mui/material";
import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PaginationItems from "../Pagination/PaginationItems";
import UnionMap from "../../data/union_map.json"


const JobTabs = (props) => {
    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const [openActive, setOpenActive] = useState(1)
    const [closedActive, setClosedActive] = useState(1)
    const [projectOptions,setProjectOptions] = useState([])
    const [project,setProject] = useState(null)
    const [tab, setTab] = useState("open")

    useEffect(()=>{
        const parameters = Object.fromEntries([...searchParams])
        
        if (Object.keys(parameters).length !== 0) {
            setTab(parameters.tab)
            parameters.tab === "open" ? setOpenActive(+parameters.page) : setClosedActive(+parameters.page)
        }
        getProjects()
    },[searchParams])

    const changePage = (page,status) => {
        status === "open" ? setOpenActive(page) : setClosedActive(page)
        if (project) {
            const project_id = projectOptions.find(p=>p.project_title === project).project_id
            setSearchParams({tab:status,page,project_id})
        } else {
            setSearchParams({tab:status,page})
        }
    }

    const getProjects = async () => {
        const apiName = "CrewbiteAPI";
        const path = "list-all-projects";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                owned_only: true
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            setProjectOptions(response)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
            <Tabs
                id="controlled-tab-example"
                activeKey={tab}
                onSelect={(t) => {
                    setProject(null)
                    setSearchParams({tab:t,page:t==="open"?openActive:closedActive})
                }}
                className="mb-3"
            >
                <Tab eventKey="open" title="Active" className="tab-div">
                    <h5>Active Jobs</h5>
                    {props.openJobPostings ? ( 
                        <>
                            <div className="tab_autocomplete">
                                <Autocomplete
                                    disablePortal
                                    id="project"
                                    options={projectOptions}
                                    getOptionLabel={(option) =>option.project_title || project}
                                    fullWidth={true}
                                    value={project}
                                    renderInput={(params) => <TextField {...params} placeholder="Filter Jobs by Production" />}
                                    onChange={(e,value)=>{              
                                        if (!value) {
                                            setProject(null)
                                            props.handleProjectFilter(null,"open")
                                        }
                                        else {
                                            setProject(value.project_title)
                                            props.handleProjectFilter(value.project_id,"open")
                                            setOpenActive(1)
                                        }
                                    }}
                                />
                            </div>
                            {props.openJobPostings.length > 0  ? (
                                <>
                                    <div className="titles">
                                        <p className="union"></p>
                                        <p>Industry Role</p>
                                        <p>Location</p>
                                        <p>Production</p>
                                        <p>Current Applicants</p>
                                        <p>Created on</p>
                                        <div className="buttons_column"></div>
                                    </div>
                                    {props.openJobPostings.map(job => (
                                        <div key={job.job_id} className="job-posting">
                                            {job.union_tag ? (
                                                <img
                                                    className="small_union_logo"
                                                    alt="Union logo"
                                                    src={require(`../../icons/${UnionMap[job.union_tag].corner_logo}`)}
                                                />
                                                ) : (
                                                    <div className="small_union_logo"></div>
                                                )
                                            }
                                            <p>{job.industry_role}</p>
                                            <p>{job.location_addr}</p>
                                            <p className="production_title" onClick={()=>navigate(`/project/${job.project_id}`)}>{job.production_title}</p>
                                            <p>{job.applicants}</p>
                                            <p>{(new Date(job.created_at*1000)).toLocaleDateString()}</p>
                                            <div className="buttons">
                                                <Link 
                                                    to={`/post-a-job/${job.job_id}?status=open`} 
                                                    state= {{
                                                        prev: `${location.pathname}?${searchParams.toString()}`,
                                                    }}  
                                                    className="view-button"
                                                >
                                                    View
                                                </Link>
                                                <button className="close-button" onClick={()=>props.handleShowModalClose(job.job_id)}>Close</button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p>You have no Open Jobs</p>
                            )}
                        </>
                    ) : (
                        <Spinner className="small-loader" animation="border" />
                    )}
                    {props.numOfOpenElements > props.items_per_page && 
                        <PaginationItems
                            numOfElements={props.numOfOpenElements} 
                            active={openActive} 
                            changePage={changePage}
                            items_per_page={props.items_per_page}
                            status={"open"}
                        />
                    }   
                </Tab>
                <Tab eventKey="closed" title="Closed" className="tab-div">
                    <h5>Closed Jobs</h5>
                    {props.closedJobPostings ? (props.closedJobPostings.length > 0 ? ( 
                        <>
                            <div className="titles">
                                <p>Industry Role</p>
                                <p>Location</p>
                                <p>Production</p>
                                <p>Current Applicants</p>
                                <p>Created on</p>
                                <div className="buttons_column"></div>
                            </div>
                            {props.closedJobPostings.map(job => (
                                <div key={job.job_id} className="job-posting">
                                    <p>{job.industry_role}</p>
                                    <p>{job.location_addr || "Remote"}</p>
                                    <p>{job.production_title}</p>
                                    <p>{job.applicants}</p>
                                    <p>{(new Date(job.created_at*1000)).toLocaleDateString()}</p>
                                    <div className="buttons">
                                        <Link 
                                            to={`/post-a-job/${job.job_id}`} 
                                            state= {{
                                                prev: `${location.pathname}?${searchParams.toString()}`,
                                            }} 
                                            className="view-button"
                                        >
                                            View
                                        </Link>
                                        <button className="close-button" onClick={()=>props.handleShowModalDelete(job.job_id)}>Delete</button>
                                    </div>
                                </div>
                            ))}
                        </>
                        ) : (
                            <p>You have no Closed Jobs</p>
                        )) : ( 
                        <Spinner className="small-loader" animation="border" />
                    )}

                    {props.numOfClosedElements > props.items_per_page && 
                        <PaginationItems 
                            numOfElements={props.numOfClosedElements} 
                            active={closedActive} 
                            changePage={changePage}
                            items_per_page={props.items_per_page}
                            status={"closed"}
                        />
                    }   
                </Tab>
            </Tabs>
        </>
    )
}

export default JobTabs;