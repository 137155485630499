import { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { memo } from "react";

import Cities from '../../data/cities.json';
import industryRoles from '../../data/industryRoles.json';

import "./Filter.scss";
import FilterTags from "./FilterTags";
import PaginationItems from "../Pagination/PaginationItems";


const FilterJobs = (props) => {

    const location = useLocation()
    const union_tag = useSelector(state=>state.union)

    const [searchParams, setSearchParams] = useSearchParams();

    const [position, setPosition] = useState([])
    const [role, setRole] = useState(null)
    const [city,setCity] = useState(null)
    const [radius,setRadius] = useState("150")
    const [remote,setRemote] = useState(false)
    const [union,setUnion] = useState(false)
    const [covid,setCovid] = useState(false)
    const [voluntary,setVoluntary] = useState(false)
    const [active, setActive] = useState(props.active)
    const [anchorEl, setAnchorEl] = useState(null)
    const [reload,setReload] = useState(false)
    const [unionMemberOnly,setUnionMemberOnly] = useState(false)
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const options = [].concat(...Object.values(industryRoles)).map(role => {
        const category = Object.keys(industryRoles).find(key => industryRoles[key].includes(role));
        return {
            category,
            role
        }
    })


    useEffect(()=> {
        const filters = props.filters

        if (filters) {
            if (filters.position) setPosition(filters.position.split(','))
            if (filters.city) setCity(filters.city)
            if (filters.radius) setRadius(filters.radius)
            if (filters.union && JSON.parse(filters.union)) setUnion(JSON.parse(filters.union))
            if (filters.covid && JSON.parse(filters.covid)) setCovid(JSON.parse(filters.covid))
            if (filters.remote && JSON.parse(filters.remote)) setRemote(JSON.parse(filters.remote))
            if (filters.voluntary && JSON.parse(filters.voluntary)) setVoluntary(JSON.parse(filters.voluntary))
            if (filters.union_tag) setUnionMemberOnly(true)
        }
       
    },[props.filters])

    useEffect(()=>{
        if (position.length > 0 || city || union || covid || voluntary || remote || unionMemberOnly || reload) {
            const params = {
                page:1,
                sort:props.filters.sort
            }

            if (position.length > 0) params.position = position.join(",")
            if (city)  {
                params.city = city
                params.radius = radius
            }
            if (union) params.union = union
            if (covid) params.covid = covid
            if (voluntary) params.voluntary = voluntary
            if (remote) params.remote = remote
            if (unionMemberOnly) params.union_tag = union_tag
            setReload(true)
            setActive(1)
            props.getFilters(params)
        }
    },[position.length,city,radius,union,covid,voluntary,unionMemberOnly,remote])

    //Handlers
    const resetAll = () => {
        setPosition([]);
        setCity("")
        setUnion(false)
        setCovid(false)
        setVoluntary(false)
        setRemote(false)
        setUnionMemberOnly(false)
    }

    const handlePopper = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleSort = (value) => {
        props.sortResults(value)
        setAnchorEl(null)
    }

    const handleTags = (tag,value) => {
        switch (tag) {
            case "position":
                setPosition(prev => prev.filter(r=>r!==value));
                break;
            case "city":
                setCity("")
                break;
            case "union":
                setUnion(false)
                break;
            case "covid":
                setCovid(false)
                break;
            case "voluntary":
                setVoluntary(false)
                break;
            case "remote":
                setRemote(false)
                break;
            case "union_tag":
                setUnionMemberOnly(false)
            default:
                break;
        }
    }
    
    const changePage = (page) => {
        setActive(page)
        if (props.filters) {
            const copyFilters = props.filters
            copyFilters.page=page
            setSearchParams(copyFilters) 
        }
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    return (
        <Container className="filter-container">
            <Row className="filter-row">
                <Col sm={12} md={4} className="filter">
                    <form className="form" autoComplete="off">
                        {union_tag && union_tag !== "default" &&
                        <>
                            <h5 className="heading union_tag_label">Search for:</h5>
                            <div className="union-specific-radio">
                                <div className="option">
                                    <input 
                                        type="radio" 
                                        name="public" 
                                        id="public" 
                                        checked={!unionMemberOnly} 
                                        onChange={()=>setUnionMemberOnly(prev=>!prev)}
                                    />
                                    <label htmlFor="public">Public Jobs</label>
                                </div>
                                <div className="option">
                                    <input 
                                        type="radio" 
                                        name="union_only" 
                                        id="union_only" 
                                        checked={unionMemberOnly} 
                                        onChange={()=>setUnionMemberOnly(prev=>!prev)}
                                    />
                                    <label htmlFor="union_only">{union_tag} Internal Jobs</label>
                                </div>
                                {/* <Tips content="By enabling this checkbox, only the members of your union will be able to see and apply for this job"/> */}
                            </div>
                        </>
                        }
                        <div className="select-position">
                            <h5 className="heading">Position</h5>
                            <div className="inputs">
                                <div className="roleInput">
                                    <Autocomplete
                                        disablePortal
                                        options={position.length < 20 ? (options.sort((a,b)=>-b.category.localeCompare(a.category))) : ([])}
                                        groupBy={(option) => option.category}
                                        getOptionLabel={(option) => option.role || ""}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="Role" />}
                                        value={role}
                                        onChange={(e,value) => {
                                            setRole(value.role)
                                            setPosition(prev => 
                                                value.role && !prev.some(val => val === value.role) ? [...prev, value.role] : prev
                                            )
                                            setTimeout(()=>{
                                               setRole(null) 
                                            },50)
                                        }}
                                    />
                                </div>
                            </div>
                            {position.length >= 20 && <p className="roles-error">You can filter a maximum of 20 roles</p>}
                        </div>
                            
                        <div className="select-location">
                            <h5 className="heading">Location</h5>
                            <div className="inputs">

                                <div className="cityInput">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        disablePortal
                                        id="city"
                                        options={Cities}
                                        value = {city}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="City" />}
                                        onChange={(e,value)=>setCity(value)}
                                    />
                                </div>

                                <div className="radiusInput">
                                    <label htmlFor="radius">Radius</label>
                                    <ArrowDropDownIcon className="radius-arrow"/>
                                    <select onChange={(e)=>setRadius(e.target.value)} name="radius" id="radius" value={radius}>
                                        <option value="150">150KM</option>
                                        <option value="300">300KM</option>
                                        <option value="500">500KM</option>
                                        <option value="1000">1000KM</option>
                                        <option value="20000">Any Place</option>
                                    </select>    
                                </div>
                            </div>
                        </div>

                        <div className="meta-data">

                            <div className="checkbox">
                                <input 
                                    type="checkbox" 
                                    name="union" 
                                    id="union" 
                                    checked={union} 
                                    onChange={(e)=>setUnion(e.target.checked)}
                                />
                                <label htmlFor="union">Union member preferred</label>
                            </div>

                            <div className="checkbox">
                                <input 
                                    type="checkbox" 
                                    name="covid" 
                                    id="covid" 
                                    checked={covid} 
                                    onChange={(e)=>setCovid(e.target.checked)}
                                />
                                <label htmlFor="covid">Covid-19 Vaccination Required</label>
                            </div>

                            <div className="checkbox">
                                <input 
                                    type="checkbox" 
                                    name="remote" 
                                    id="remote" 
                                    checked={remote} 
                                    onChange={(e)=>setRemote(e.target.checked)}
                                />
                                <label htmlFor="remote">Remote work only</label>
                            </div>

                            <div className="checkbox">
                                <input 
                                    type="checkbox" 
                                    name="voluntary" 
                                    id="voluntary" 
                                    checked={voluntary} 
                                    onChange={(e)=>setVoluntary(e.target.checked)}
                                />
                                <label htmlFor="voluntary">Voluntary work only</label>
                            </div>
                        </div>
                        
                        <input onClick={resetAll} className="search" type="button" value="Reset Filters"/>
                    </form>
                </Col>
                <Col sm={12} md={8} className="crewInfo">    
                    <div className="tags">
                        <FilterTags
                            handleTags={handleTags}
                            position={position}
                            city={city}
                            union={union}
                            covid={covid}
                            voluntary={voluntary}
                            remote={remote}
                            union_tag={unionMemberOnly?union_tag:false}
                        />
                    </div>

                    {!props.loading && props.jobs ? (
                        props.jobs.length > 0 ? (
                            <div className="preview-col" sm={12} md={8}>

                                <div className="sort">
                                    <p className="text">{props.numOfElements} available jobs found</p>
                                    <p className="open-popper" onClick={handlePopper}>Sorting: {props.sort}  <img alt="sort jobs icon" src={require('../../icons/filter.png')} /> </p>
                                    <Popper id={id} open={open} anchorEl={anchorEl}>
                                        <div className="popper">
                                            {/* <p onClick={()=>handleSort("default")} className={props.sort==="default" ? "active-sort" : ""}>Default</p> */}
                                            <p onClick={()=>handleSort("created_at")} className={props.sort==="created_at" ? "active-sort" : ""}>Created At (Most recent first)</p>
                                            <p onClick={()=>handleSort("distance")} className={props.sort==="distance" ? "active-sort" : ""}>Distance (Nearest first)</p>  
                                        </div>                     
                                    </Popper>
                                </div>
                                
                                {props.jobs.map(job => (                   
                                    <Link 
                                        to={`/job/${job.job_id}`} 
                                        state={{prev: `${location.pathname}?${searchParams.toString()}`}} 
                                        key={job.job_id} 
                                        className="job_card"
                                    >   
                                        <h5 className="role">{job.industry_role}</h5>
                                        <div className="job_card_content">
                                            <div className="job_info">
                                                <p className="production_title">{job.production_title}</p>
                                                <p className="location"><img alt="job location icon" src={require("../../icons/location.png")}/> {job.location_addr}</p>
                                                <p className="created-at">Created on: {(new Date(job.created_at*1000)).toLocaleDateString()}</p>
                                            </div>
                                            
                                            <div className="metaData">
                                                {job.metadata && job.metadata.map(meta => {
                                                    let text
                                                    switch (meta) {
                                                        case "covid_vac":
                                                            text = "Covid-19 Vaccination Required"
                                                            break;
                                                        case "volunteer_work": 
                                                            text = "This is a voluntary work"
                                                            break;
                                                        case "union":
                                                            text = "Union member preferred"
                                                            break;
                                                        case "remote":
                                                            text = "Remote position"
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                    return text && <p key={meta}><img alt="check icon" src={require("../../icons/check.png")}/> {text}</p>
                                                })}
                                            </div>
                                        </div>
                                    </Link>
                                ))}                     
                            </div>
                        ) : (
                            <Row className="no-match">
                                <h3>No matches found</h3>
                            </Row>
                    )) : (
                        <Spinner className="small-loader" animation="border" />
                    )}
                    
                    {props.numOfElements > props.items_per_page && 
                        <PaginationItems
                            numOfElements={props.numOfElements} 
                            active={active} 
                            changePage={changePage}
                            items_per_page={props.items_per_page}
                        />
                    }      
                </Col>
            </Row>
        </Container>
    )
}

export default memo(FilterJobs)