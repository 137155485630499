import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import project_configuration from "../../configurations/project_configuration";

import "./About.scss";


const About = () => {
    const [ windowHeight, setWindowHeight ] = useState(window.innerHeight);

    const location = useLocation()

    useEffect(()=> {
        if (location.hash) {
            const hash = location.hash.substring(1);
            const contactSecction = document.getElementById(hash)
            contactSecction?.scrollIntoView();
        }
    },[location])

    useEffect(()=>{
        const elements = document.getElementsByClassName('animated-row');
        const images = document.getElementsByClassName('animated-img');
        const otherElements = document.getElementsByClassName('other-animations');

        window.onresize = () => {
            setWindowHeight(window.innerHeight);
        }

        if (elements) checkPosition(elements);
        if (images) checkPosition(images,true);
        if (otherElements) checkPosition(otherElements,true);

        window.onscroll = () => {
            if (elements) checkPosition(elements);
            if (images) checkPosition(images,true);
            if (otherElements) checkPosition(otherElements,true);
        }
    },[windowHeight])

    const checkPosition = (elements,isImage) => {
        for (let element of elements) {
            let positionFromTop = element.getBoundingClientRect().top;
            if (positionFromTop - windowHeight <= 0) {
                element.classList.add('fadein');
                if (!isImage) element.classList.add('translate');
            } else {
                element.classList.remove('fadein');
                if (!isImage) element.classList.remove('translate');
            }
        }
    }

    return (
        <>
        <Helmet>
            <title>About Us - Crewbite Inc.</title>
            <link rel="canonical" href={`https://www.${project_configuration.main_url}/about`}/>
            <meta
                name="description"
                content="Crewbite Social Networking Services. Get in touch for collaborative opportunities and learn more about us."
            />;
        </Helmet>
        <Container fluid className="about-container">
            <Row className="about-row">
                <Col className="about-col">
                    <img className="bg-image animated-img" alt="crewbite about page background image" src={require("../../images/crewbite-background-blue.jpg")}/>
                    <h1 className="title">THE REVOLUTIONARY PLATFORM FOR ALL YOUR PRODUCTION NEEDS</h1>
                    <h3 className="text other-animations">Whether you’re a producer searching for reliable crew, or an industry professional searching for your next gig, Crewbite is here for you. We make the connections that make screens come to life.</h3>
                </Col>
            </Row>
            <Row id="mission" className="mission animated-row">
                <img className="mission-image" alt="crewbite mission" src={require("../../icons/director-chair.png")}/>
                <h2 className="subtitle">Our Mission</h2>
                <p className="text">Crewbite's mission is to help create more intriguing visuals and stories by connecting production companies, filmmakers, artists, and resources together. Our goal is to make every logistical flow easier than before.</p>
            </Row>
            <Row id="vision" className="vision animated-row">
                <img className="vision-image" alt="crewbite vision" src={require("../../icons/user.png")}/>
                <h2 className="subtitle">Our Vision</h2>
                <p className="text">We noticed that it takes a lot of time for producers and heads of departments to find everything they need, and we also met a lot of people in the industry who were having trouble finding new jobs, so they couldn't keep working in the field they love. Therefore, we decided to find a revolutionary way to change how people work in the industry and help make the industry that we love better by building a platform that helps everyone work better and faster.</p>
            </Row>
            <Row id="team" className="team animated-row">
                <h2 className="subtitle">Our Team</h2>
                <Col className="team-card">
                    <div className="team-img animated-img">
                        <img alt="Jasmin Lyu (Founder) crewbite" src={require("../../images/Jasmin.jpg")}/>
                    </div>
                    <h3 className="name text">Jasmin Lyu</h3>
                    <p className="position text">Founder/CEO</p>
                </Col>
                <Col className="team-card">
                    <div className="team-img animated-img">
                        <img alt="Shiyang Xia (CTO) crewbite" src={require("../../images/Bruce.jpg")}/>
                    </div>
                    <h3 className="name text">Shiyang Xia</h3>
                    <p className="position text">CTO</p>
                </Col>
                <Col className="team-card">
                    <div className="team-img animated-img">
                        <img alt="Miranti (UX/UI Designer) crewbite" src={require("../../images/Miranti.jpg")}/>
                    </div>
                    <h3 className="name text">Miranti</h3>
                    <p className="position text">Lead UX/UI Designer</p>
                </Col>
                <Col className="team-card">
                    <div className="team-img animated-img">
                        <img alt="Gerardo Montero (Frontend Developer) crewbite" src={require("../../images/Gerardo.jpg")}/>
                    </div>
                    <h3 className="name text">Gerardo Montero</h3>
                    <p className="position text">Lead Frontend Developer</p>
                </Col>
            </Row>
            {/* <Row className="readings">
                <h2 className="subtitle">Recommended Readings</h2>
                <Col>
                    <div className="readings-post">
                        <p className="reading-type">BLOG</p>
                        <h3 className="reading-title">Something about makeup that you should know</h3>
                        <p className="reading-text">For some people, make up is a for fun. But in film industry, make up is a necessity to create a visual…</p>
                    </div> 
                </Col>
                <Col>
                    <div className="readings-post">
                        <p className="reading-type">BLOG</p>
                        <h3 className="reading-title">How to make a pitch video that truly tells</h3>
                        <p className="reading-text">A striking and engaging pitch video is crucial in getting the attention for the audience…</p>
                    </div>
                </Col>
                <Col>
                    <div className="readings-post">
                        <p className="reading-type">NEWS</p>
                        <h3 className="reading-title">Now you can have a group chat with your crew!</h3>
                        <p className="reading-text">New feature has been launched at crew bite. Now you can have a group chat with everyone in…</p>
                    </div>
                </Col>
            </Row>   */}
            <Row id="contact" className="contact-row animated-row">
                <Col className="contact-col">
                    <h2 className="subtitle">Questions? Contact Us!</h2>
                    <p>Get in touch for collaborative opportunities and learn more about us</p>
                    <p>Talk to us at <a href="mailto:heya@crewbite.com">heya@crewbite.com</a></p>
                    <br/>
                    <p>For feature requests, bug reports, plan upgrade/cancellation, or any other inquiries</p>
                    <p>Contact <a href="mailto:support@crewbite.com">support@crewbite.com</a></p>
                </Col>
            </Row>
            <Row >
                <Footer />  
            </Row>
        </Container>
        </>
    )
}

export default About;
