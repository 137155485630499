import { Spinner, Tab, Tabs, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from '@mui/icons-material/Close';
import CreateProject from "./CreateProject";
import "./ProjectDashboard.scss"


const ProjectDashboard = () => {
    const dispatch = useDispatch();
    const subscription = useSelector(state => state.isPremium)
    const navigate = useNavigate()

    const [projects,setProjects] = useState(undefined)
    const [showForm,setShowForm] = useState(false)
    const [tab,setTab] = useState("all")
    const [showApiMeter,setShowApiMeter] = useState(false)
    const [notSubscribed,setNotSubscribed] = useState(false)

    const createNewProject = () => {
        setShowForm(true)
    }

    useEffect(()=>{
        getProjects(tab)
    },[tab])

    useEffect(()=>{
        if (subscription) {
            setNotSubscribed(!subscription.subscription_status || (subscription.subscription_status && subscription.subscription_cancel_at_period_end))
        }
    },[subscription])

    //Handlers
    const handleCreatePopper = async () => {
        if (await checkApiMeter()) createNewProject()
    }

    //Api calls
    const manageSubscription = async () => {
        const apiName = "CrewbiteAPI";
        const path = "customer_portal";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        
        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.post(apiName, path, myInit)
            window.location.href = response.session_url
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const checkApiMeter = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-api-meter";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                paths: "create-project"
            }
        };
        try {
            const response = await API.get(apiName, path, myInit)
            if (response.owned_productions > 0) {
                return true
            } else {
                setShowApiMeter(true)
                return false
            }
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const getProjects = async (tab) => {
        const apiName = "CrewbiteAPI";
        const path = "list-all-projects";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                owned_only: tab === "owned" ? true : false
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            setProjects(response)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }


    return (
        <>
            <Helmet>
                <title>Production Dashboard - Crewbite Inc.</title>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showApiMeter}>
                <Modal.Body>
                    <CloseIcon className="closeButton" onClick={()=>setShowApiMeter(false)} />
                    <div>
                        <br/>
                        <p>You run out of quota usage for the following category: Production owned limit.</p>
                        {notSubscribed ? (
                            <button className="upgrade" onClick={()=>navigate("/plan-selection")}>Go Premium</button> 
                        ) : (
                            <button className="upgrade" onClick={manageSubscription}>Upgrade Plan</button> 
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <CreateProject showForm={showForm} closeForm={() => setShowForm(false)}/>

            <div className="project_dashboard_container">
                <h1 className="project_dashboard_title">Production Dashboard</h1>
                <p className="project_dashboard_text">Here's where you can manage all the productions that you are working on. <b>Create. Hire. Manage</b></p>

                <button className="create_new_project" onClick={handleCreatePopper}>Create new production</button>

                <Tabs
                    id="controlled-tab-example"
                    activeKey={tab}
                    onSelect={(t) => setTab(t)}
                    className="mb-3"
                >
                    <Tab eventKey="all" title="All productions" className="tab-div">
                        <div className="active_projects_container">
                            <div className="project first_row">
                                <p className="project_name">Title</p>
                                <p className="project_role">Role in production</p>
                            </div>
                            {projects ? (
                                projects.length > 0 ? (
                                    projects.map(project => (
                                        <div className="project" key={project.project_id}>
                                            <p className="project_name">{project.project_title}</p>
                                            <p className="project_role">{project.employment_title}</p>
                                            <Link className="view_project" to={`/project/${project.project_id}`}>View</Link>
                                        </div>
                                    ))
                                ) : (
                                    <p>You don't have any productions</p>
                                )
                            ):(
                                <Spinner className="small-loader" animation="border" />
                            )}
                        </div>  
                    </Tab>
                    <Tab eventKey="owned" title="Productions I own" className="tab-div">
                        <div className="active_projects_container">
                            <div className="project first_row">
                                <p className="project_name">Title</p>
                                <p className="project_role">Role in production</p>
                            </div>
                            {projects ? (
                                projects.length > 0 ? (
                                    projects.map(project => (
                                        <div className="project" key={project.project_id}>
                                            <p className="project_name">{project.project_title}</p>
                                            <p className="project_role">{project.employment_title}</p>
                                            <Link className="view_project" to={`/project/${project.project_id}`}>View</Link>
                                        </div>
                                    ))
                                ) : (
                                    <p>You don't have any productions</p>
                                )
                            ):(
                                <Spinner className="small-loader" animation="border" />
                            )}
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default ProjectDashboard