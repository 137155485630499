import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";

import "./OverLimits.scss";

const OverLimits = (props) => {

    const dispatch = useDispatch();
    const [notSubscribed,setNotSubscribed] = useState(false)

    const subscription = useSelector(state => state.isPremium)

    const navigate = useNavigate()

    useEffect(()=>{
        if (subscription) {
            setNotSubscribed(!subscription.subscription_status || (subscription.subscription_status && subscription.subscription_cancel_at_period_end))
        }
    },[subscription])

    const manageSubscription = async () => {
        const apiName = "CrewbiteAPI";
        const path = "customer_portal";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.post(apiName, path, myInit)
            window.location.href = response.session_url
        } catch (error) {
          dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
      }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body className="over-limits-modal">
            <h5>You need to Upgrade your plan to perform this action</h5>
            {notSubscribed ? (
              <button onClick={()=>navigate("/plan-selection")}>Go Premium</button>
            ) : (
              <button onClick={manageSubscription}>Manage Subscription</button>
            )}
        </Modal.Body>
      </Modal>
    );
  }
  export default OverLimits;