import { useState, useEffect} from "react";
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";

import IOSSwitch from "../../components/IOSSwitch/IOSSwitch"


const PrivacySetting = (props) => {
    const dispatch = useDispatch();

    const [privacySetting,setPrivacySetting] = useState()

    useEffect(()=>{
        setPrivacySetting(props.privacySetting)
    },[props.privacySetting])

    const handlePrivacySetting = async () => {
        const apiName = "CrewbiteAPI";
        const path = "update-project-privacy-setting";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                project_id: props.id,
                privacy_setting: props.privacySetting ? 0 : 1   
            }
        }

        try {
            await API.post(apiName, path, myInit)
            props.handlePrivacy(privacySetting ? 0 : 1)
        } catch (error) { 
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        } 
    }

    return (
        <div className="privacy-popper">
            <IOSSwitch
                name="mfa-switch" 
                className="mfa-switch" 
                checked={privacySetting === 1} 
                onClick={handlePrivacySetting}
            />
            <label className="mfa-label" htmlFor="mfa-switch">Share my contact information</label>
        </div>                     
    )
}

export default PrivacySetting