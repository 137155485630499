import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const Skills = (props) => {

    const [skill, setSkill] = useState("")

    return (
        <>
        <div className="skillsInput">
            <input 
                type="text" 
                name="skills" 
                id="skills" 
                placeholder="Enter a skill" 
                maxLength="100"
                value={skill}
                onChange={(e)=>setSkill(e.target.value)}
                onKeyDown={(e)=> {
                    if (e.key === "Enter") {
                        props.handleNewSkills(skill)
                        setSkill("")
                    }
                }}
            />
            {props.newSkills && props.newSkills.length <=20 ? (
                <p 
                    className="add-skill" 
                    onClick={()=>{
                        props.handleNewSkills(skill)
                        setSkill("")
                    }}
                >add skill</p>
            ) : (
                <p className="add-skill">Limit of 20 skills reached</p>
            )}
        </div>

        {props.newSkills && props.newSkills.length>0 &&
        <>
            <div className="skillTags">
                {props.newSkills.map(s=>(
                    <div className="skill" key={s.id}>
                        <p>{s.value}</p>
                        <CloseIcon onClick={()=>props.handleRemoveSkill(s.id)}/>
                    </div>
                ))}
            </div>
        </>
        }
        </>
    )
}

export default Skills;