import { API, Auth } from "aws-amplify"
import { useEffect, useState } from "react"
import ProjectChat from "../../components/ProjectChat/ProjectChat"
import { MdOutlineMarkChatUnread } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet-async"

import "./Messages.scss"

const Messages = () => {
    const newMessages = useSelector(state => state.newMessages)
    const user_id = useSelector(state => state.id)
    const dispatch = useDispatch();

    const [channels,setChannels] = useState([])
    const [currentChannel,setCurrentChannel] = useState(undefined)
    const [shouldFetch,setShouldFetch] = useState(undefined)

    useEffect(()=>{
        fetchChannels(true)
    },[])

    useEffect(()=>{
        if (newMessages) {
            if (shouldFetch && newMessages.length > 0 && currentChannel && currentChannel.channel_id !== newMessages.slice(-1)[0]) {
                fetchChannels()
            }
            if (shouldFetch === false) {
                setShouldFetch(true)
            }
        }
    },[newMessages])

    const handleIsMute = (channel_id,is_mute) => {
        setChannels(prev => {
            return prev.map(ch=> {
                if (ch.channel_id === channel_id) {
                    ch.is_mute = is_mute
                }   
                return ch
            })
        })
    }

    const fetchChannels = async (initial=false) => {
        const apiName = "CrewbiteAPI"
        const path = "fetch-message-channels"
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            setChannels(response)
            if (!currentChannel) {
                setCurrentChannel(response[0])
            }
            if (initial) setShouldFetch(false)
        } catch (error) { 
            if (error.response.status !== 429) {
                dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Conversations - Crewbite Inc.</title>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className="messages_container">
                {channels.length > 0 ? (
                    <>
                        <h1 className="title">CONVERSATIONS</h1>
                        <div className="channels_preview">
                            {channels.map(channel => (
                                <div 
                                    className={`channel ${currentChannel && currentChannel.channel_id === channel.channel_id && "active_channel"}`} 
                                    key={channel.channel_id} 
                                    onClick={()=>{
                                        setCurrentChannel(channel)
                                    }}
                                >
                                    <div className="channel_col_1">
                                        <div className="target_picture">
                                            {channel.channel_type === "1" ? (
                                                <img alt="project chat pic" src={`https://crewbite-project-pictures.s3.amazonaws.com/${channel.channel_target_id}-compact.jpg`}/>  
                                            ) : (
                                                <img alt="one on one chat pic" src={`https://crewbite-user-profile-pictures.s3.amazonaws.com/${channel.channel_target_id}-compact.jpg`}/>
                                            )}
                                        </div>
                                        <div className="channel_content">
                                            <p className="target_name">
                                                {channel.channel_target_name.length > 25 ? 
                                                    channel.channel_target_name.substring(0,25) + "..." 
                                                    : channel.channel_target_name
                                                }
                                            </p>
                                            <p className="last_message">
                                                {user_id && channel.last_msg_sender_id === user_id ? "You: " : (
                                                    channel.channel_type === "1" &&  channel.last_msg_sender_id && 
                                                        channel.last_msg_sender_first_name + " " + channel.last_msg_sender_last_name + ": " 
                                                )}
                                                {channel.last_msg_type === "1" ? 
                                                    channel.last_msg_body.length > 25 ? 
                                                        channel.last_msg_body.substring(0,25) + "..." 
                                                    : channel.last_msg_body 
                                                : channel.last_msg_type === "2" ? "file" : ""
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="channel_status">
                                        {(channel.is_read === "0" && newMessages?.includes(channel.channel_id)) && <p><MdOutlineMarkChatUnread className="unread_messages"/></p>}
                                        <p className="message_time">
                                            {(new Date(channel.last_msg_tstamp*1000)).toLocaleString("en-CA",{
                                                day: "numeric",
                                                month: "numeric",
                                                year:"numeric",
                                                hour: "numeric",
                                                minute: "numeric"
                                            })}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>  
                        <div className="chat_container">
                            {currentChannel && <ProjectChat channel={currentChannel} handleIsMute={handleIsMute}/>}
                        </div>
                    </>
                ) : (
                    <div className="no_conversations">
                        <h2>You have no conversations.</h2> 
                        <p>To start a conversation with another user visit a profile and click on "send message". Or create or join a production.</p>
                    </div>
                )}
            </div>
        </>
    )
}

export default Messages