import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Container, Modal, Row } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

import Loader from "../../components/Loader/Loader";
import PostJobForm from "../../components/PostJob-Form/PostJob-Form";

import "./PostJob.scss"
import JobTabs from "../../components/JobTabs/JobTabs";


const PostJob = () => {
    const dispatch = useDispatch();
    const { route } = useAuthenticator((context) => [context.route]);
    const [searchParams, setSearchParams] = useSearchParams();
    const subscription = useSelector(state => state.isPremium)
    const hasProfile = useSelector(state => state.hasProfile)
    const navigate = useNavigate()
    
    const [showForm,setShowForm] = useState(false)
    const [openJobPostings, setOpenJobPostings] = useState(undefined);
    const [closedJobPostings, setClosedJobPostings] = useState(undefined);
    const [closedActive, setClosedActive] = useState(1)
    const [numOfOpenElements, setNumOfOpenElements] = useState();
    const [numOfClosedElements, setNumOfClosedElements] = useState();
    const [showModalPost, setShowModalPost] = useState(false);
    const [showModalClose, setShowModalClose] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false); 
    const [showModalError,setShowModalError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [jobToClose,setJobToClose] = useState(null)
    const [jobToDelete,setJobToDelete] = useState(null)
    const [showApiMeter,setShowApiMeter] = useState(false)
    const [notSubscribed,setNotSubscribed] = useState(false)

    const items_per_page = 15
   
    useEffect(()=> {
            const parameters = Object.fromEntries([...searchParams])
        
            if (Object.keys(parameters).length !== 0) {
                if (parameters.tab === "closed") setClosedActive(+parameters.page)
                getJobPostings(parameters.tab,parameters.page,parameters.project_id)
            } else {
                getJobPostings("open",1)
            }
    },[searchParams])

    useEffect(()=>{
        if (subscription) {
            setNotSubscribed(!subscription.subscription_status || (subscription.subscription_status && subscription.subscription_cancel_at_period_end))
        }
    },[subscription])

    //Handlers
    const handleShowForm = (success) => {
        setShowForm(false)
        if (success) {
            setOpenJobPostings([])
            getJobPostings("open",1)
            setShowModalPost(true)
        }
    }

    const handleShowModalClose = (job_id) => {
        setJobToClose(job_id)
        setShowModalClose(true)
    }

    const handleShowModalDelete = (job_id) => {
        setJobToDelete(job_id)
        setShowModalDelete(true)
    }
    const handleProjectFilter = (project_id,status) => {
        if (project_id) setSearchParams({tab:status,page:1,project_id})
        else setSearchParams({tab:status,page:1})
    }

    const openJobForm = async () => {
        if (!hasProfile) {
            setErrorMessage(
                <div className="create-profile">
                    <p>A profile is required to post a job</p> 
                    <Link to={"/create-profile"} className="create-p-button">Create Profile</Link>
                </div>)
            setShowModalError(true)
        } else if (await checkApiMeter()) {
            setShowForm(true)
        } 
    }

    //Api calls
    const manageSubscription = async () => {
        const apiName = "CrewbiteAPI";
        const path = "customer_portal";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        
        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.post(apiName, path, myInit)
            window.location.href = response.session_url
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const checkApiMeter = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-api-meter";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                paths: "create-job-posting"
            }
        };
        try {
            const response = await API.get(apiName, path, myInit)
            if (response.active_jobs <= 0) {
                setShowApiMeter("active")
                return false
            } else if (response.total_jobs <=0) {
                setShowApiMeter("total")
                return false
            } else {         
                return true
            }
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const getJobPostings = async (status,page,project_id=undefined) => {
        const apiName = "CrewbiteAPI";
        const path = "get-job-postings";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const queryStringParameters = {
            status,
            start_index: (page - 1) * items_per_page,
            end_index: (page * items_per_page) - 1,
        }

        if (project_id) queryStringParameters.project_id = project_id

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters
        }

        try {
            const response = await API.get(apiName, path, myInit)
            if (status === "open") {
                setOpenJobPostings(response.jobs) 
                setNumOfOpenElements(response.total_num)
            } else {
                setClosedJobPostings(response.jobs)
                setNumOfClosedElements(response.total_num)
            }
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const closeJob = async (job) => {
        const apiName = "CrewbiteAPI";
        const path = "close-job";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                job_id: job
            }
        }

        try {
            await API.post(apiName, path, myInit)
            setOpenJobPostings(prev => prev.filter(j => j.job_id!==job))
            setClosedJobPostings([])
            getJobPostings("closed",closedActive)
            setShowModalClose(false)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message}) 
        }
    }

    const deleteJob = async (job) => {
        const apiName = "CrewbiteAPI";
        const path = "delete-job";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                job_id: job
            }
        }

        try {
            await API.post(apiName, path, myInit)
            setClosedJobPostings(prev => prev.filter(j => j.job_id!==job))
            setShowModalDelete(false)
        } catch (error) {   
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
            <>
            <Helmet>
                <title>Job dashboard - Crewbite Inc.</title>
                <meta name="robots" content="noindex, follow"/>
            </Helmet>

            {route === 'authenticated' ? (
            <Container className="job-container">
                {showForm ? ( <PostJobForm closeForm={handleShowForm} /> ) : (
                
                !(openJobPostings || closedJobPostings) ? (<Loader/>) : (
                    <>
                    <Row>
                        <h1 className="title">JOB DASHBOARD</h1>
                        <button onClick={openJobForm} className="post-job-btn">Post a new Job</button>
                    </Row>
                    <Row>
                        <div className="job-postings">    
                            <JobTabs  
                                openJobPostings={openJobPostings} 
                                closedJobPostings={closedJobPostings} 
                                numOfOpenElements={numOfOpenElements}
                                numOfClosedElements={numOfClosedElements} 
                                handleShowModalClose={handleShowModalClose}
                                handleShowModalDelete={handleShowModalDelete}
                                handleProjectFilter={handleProjectFilter}
                                items_per_page={items_per_page}
                            />
                        </div>
                    </Row>
                    </>
                ))}
                </Container>
                ) : (<Loader />)}

                <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalPost}>
                    <Modal.Body>
                        <CloseIcon className="closeButton" onClick={()=>setShowModalPost(false)} />
                        <img alt="thumbs up icon" src={require("../../icons/thumbs-up.png")}/>
                        <h5 className="modal-heading">Posted!</h5>
                        <p>Your new job has been posted.</p>
                        <br/>
                        <p>Need help? <Link className="popover-link" to={"/about-us"} state={{contact: true}}>Contact us</Link></p>
                    </Modal.Body>
                </Modal> 

                <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalClose}>
                    <Modal.Body>
                        <CloseIcon className="closeButton" onClick={()=>setShowModalClose(false)} />
                        <img alt="close job icon" src={require("../../icons/eye.png")}/>
                        <h5 className="modal-heading">Close this Job Posting?</h5>
                        <p>You will no longer accept new applicants, but you are still able to see all applicants who applied for this job.</p>
                        <div className="buttons">
                            <button className="cancel" onClick={()=>setShowModalClose(false)}>Cancel</button>
                            <button className="close" onClick={()=>closeJob(jobToClose)}>Close</button>
                        </div>
                    </Modal.Body>
                </Modal>   

                <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalDelete}>
                    <Modal.Body>
                        <CloseIcon className="closeButton" onClick={()=>setShowModalDelete(false)} />
                        <img alt="delete job icon" src={require("../../icons/trash.png")}/>
                        <h5 className="modal-heading">Delete this Job Posting?</h5>
                        <p>By deleting this job posting, you will lose access to all applicants and posting data.</p>
                        <div className="buttons">
                            <button className="cancel" onClick={()=>setShowModalDelete(false)}>Cancel</button>
                            <button className="close" onClick={()=>deleteJob(jobToDelete)}>Delete</button>
                        </div>
                    </Modal.Body>
                </Modal> 

                <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalError}>
                    <Modal.Body>
                        <CloseIcon className="closeButton" onClick={()=>setShowModalError(false)} />
                        <div>{errorMessage}</div>
                    </Modal.Body>
                </Modal> 

                <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showApiMeter}>
                    <Modal.Body>
                        <CloseIcon className="closeButton" onClick={()=>setShowApiMeter(false)} />
                        <div>
                            <br/>
                            {showApiMeter && <p>You run out of quota usage for the following category: {showApiMeter === "active" ? "Active Jobs Limit." : "Total Jobs Limit"}</p>}
                            {notSubscribed ? (
                                <button className="upgrade" onClick={()=>navigate("/plan-selection")}>Go Premium</button> 
                            ) : (
                                <button className="upgrade" onClick={manageSubscription}>Upgrade Plan</button> 
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
    )
}

export default PostJob;