const aws_configuration = {
    Auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_9XIsbEMgu",
      userPoolWebClientId: "5sufml18hma80v3d9lliplmh3u",
      authenticationFlowType:'USER_SRP_AUTH',
      oauth: {
        domain: "auth.crewbite.com",
        scope: ["email", "phone", "aws.cognito.signin.user.admin", "openid", "profile"],
        responseType: "code"
      }
    },
    API: {
      endpoints: [
        {
          name: "CrewbiteAPI",
          endpoint: "https://api.crewbite.com/"
        }
      ]
    },
    aws_appsync_graphqlEndpoint: "https://te3dghfr5zbg7bmhnxl3l7eedy.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
}

export default aws_configuration;
