import CloseIcon from '@mui/icons-material/Close';

const FilterTags = (props) => {
    return (
        <>
            {props.position.length>0 && props.position.map(role => (
                <div className="filter-tag" key={role}>
                    <p>{role} </p>
                    <CloseIcon 
                        className="close-icon" 
                        onClick={()=>props.handleTags("position",role)}
                    /> 
                </div>
            ))}
                
            {props.city && 
                <div className="filter-tag">
                    <p>{props.city}</p> 
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("city")} /> 
                </div>}

            {props.union && 
                <div className="filter-tag">
                    <p>Union</p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("union")}/> 
                </div>}

            {props.covid && 
                <div className="filter-tag">
                    <p>Covid-19 Vac</p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("covid")}/> 
                </div>}

            {props.voluntary && 
                <div className="filter-tag">
                    <p>Volunteer Work </p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("voluntary")}/> 
                </div>}
            
            {props.lgbtqia2 && 
                <div className="filter-tag">
                    <p>LGBTQIA2+</p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("lgbtqia2")}/> 
                </div>}

            {props.bipoc && 
                <div className="filter-tag">
                    <p>BIPOC</p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("bipoc")}/> 
                </div>}

            {props.crewName && 
                <div className="filter-tag">
                    <p>{props.crewName} </p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("crew-name")}/> 
                </div>}
            
            {props.ethnicity?.length>0 && props.ethnicity.map(value => (
                <div className="filter-tag" key={value}>
                    <p>{
                        value === "black" ? 
                            "black / afro american" : 
                            value === "latino" ? 
                            "hispanic / latino" : 
                            value
                    } </p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("ethnicity",value)}/> 
                </div>
            ))}
            
            {props.gender &&
                <div className="filter-tag">
                    <p>{props.gender}</p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("gender")}/> 
                </div>
            }

            {props.dates && props.dates[1] !== null && 
                <div className="filter-tag">
                    <p>
                        {(new Date(props.dates[0]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ')} 
                        <span> - </span>
                        {(new Date(props.dates[1]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ')}
                    </p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("dates")}/> 
                </div>}    

            {props.remote && 
                <div className="filter-tag">
                    <p>Remote </p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("remote")}/> 
                </div>}   

            {props.union_tag && 
                <div className="filter-tag">
                    <p>{props.union_tag} </p>
                    <CloseIcon className="close-icon" onClick={()=>props.handleTags("union_tag")}/> 
                </div>}     
        </>
    )
}

export default FilterTags;