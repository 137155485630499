import { useState } from "react";
import { API, Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

import Cities from '../../data/cities.json';
import CalendarFilter from "../../components/Calendar/CalendarFilter";
import "./CreateProject.scss";

const CreateProject = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [title,setTitle] = useState("")
    const [description,setDescription] = useState("")
    const [location,setLocation] = useState("")
    const [producers,setProducers] = useState("")
    const [dates,setDates] = useState([null,null])


    const getDays = (start,end) => {
        setDates([start,end])
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    const createProject = async () => {
        const apiName = "CrewbiteAPI";
        const path = "create-project";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const body = {
            title
        }

        const date_format = dates[1] ? `${(new Date(dates[0]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")},${(new Date(dates[1]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")}` : undefined

        if (description) body.description = description
        if (producers) body.producers = producers
        if (location) body.location_addr = location
        if (date_format) body.production_date = date_format

        const myInit = { 
            headers:{
                Authorization: token
            },
            body
        }

        try {
            const response = await API.post(apiName, path, myInit)
            props.closeForm()
            navigate(`/project/${response}`)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <Modal className="success-modal create_project" aria-labelledby="contained-modal-title-vcenter" centered show={props.showForm}>
            <Modal.Body>   
                <CloseIcon className="closeButton" onClick={()=>props.closeForm()} />    
                <form className="create_project_form">
                    <input 
                        type="text" 
                        placeholder="Enter production title..." 
                        className="create_title" 
                        maxLength="100"
                        value={title} 
                        onChange={(e)=>setTitle(e.target.value)} 
                        required
                    />
                    <input 
                        type="text" 
                        placeholder="Enter producers..." 
                        className="create_producers" 
                        maxLength="200"
                        value={producers} 
                        onChange={(e)=>setProducers(e.target.value)}
                    />
                    <div className="create_location">
                        <Autocomplete
                            filterOptions={filterOptions}
                            disablePortal
                            id="create_location"
                            options={Cities}
                            value = {location}
                            fullWidth={true}
                            renderInput={(params) => <TextField {...params} placeholder="Enter location..."/>}
                            onChange={(e,value)=>setLocation(value)}
                        />
                    </div>
                    <div className="availability create_dates">
                        <label>Production Date</label>
                        <CalendarFilter className="calendar" getDays={getDays}/>
                    </div>
                    <textarea 
                        placeholder="Enter production description..." 
                        maxLength="3000"
                        value={description} 
                        onChange={(e)=>setDescription(e.target.value)}
                    />
                    <button className="create_button" type="button" onClick={createProject}>Create</button>
                </form> 
            </Modal.Body>
        </Modal> 
    )
}

export default CreateProject;