
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatMessage from "./ChatMessage";
import "./ProjectChat.scss";
import { API, Auth } from "aws-amplify";
import { readFile } from "../CropImage/CropImage";
import { createImage } from "../CropImage/canvasUtils";
import { AiOutlineSend } from "react-icons/ai";
import { IoWarningOutline } from "react-icons/io5";
import IOSSwitch from "../IOSSwitch/IOSSwitch";
import Tips from "../Tips/Tips";

const ProjectChat = (props) => {

    const newMessages = useSelector(state => state.newMessages)
    const dispatch = useDispatch();

    const [messageInput,setMessageInput] = useState("")
    const [messages,setMessages] = useState([])
    const [messagesLimit,setMessagesLimit] = useState("")
    const [initialFetch,setInitialFetch] = useState(false)
    const [seePrevMessages,setSeePrevMessages] = useState(0)
    const [oldestMessage,setOldestMessage] = useState(undefined)
    const [shouldScroll,setShouldScroll] = useState(true)
    const [hasScroll,setHasScroll] = useState(false)
    const [loadMore,setLoadMore] = useState("")
    const [muted,setMuted] = useState()
    const [disableSwitch,setDisableSwitch] = useState(false)
    const [sending,setSending] = useState(false)
    const currentTime = Math.round(Date.now() / 1000) + 10 

    useEffect(()=> {
        setMessages([])
        setMessagesLimit("")
        setMessageInput("")
        setSeePrevMessages(0)
        setOldestMessage(undefined)
        setShouldScroll(true)
        setHasScroll(false)
        setLoadMore("")
        fetchMessages(true)
        if (newMessages?.includes(props.channel.channel_id)) {
            dispatch({type:'READ_MESSAGE', payload:props.channel.channel_id});    
        }
        if (props.channel.is_mute !== undefined) {
            setMuted(JSON.parse(props.channel.is_mute))
        } else {
            fetchChannels()
        }
    },[props.channel.channel_id])

    useEffect(()=>{
        if (messagesLimit) {
            const messages_div = document.getElementById("messages")
            messages_div.scrollTop = messages_div.scrollHeight;
            if (messagesLimit === "wait") {
                setTimeout(()=>{
                    setMessagesLimit("")
                },5000)
            }
        }
    },[messagesLimit])

    useEffect(()=>{
        if (newMessages) {
            const [ last_message ] = newMessages.slice(-1)
            if (initialFetch && last_message === props.channel.channel_id) {
                fetchMessages()
                dispatch({type:'READ_MESSAGE', payload:props.channel.channel_id});
            }
        }
    },[newMessages])

    useEffect(()=>{
        if (messageInput.length >= "2000") {
            setMessagesLimit("char_limit")
        } else if (messagesLimit === "char_limit") {
            setMessagesLimit("")
        }
    },[messageInput])

    useEffect(()=> {
        const last_messages = [...document.getElementsByClassName("message")]
        if (last_messages.length > 0) { 
            setOldestMessage(last_messages[0])
            if (shouldScroll) {
                last_messages.pop().scrollIntoView({block: "end"})
            } else {
                oldestMessage?.scrollIntoView({block: "start"})
                setShouldScroll(true)
            }

            const messages_container = document.getElementsByClassName('messages')[0]
            const container_has_scroll = messages_container.scrollHeight > messages_container.clientHeight + 10
            if (!loadMore) {
                if (container_has_scroll) setHasScroll(true)
                else fetchMessages(false,seePrevMessages)
            }
        }
    },[messages])

    //handlers
    const handlePrevMessages = async (e) => {
        if (e.target.scrollTop !== 0) return
        if (hasScroll) {
            setLoadMore("loading")
            await fetchMessages(false,seePrevMessages)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && e.repeat) {
            e.preventDefault()
            return
        }
        if (messageInput) { 
            if (e.key === "Enter" && !e.shiftKey && !sending) {
                e.preventDefault()
                sendMessage(1) 
            } 
        } else {
            if (e.key === "Enter") {
                e.preventDefault()
            }
        }
    }

    const handleMute = (channel_id) => {
        setDisableSwitch(true)

        const is_mute = muted === 1 ? 0 : 1
        updateChannelPreferences(channel_id,is_mute)
    }

    // const onFileChange = async (e) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         const file = e.target.files[0]
    //         const imageDataUrl = await readFile(file)
    //         const image = await createImage(imageDataUrl)
    //         const canvas = document.createElement("canvas")
    //         const ctx = canvas.getContext("2d")
    //         canvas.width = image.width;          
    //         canvas.height = image.height;
    //         ctx.drawImage(image, 0, 0);
    //         const jpeg = canvas.toDataURL("image/jpeg");
    //         const base64Url = jpeg.replace("data:image/jpeg;base64,","")
    //         console.log(base64Url)
    //         sendMessage(2,base64Url)
    //     }
    //   }

    //Api calls
    const sendMessage = async (type,image=null) => {
        setSending(true)

        const apiName = "CrewbiteAPI"
        const path = "send-message"
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                channel_id: props.channel.channel_id,  
                message_type: type,                                           
                message_body: type === 1 ? messageInput : image  
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setSending(false)
            setMessageInput("")
            setSeePrevMessages(0)
            setLoadMore("")
            fetchMessages(true,0)
        } catch (error) { 
            setSending(false)
            if (error.response.status === 429) {
                setMessagesLimit("wait")
            } else {
                dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            }
        }
    }

    const fetchMessages = async (initial = false,from = 0,last = false) => {
        const apiName = "CrewbiteAPI"
        const path = "fetch-messages-by-channel"
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                channel_id: props.channel.channel_id,  
            }
        }
        if (from > 0) {
            myInit.queryStringParameters["time_cutline_from"] = !last ? from - 86400*14 : from - 86400*30*6
            myInit.queryStringParameters["time_cutline_to"] = from
        } else {
            myInit.queryStringParameters["time_cutline_from"] = currentTime - 86400*14
        }
            

        try {
            const response = await API.get(apiName, path, myInit)
            if (from > 0) {
                if (response.length === 0) {
                    if (!last) fetchMessages(false,from ,true)
                    else {
                        setHasScroll(false)
                        setLoadMore("none_found")
                    }
                } else {
                    if (!initial) setShouldScroll(false)
                    setMessages(prev=> [...response,...prev])
                    setSeePrevMessages(prev => prev - 86400*14)
                    if (last) {
                        setHasScroll(false)
                        setLoadMore("none_found")
                    } else {
                        setLoadMore("")
                    }
                } 
            } else if (initial) {
                if (response.length === 0) {
                    if (props.channel.last_msg_tstamp) {
                        const last_message = JSON.parse(props.channel.last_msg_tstamp)+10
                        setSeePrevMessages(last_message)
                        fetchMessages(true,last_message)
                    } else {
                        setSeePrevMessages(currentTime - 86400*14)
                        fetchMessages(true,currentTime - 86400*14)
                    }
                } else {
                    setSeePrevMessages(currentTime - 86400*14)
                    setMessages(response)
                    setInitialFetch(true)        
                }
            } else {
                setMessages(response)
            }
        } catch (error) { 
            if (error.response.status !== 429) {
                dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            }
        }
    }

    const updateChannelPreferences = async (channel_id,is_mute) => {
        const apiName = "CrewbiteAPI"
        const path = "update-channel-notification-preference"
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body:{
                channel_id,
                is_mute
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setMuted(is_mute)
            if (props.channel.is_mute !== undefined)  props.handleIsMute(props.channel.channel_id,is_mute)
            setDisableSwitch(false)
        } catch (error) { 
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            setDisableSwitch(false)
        }
    }

    const fetchChannels = async () => {
        const apiName = "CrewbiteAPI"
        const path = "fetch-message-channels"
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            const currentChannel = response.find(channel => channel.channel_id === props.channel.channel_id)
            setMuted(JSON.parse(currentChannel.is_mute))
        } catch (error) { 
            if (error.response.status !== 429) {
                dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            }
        }
    }

    return (
        <>
            <div className="chat_header">
                {props.channel.channel_type && (
                    <a 
                        className="channel_target project_link" 
                        href={
                            props.channel.channel_type === "1" ? (
                                `/project/${props.channel.channel_target_id}`
                            ) : (
                                `/profile/${props.channel.channel_target_id}`
                            )
                        }
                    >
                        {props.channel.channel_target_name}
                    </a>
                )}
                <div className="mute_channel">
                    <label className="mute_label label-with-tip" htmlFor="mute_switch">
                        <Tips content="When muted, messages within this channel will not trigger real-time notifications nor emails, the latest message can still be retrieved with a page refresh."/>
                        Mute
                    </label>
                    <IOSSwitch                                              
                        name="mute_switch" 
                        className="mute_switch" 
                        checked={muted === 1} 
                        onClick={()=>handleMute(props.channel.channel_id)}
                        disabled={disableSwitch}
                    />
                </div>
            </div>
            <div id="messages" className="messages" onScroll={handlePrevMessages}>
                <p className="load_more">{loadMore === "loading" ? "Loading more messages..." : loadMore === "none_found" ? "There are no more messages" : ""}</p>
                {messages.map(message => (
                    <ChatMessage key={message.msg_id || Date.now()} message={message}/>
                ))}
                {messagesLimit && 
                    <p className="error_message">
                        <IoWarningOutline className="warning-icon"/>
                        {messagesLimit && (messagesLimit === "wait" ? 
                            "You are sending too many messages. Please wait for 30 seconds before sending your next message." 
                            : "You have reached the characters limit of 2000"
                        )}
                    </p>
                }
            </div>
            <div className="message_input">
                {/* <img alt="plus icon" src={require("../../../icons/plus.png")}/>
                <input type="file" onChange={onFileChange} accept="image/*" /> */}
                <textarea 
                    className="message_input_text" 
                    type="text" 
                    placeholder="Type your message here"
                    value={messageInput}
                    onChange={(e)=>setMessageInput(e.target.value)}  
                    maxLength="2000"
                    onKeyDown={handleKeyDown}  
                    disabled={messagesLimit === "wait"}
                />
                <AiOutlineSend className={`send_icon ${!messageInput && "disabled"}`} onClick={()=> messageInput && !sending && sendMessage(1)}/>
            </div>
        </>
    )
}
export default ProjectChat