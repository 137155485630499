import { useState, useEffect } from "react"
import { API, Auth } from "aws-amplify"
import { useDispatch } from "react-redux"
import IOSSwitch from "../IOSSwitch/IOSSwitch"

import "./EmailPreferences.scss"

const EmailPreferences = () => {
    const dispatch = useDispatch();

    const [enableProgrammatic, setEnableProgrammatic] = useState(false)
    const [enablePromotional, setEnablePromotional] = useState(false)
    const [disableSwitch,setDisableSwitch] = useState(false)

    useEffect(() => {
        getEmailPreference()
    },[])
    
    const handleEnable = (type) => {
        setDisableSwitch(true)
        if (type ==="programmatic") { 
            updateEmailPreference("programmatic").then(()=> {
                setEnableProgrammatic(prev=>!prev)
            })
        } else {
            updateEmailPreference("promotional").then(()=> {
                setEnablePromotional(prev=>!prev) 
            }) 
        } 
    }

    //Api calls
    const getEmailPreference = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-email-preference";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            setEnableProgrammatic(JSON.parse(response.programmatic))
            setEnablePromotional(JSON.parse(response.promotional))
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }
    const updateEmailPreference = async (type) => {
        const apiName = "CrewbiteAPI";
        const path = "update-email-preference";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body:{
                programmatic: type === "programmatic" ? !enableProgrammatic : enableProgrammatic,
                promotional: type === "promotional" ? !enablePromotional : enablePromotional
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setDisableSwitch(false)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            setDisableSwitch(false)
        }
    }

    return (
        <div className="email_preferences_container">
            <h2 className="subtitle">Email Preferences</h2>
            <div className="enable_emails">
                <IOSSwitch 
                        name="mfa-switch" 
                        className="mfa-switch" 
                        checked={enableProgrammatic} 
                        onClick={()=>handleEnable("programmatic")}
                        disabled={disableSwitch}
                />
                <label className="mfa-label" htmlFor="mfa-switch">
                    <p className="enable">Email Notifications</p>
                    <p className="explanation">When turned on, you will receive <b>Job Invitations</b> and <b>Job Application</b> updates</p>
                </label>
            </div>
            <div className="enable_emails">
                <IOSSwitch 
                        name="mfa-switch" 
                        className="mfa-switch" 
                        checked={enablePromotional} 
                        onClick={()=>handleEnable("promotional")}
                        disabled={disableSwitch}
                />
                <label className="mfa-label" htmlFor="mfa-switch">
                    <p className="enable">Promotional Emails</p>
                    <p className="explanation">When turned on, you will receive promotional emails</p>
                </label>
            </div>
        </div>
    )
}

export default EmailPreferences