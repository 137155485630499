import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import {Authenticator, useAuthenticator, View, CheckboxField, Heading, Text, Button} from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import { Auth } from "aws-amplify";
import project_configuration from "../../configurations/project_configuration";

import '@aws-amplify/ui-react/styles.css';
import './SignIn.scss';

import SignUpForm from "./SignUpForm";



const SignIn = (props) => {

  const { route } = useAuthenticator((context) => [context.route]);

  const location = useLocation();
  const navigate = useNavigate();

  const formFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: 'Username or Email address'
      },
      password: {
        labelHidden: true,
        placeholder: 'Password'
      }
    },
    resetPassword: {
      username: {
        labelHidden: true,
        placeholder: 'Enter your username or email address'
      }
    }
  }

  const [email,setEmail] = useState()
  const [from,setFrom] = useState(location.state?.from?.pathname + location.state?.from?.search || '/');

  useEffect(() => {
    if (route === 'authenticated') {
      props.handleSignIn();
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  useEffect(()=>{
    if (route === 'confirmSignUp' && !location.state?.from) {
      setFrom("/create-profile")
    }
  },[route,location.state])

  return (
    <>
    <Helmet>
      <title>Sign In - Crewbite Inc.</title>
      <link rel="canonical" href={`https://www.${project_configuration.main_url}/signin`}/>
      <meta
          name="description"
          content="Crewbite Social Networking Services. Sign in now to connect with film productions and crew."
      />
    </Helmet>

    <View className="auth-wrapper">
      <Authenticator
        initialState={location.state?.page || "signIn"}
        signUpAttributes={['email']}
        formFields={formFields}
        components={{
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();
              return (
                <>
                  <SignUpForm />

                  <div className="terms-checkbox">
                    <CheckboxField
                      errorMessage={"Terms & Conditions acceptance is required"}
                      hasError={!!validationErrors.acknowledgement}
                      name="acknowledgement"
                      value="yes"
                    />
                    <span className="checkbox-label">I agree with Crewbite's <Link className="link-to-terms" to={"/terms-and-conditions"}>Terms & Conditions</Link></span>
                  </div>
                </>
              );
            }
          },
          ConfirmSignUp: {
            Header() {
              return (
                <>
                  <Heading
                    level={3}
                  >
                    Verification Code Sent
                  </Heading>
                  {email ? (
                    <Text>
                      To log in, enter the code we emailed to <b>{email}</b> <br/><br/>
                      Note: Occationally, the Email could be filtered into 'All Mail', 'Spam' or other places if it is not presented in the Inbox.
                    </Text>
                  ) : (
                    <Text>
                      Note: Occationally, the Email could be filtered into 'All Mail', 'Spam' or other places if it is not presented in the Inbox.
                    </Text>
                  )}
                </>
              );
            },
            Footer() {
              const { toSignIn } = useAuthenticator();

              return (
                <View textAlign="center">
                  <Button
                    fontWeight="normal"
                    onClick={toSignIn}
                    size="small"
                    variation="link"
                  >
                    Back to Sign In
                  </Button>
                </View>
              );
            }
          },
          ConfirmResetPassword: {
            Footer() {
              const { toSignIn } = useAuthenticator();

              return (
                <View textAlign="center">
                  <Button
                    fontWeight="normal"
                    onClick={toSignIn}
                    size="small"
                    variation="link"
                  >
                    Back to Sign In
                  </Button>
                </View>
              );
            }
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
              return {
                acknowledgement: 'You must agree to the Terms & Conditions',
              };
            }
          },
          async handleSignUp(formData) {
            let { username, password, attributes } = formData;
            setEmail(attributes.email)
            return Auth.signUp({
              username,
              password,
              attributes,
              autoSignIn: {
                enabled: true,
              }
            });
          },
        }}
      />
    </View>
    </>
  );
}

export default SignIn;
