import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {API, Auth} from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
// import { useAuthenticator } from '@aws-amplify/ui-react';
import { useCookies } from 'react-cookie';
import { Nav, Spinner } from "react-bootstrap";

import { RiArrowDropDownLine as DownIcon, RiArrowDropUpLine as UpIcon }  from "react-icons/ri";
import { BsCameraReels } from "react-icons/bs"
import { GrDocumentText as Document } from "react-icons/gr";
import { FaRegStar } from "react-icons/fa";
import { channel } from "../../App";

const ProfileNav = (props) => {
    
    // const { signOut } = useAuthenticator((context) => [context.signOut]);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const user_id = useSelector(state => state.id)
    const hasProfile = useSelector(state => state.hasProfile)
    const subscription =  useSelector(state => state.isPremium)

    const [showDetails,setShowDetails] = useState(false)
    const [metrics,setMetrics] = useState()
    const [ cookies, setCookie, removeCookie ] = useCookies(['union_tag']);

    const closeMenu = () => {
        dispatch({type:"OPEN_MENU", payload:false})
    }
    const logOut = async () => {
        try {
            await Auth.signOut();    
            channel.postMessage("signing out");     
            dispatch({type:"RESET_STATE"})
            removeCookie("union_tag")
            navigate("/") 
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error})
        }
    }

    const handlePlanDetails = () => {
        if (showDetails) {
            setShowDetails(false)
        } else {
            setShowDetails(true)
            getMetrics()
        }
    }

    //Api calls
    const manageSubscription = async () => {
        const apiName = "CrewbiteAPI";
        const path = "customer_portal";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        
        const myInit = { 
            headers:{
                Authorization: token
            }
        }
        try {
            const response = await API.post(apiName, path, myInit)
            window.location.href = response.session_url
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const getMetrics = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-api-meter";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    
        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                paths: "create-job-posting,send-invite-message,get-full-profile-by-id,create-project"
            }
        };
        try {
          const response = await API.get(apiName, path, myInit)
          setMetrics(response)
        } catch (error) {
            setShowDetails(false)
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
      }

    return (
        <>
            {props.mobile && <hr />}
            <p className="popover-heading">User name: {user_id}</p>

            {hasProfile ? (
                <Nav.Link 
                    eventkey="profile"
                    as={Link} 
                    className="my-profile popover-link" 
                    onClick={closeMenu} 
                    to={`/profile/${user_id}`}
                > 
                    {props.mobile ? (
                        <img alt="profile navbar icon" src={require("../../icons/profile.png")} />
                    ) : (
                        <img 
                            alt="navbar user profile" 
                            className="profile-pic" 
                            src={`https://crewbite-user-profile-pictures.s3.amazonaws.com/${user_id}-icon.jpg?${new Date().getTime()}`}
                        /> 
                    )}
                    My Profile
                </Nav.Link > 
            ) : ( 
                <Nav.Link 
                    eventkey="create"
                    as={Link}
                    className="popover-link" 
                    to={`/create-profile`}
                    onClick={closeMenu}
                >
                    <img 
                        alt="create job icon" 
                        src={require("../../icons/person.png")}
                    /> 
                    Create Profile
                </Nav.Link > 
            )}

            <Nav.Link 
                eventkey="projects"
                as={Link}
                className="popover-link" 
                to={`/project-dashboard`}
                onClick={closeMenu}
            >
                <BsCameraReels className="icon"/> 
                My Productions
            </Nav.Link >

            <Nav.Link 
                eventkey="applications"
                as={Link}
                className="popover-link" 
                to={`/my-job-applications`}
                onClick={closeMenu}
            >
                <Document className="icon"/> 
                My Applications
            </Nav.Link >

            <Nav.Link 
                eventkey="postings"
                as={Link}
                className="popover-link" 
                to={`/post-a-job`}
                onClick={closeMenu}
            >
                <img alt="briefcase icon" src={require("../../icons/briefcase.png")}/> 
                My Job Postings
            </Nav.Link >

            <Nav.Link 
                eventkey="contact"
                as={Link} 
                className="popover-link" 
                to={"/about-us#contact"} 
                onClick={closeMenu}
            >
                <img alt="telephone icon" src={require("../../icons/telephone.png")}/> 
                Contact Us
            </Nav.Link >

            <Nav.Link 
                eventkey="settings"
                as={Link}
                className="popover-link" 
                to={`/settings`}
                onClick={closeMenu}
            >
                <img alt="settings icon" src={require("../../icons/gear.png")}/> 
                Settings
            </Nav.Link >

            {!subscription.subscription_status ? (
                <Nav.Link 
                    eventkey="premium"
                    as={Link} 
                    className="popover-link" 
                    to={"/plan-selection"}
                    onClick={closeMenu}
                >
                    <FaRegStar className="icon"/>
                    Go Premium
                </Nav.Link>
            ) : (
                <>            
                {subscription.subscription_cancel_at_period_end ? (
                    <>
                    <Nav.Link 
                        eventkey="premium"
                        as={Link} 
                        className="popover-link" 
                        to={"/plan-selection"}
                        onClick={closeMenu}
                    >
                        <FaRegStar className="icon"/>
                        Go Premium
                    </Nav.Link>
                    <div className="reminder">
                        <p className="type">Expiring on</p>
                        <p className="value">{(new Date(subscription.expiration*1000)).toLocaleString()}</p>
                    </div>
                    </>
                ) : (
                    <Nav.Link 
                        eventkey="manage"
                        className="popover-link" 
                        onClick={()=> {
                            manageSubscription()
                            closeMenu()
                        }}
                    >
                        <FaRegStar className="icon"/> 
                        Manage Subscription
                    </Nav.Link >
                )}
                </>
            )}

            <div className="reminder">
                <p className="type current-plan">Current plan</p> 
                <p className="value current-plan">
                    {subscription.type === "established_pros" ? ( 
                        "Established Pros" 
                    ) : ( subscription.type === "busy_bees" ) ? (
                        "Busy Bees" 
                    ) : (  subscription.type === "indie_surfers" ) ? (
                        "Indie Surfers" 
                    ) : (
                        "Basic"
                    )
                    }
                </p>
            </div>
           
           <div className="reminder" onClick={()=>handlePlanDetails()}>
                <p className="see-details">See plan details {!showDetails ? <DownIcon className="icon"/>:<UpIcon className="icon"/>}</p>
            </div>
           {showDetails && (
                !metrics ? <Spinner className="small-loader" animation="border" /> : (
                <>
                        <div className="reminder">
                            <p className="type">Profile views</p>
                            <p className="value">{metrics.profile_views>10000 ? "Unlimited" : metrics.profile_views + " left"}</p>
                        </div>

                        <div className="reminder">
                            <p className="type">Invite messages</p>
                            <p className="value">{metrics.invite_messages>10000 ? "Unlimited" : metrics.invite_messages + " left"}</p>
                        </div>

                        <div className="reminder">
                            <p className="type">Active Job postings</p>
                            <p className="value">{metrics.active_jobs} left</p>
                        </div>

                        <div className="reminder">
                            <p className="type">Total Job postings</p>
                            <p className="value">{metrics.total_jobs>10000 ? "Unlimited" : metrics.total_jobs + " left"}</p>
                        </div>

                        <div className="reminder">
                            <p className="type">Productions owned</p>
                            <p className="value">{metrics.owned_productions>10000 ? "Unlimited" : metrics.owned_productions + " left"}</p>
                        </div>
                    </>
                )
            )}

            <p 
                className="popover-link signout" 
                onClick={() => {
                    logOut()
                    closeMenu()
                }}
            >
                <img alt="signout icon" src={require("../../icons/sign-out.png")}/> 
                Sign Out
            </p> 
        </>
    )
}

export default ProfileNav;