import { useEffect, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import "../CreateProfile/GenderEthics.scss";
import "./EditGenderEthics.scss";

const EditGenderEthics = (props) => {
    const [gender,setGender] = useState(...props.genderEthics?.gender)
    const [lgbtqia2,setLgbtqia2] = useState(props.genderEthics?.ethics.some(val=>val==="lgbtqia2"))
    const [bipoc,setBipoc] = useState(props.genderEthics?.ethics.some(val=>val==="bipoc"))
    const [ethnicity,setEthnicity] = useState({
        "indigenous": false,
        "asian":false,
        "black": false,
        "latino": false,
        "islander": false,
        "white": false,
        "prefer_not_to_say": true,
    })

    useEffect(()=>{
        const obj = {...ethnicity}
        props.genderEthics?.ethnicity.forEach(val=> {
            if (val) {
                obj[val] = true
                obj.prefer_not_to_say = false
            }
        })
        setEthnicity(obj)
    },[])

    useEffect(()=>{
        handleChange()
    },[gender,lgbtqia2,bipoc,ethnicity])

    const handleChange = () => {

        const gender_data = []
        const ethics_data = []
        const ethnicity_data = []
  
        if (gender) gender_data.push(gender)
        if (lgbtqia2) ethics_data.push("lgbtqia2")
        if (bipoc) ethics_data.push("bipoc")
        if (ethnicity.indigenous) ethnicity_data.push("indigenous")
        if (ethnicity.asian) ethnicity_data.push("asian")
        if (ethnicity.black) ethnicity_data.push("black")
        if (ethnicity.latino) ethnicity_data.push("latino")
        if (ethnicity.islander) ethnicity_data.push("islander")
        if (ethnicity.white) ethnicity_data.push("white")

        props.handleGenderEthics(gender_data,ethics_data,ethnicity_data)
    }

    const handleEthnicity = (e, type) => {
        setEthnicity(prev => {
            let newEthnicity = {...prev}
            if (type==="prefer_not_to_say") {
                newEthnicity = {
                    "indigenous": false,
                    "asian":false,
                    "black": false,
                    "latino": false,
                    "islander": false,
                    "white": false,
                    "prefer_not_to_say": true,
                }
            } else {
                if (newEthnicity["prefer_not_to_say"]) {
                    newEthnicity["prefer_not_to_say"] = false
                }
                newEthnicity[type] = e.target.checked
            }
            return newEthnicity
        })
    }

    return (
        <div className="ethics-form">
            <div className="gender-selection">
                <h5 className="header" id="gender">You are</h5>
                <ArrowDropDownIcon className="gender-arrow"/>
                <select 
                    value={gender}
                    onChange={(e)=>setGender(e.target.value)} 
                    placeholder="Select your gender"
                    name="gender" 
                    id="gender-select" 
                >
                    <option value="">Prefer not to say</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="nonbinary">Non-binary</option>
                </select> 
            </div>

            <h5 className="header" id="lgbtqia2">Do you identify yourself as LGBTQIA2+?</h5> 
            <div className="lgbtqia2">
                <div className="lgbtqia2-option">
                    <input type="radio" name="lgbtqia2" id="lgbtqia2-not-to-say" checked={!lgbtqia2} onChange={()=>setLgbtqia2(false)}/>
                    <label htmlFor="lgbtqia2-not-to-say">No / Prefer not to say</label> 
                </div>

                <div className="lgbtqia2-option">
                    <input type="radio" name="lgbtqia2" id="lgbtqia2-yes" checked={lgbtqia2} onChange={()=>setLgbtqia2(true)} />
                    <label htmlFor="lgbtqia2-yes">Yes</label> 
                </div>
            </div>

            <h5 className="header" id="bipoc">Do you identify yourself as BIPOC (Black, Indigenous, and people of colour)?</h5> 
            <div className="lgbtqia2">
                <div className="lgbtqia2-option">
                    <input type="radio" name="bipoc" id="bipoc-not-to-say" checked={!bipoc} onChange={()=>setBipoc(false)}/>
                    <label htmlFor="bipoc-not-to-say">No / Prefer not to say</label> 
                </div>

                <div className="lgbtqia2-option">
                    <input type="radio" name="bipoc" id="bipoc-yes" checked={bipoc} onChange={()=>setBipoc(true)} />
                    <label htmlFor="bipoc-yes">Yes</label> 
                </div>
            </div>

            <div className="ethnicity">
                <h5 id="ethnicity" className="header label-with-tip">Which ethnicity do you identifying with?</h5>   
                <p className="select-all">Please select all that apply</p>

                <div className="ethnicity-option">
                    <input type="checkbox" name="ethnicity" id="ethnicity-not-to-say" checked={ethnicity.prefer_not_to_say} onChange={(e)=>handleEthnicity(e,"prefer_not_to_say")}/>
                    <label htmlFor="ethnicity-not-to-say">Prefer not to say</label> 
                </div>

                <div className="ethnicity-option">
                    <input type="checkbox" name="ethnicity" id="indigenous" checked={ethnicity.indigenous} onChange={(e)=>handleEthnicity(e,"indigenous")}/>
                    <label htmlFor="indigenous">American Indian or Alaska Native 
                        <span>A person having origins in any of the original peoples of North and South America (including Central America), and who maintains tribal affiliation or community attachment.</span>
                    </label>
                </div>

                <div className="ethnicity-option">
                    <input type="checkbox" name="ethnicity" id="asian" checked={ethnicity.asian} onChange={(e)=>handleEthnicity(e,"asian")}/>
                    <label htmlFor="asian">Asian
                        <span>A person having origins in any of the original peoples of the Far East, Southeast Asia, or the Indian subcontinent including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand, and Vietnam.</span>
                    </label>
                </div>

                <div className="ethnicity-option">
                    <input type="checkbox" name="ethnicity" id="black" checked={ethnicity.black} onChange={(e)=>handleEthnicity(e,"black")}/>
                    <label htmlFor="black"> Black or African American
                        <span>A person having origins in any of the black racial groups of Africa. Terms such as “Haitian” or “Negro” can be used in addition to "Black or African American".</span>
                    </label>
                </div>

                <div className="ethnicity-option">
                    <input type="checkbox" name="ethnicity" id="latino" checked={ethnicity.latino} onChange={(e)=>handleEthnicity(e,"latino")}/>
                    <label htmlFor="latino"> Hispanic or Latino
                        <span>A person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin, regardless of race. The term, "Spanish origin", can be used in addition to "Hispanic or Latino".</span>
                    </label>
                </div>

                <div className="ethnicity-option">
                    <input type="checkbox" name="ethnicity" id="islander" checked={ethnicity.islander} onChange={(e)=>handleEthnicity(e,"islander")}/>
                    <label htmlFor="islander"> Native Hawaiian or Other Pacific Islander
                        <span>A person having origins in any of the original peoples of Hawaii, Guam, Samoa, or other Pacific Islands.</span>
                    </label>
                </div>

                <div className="ethnicity-option">
                    <input type="checkbox" name="ethnicity" id="white" checked={ethnicity.white} onChange={(e)=>handleEthnicity(e,"white")}/>
                    <label htmlFor="white"> White
                        <span>A person having origins in any of the original peoples of Europe, the Middle East, or North Africa.</span>
                    </label>
                </div>
            </div>
        </div>
    )
}
export default EditGenderEthics;