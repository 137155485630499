import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Row, Container, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import ContactInfo from "../../components/ContactInfo/ContactInfo";
import Loader from "../../components/Loader/Loader";

import "./Settings.scss";
import EmailPreferences from "../../components/EmailPreferences/EmailPreferences";

const Settings = () => {

    const [tab,setTab] = useState("security")

    const { route } = useAuthenticator((context) => [context.route]);

    return (
        <>
            <Helmet>
                <title>Settings - Crewbite Inc.</title>
            </Helmet>
            {route === 'authenticated' ? (
            <Container className="settings-container">
                <Row>
                    <h1 className="title">Account Settings</h1>
                    <h5 className="heading">Manage and personalize your Crewbite account</h5>

                    <Container fluid className="settings-box">
                        <Row className="setting-row">
                            <Col md={3} sm={12} className="settings-options">
                                <p className={(tab === "security") ? "active" : undefined} onClick={()=>setTab("security")}>Privacy and Security</p>
                                <p className={(tab === "notifications") ? "active" : undefined} onClick={()=>setTab("notifications")}>Notifications</p>
                            </Col>
                            <Col md={9} sm={12} className="settings-content">
                                {tab === "security" && <ContactInfo />}
                                {tab === "notifications" && <EmailPreferences />}
                            </Col>  
                        </Row>
                    </Container> 
                </Row>
            </Container> 
            ) : (<Loader/>)}
        </>
    )
}

export default Settings;