export const reSize = (img, urlSize) => {
    const newWidth =img.width*(800/urlSize)**(1/2)
    const newHeight = img.height*(800/urlSize)**(1/2)
    const canvas2 = document.createElement("canvas");
    canvas2.width = newWidth;
    canvas2.height = newHeight;
    const ctx2 = canvas2.getContext("2d");
    ctx2.drawImage(img, 0, 0, newWidth, newHeight)
    let base64URL = canvas2.toDataURL('image/jpeg')
    let newUrlSize = base64URL.length / 1000
    if (newUrlSize>800) base64URL = canvas2.toDataURL('image/jpeg',((800/newUrlSize)*10 | 0)/10)
    return base64URL
}