import { useEffect, useState } from "react"
import { Pagination } from "react-bootstrap"


const PaginationItems = (props) => {

    const [paginationStart,setPaginationStart] = useState(2)
    const [paginationEnd,setPaginationEnd] = useState(7)
    const [goNext,setGoNext] = useState(undefined)
    const [goBack,setGoBack] = useState(undefined)
    const [items,setItems] = useState(undefined)

    useEffect(()=>{
        if (props.active === 1) {
            setPaginationStart(2)
            setPaginationEnd(7)
        }
    },[props.active])

    useEffect(()=>{
        if (goNext) {
            const newItems = []
            for (let i=paginationStart; i<goNext;i++) {
                newItems.push(i)
            }
            setItems(newItems)
        }
    },[goNext])

    useEffect(()=> {
        if (props.numOfElements) {
            setGoNext(Math.min(Math.ceil(props.numOfElements/props.items_per_page),paginationEnd))
            setGoBack(Math.max(paginationStart,7))
        }
    },[props.numOfElements,paginationStart,paginationEnd])

    return (
        <Pagination>
            <Pagination.Item 
                key={1} 
                active={props.active===1} 
                onClick={()=>{
                    props.status ? (
                        props.changePage(1,props.status)
                    ) : (
                        props.changePage(1)
                    )
                }}
                >
                    {1}
                </Pagination.Item>
            {Math.ceil(props.numOfElements/props.items_per_page) > 7 &&
                <Pagination.Ellipsis key="prev-ellipsis" onClick={()=>{
                    setPaginationEnd(goBack)
                    setPaginationStart(goBack-5)
                    props.status ? props.changePage(goBack-5,props.status) : props.changePage(goBack-5)
                }}/>
            }
            {items?.map(page=>(
                <Pagination.Item key={page} active={page === props.active} onClick={()=>props.changePage(page)}>{page}</Pagination.Item>
            ))}
            {Math.ceil(props.numOfElements/props.items_per_page) > 7 &&
                <Pagination.Ellipsis key="next-ellipsis" onClick={()=>{
                    setPaginationStart(goNext)
                    setPaginationEnd(goNext+5)
                    props.status ? props.changePage(goNext,props.status) : props.changePage(goNext)
                }}/>
            }
            <Pagination.Item 
                key={Math.ceil(props.numOfElements/props.items_per_page)} 
                active={props.active===Math.ceil(props.numOfElements/props.items_per_page)} 
                onClick={()=>{
                    props.status ? (
                        props.changePage(Math.ceil(props.numOfElements/props.items_per_page),props.status)
                     ) : (
                        props.changePage(Math.ceil(props.numOfElements/props.items_per_page))
                     )
                }}
            >
                {Math.ceil(props.numOfElements/props.items_per_page)}
            </Pagination.Item>
        </Pagination>
    )
}

export default PaginationItems