
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import { Helmet } from "react-helmet-async";
import parse from 'html-react-parser';
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { IoIosArrowBack } from 'react-icons/io';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import CalendarFilter from "../../components/Calendar/CalendarFilter";
import PrivacySetting from "./PrivacySetting";
import Loader from "../../components/Loader/Loader";
import ProjectMembers from "./ProjectMembers";
import ProjectChat from "../../components/ProjectChat/ProjectChat";
import InviteMember from "./InviteMember";
import CropImage from "../../components/CropImage/CropImage";
import Cities from '../../data/cities.json';

import "./Project.scss";
import { Autocomplete, ClickAwayListener, createFilterOptions, Popper, TextField } from "@mui/material";

const Project = () => {
    const dispatch = useDispatch();
    const { route } = useAuthenticator((context) => [context.route]);
    const myId = useSelector((state) => state.id)

    const { id } = useParams()
    const navigate = useNavigate()

    const [showModalError,setShowModalError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [project,setProject] = useState(undefined)
    const [members,setMembers] = useState(undefined)
    const [edit,setEdit] = useState(false)
    const [description,setDescription] = useState("")
    const [partialDescription,setPartialDescription] = useState(true)
    const [showInviteModal,setShowInviteModal] = useState(false)
    const [projectModalShow,setProjectModalShow] = useState(false);
    const [projectPic,setProjectPic] = useState()
    const [showModalDelete,setShowModalDelete] = useState(false)
    const [privacySetting,setPrivacySetting] = useState(false)
    const [dates,setDates] = useState([null,null])
    const [prevDates,setPrevDates] = useState([null,null])
    const [producers,setProducers] = useState("")
    const [location,setLocation] = useState("")
    const [editAnchorEl,setEditAnchorEl] = useState(null)
    const [numOfMembers,setNumOfMembers] = useState(0)
    
    
    useEffect(()=> {
        if (id && route==="authenticated" && myId) {           
            getProjectData()
            getMembers()
            setProjectPic(`https://crewbite-project-pictures.s3.amazonaws.com/${id}-compact.jpg?${new Date().getTime()}`)
        }
    },[myId,id,route])

    //Handlers
    const handlePrivacy = (value) => {
        setPrivacySetting(value)
    };

    const getDays = (start,end) => {
        setDates([start,end])
    }

    const cancelEdit = () => {
        setEdit(false)
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    const edit_open = Boolean(editAnchorEl);
    const edit_id = edit_open ? 'simple-popper' : undefined;

    //Api calls
    const getProjectData = async () => {
        const apiName = "CrewbiteAPI";
        const path = "get-project";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                project_id: id
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            setProject(response)
            if (response.description) setDescription(response.description)
            if (response.production_date) setPrevDates(response.production_date.split(","))
            if (response.location_addr) setLocation(response.location_addr)
            if (response.producers) setProducers(response.producers)
        } catch (error) { 
            setErrorMessage(
                <>
                    <p>{error.response.data.message}</p>
                    <div className="buttons">
                        <button className="close" onClick={()=>navigate(-1)}>Return</button>
                    </div>
                </>
            )
            setShowModalError(true) 
        }
    }

    const getMembers = async () => {
        const apiName = "CrewbiteAPI";
        const path = "list-project-members";
        const path2 = "list-project-pending-members"

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            queryStringParameters: {
                project_id: id
            }
        }

        try {
            const response = await API.get(apiName, path, myInit)
            for (let m of response ) {
                if (m.user_id === myId) {
                    setPrivacySetting(parseInt(m.privacy_setting))
                }
            }
            const response2 = await API.get(apiName, path2, myInit)
            setNumOfMembers(response.length)
            setMembers([...response,...response2])
        } catch (error) { 
            setErrorMessage(
                <>
                    <p>{error.response.data.message}</p>
                    <div className="buttons">
                        <button className="close" onClick={()=>navigate(-1)}>Return</button>
                    </div>
                </>
            )
            setShowModalError(true) 
        }
    } 

    const updateProject = async () => {
        const apiName = "CrewbiteAPI";
        const path = "update-project";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const body = {
            project_id: id,
        }

        const date_format = dates[1] ? `${(new Date(dates[0]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")},${(new Date(dates[1]*86400000)).toUTCString().split(' ').slice(0, 4).join(' ').replace(/,/g, " ")}` : undefined

        if (description) body.description = description
        if (producers) body.producers = producers
        if (location) body.location_addr = location
        if (date_format) body.production_date = date_format

        const myInit = { 
            headers:{
                Authorization: token
            },
            body
        }

        try {
            await API.post(apiName, path, myInit)
            setProject(prev => {
                const newProject = {...prev}
                newProject.description = description
                newProject.producers = producers
                newProject.location_addr = location
                newProject.production_date = date_format
                return newProject
            })
            setEdit(false)
        } catch (error) { 
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const deleteProject = async () => {
        const apiName = "CrewbiteAPI";
        const path = myId === project.owner_id ? "delete-project" : "leave-project";
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                project_id: id,
            }
        }

        try {
            await API.post(apiName, path, myInit)
            navigate("/project-dashboard")
        } catch (error) { 
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
        <Helmet>
            <title>{project && project.title  + " - Crewbite Inc."}</title>
            <meta name="robots" content="noindex"/>
        </Helmet>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalError}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModalError(false)} />
                <div>{errorMessage}</div>
            </Modal.Body>
        </Modal>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalDelete}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModalDelete(false)} />
                <img alt="delete job icon" src={require("../../icons/trash.png")}/>
                {showModalDelete === "delete" ? (
                        <>
                            <h5 className="modal-heading">Delete this production?</h5>
                            <p>All the production information will be lost including the chatbox and members</p>
                        </>
                    ) : (
                        showModalDelete === "leave" &&
                        <>
                            <h5 className="modal-heading">Leave this production?</h5>
                            <p>You will lose access to the production information including the chatbox and members' information</p>
                        </>
                    )
                }
                <div className="buttons">
                    <button className="cancel" onClick={()=>setShowModalDelete(false)}>Cancel</button>
                    <button className="close" onClick={deleteProject}>Continue</button>
                </div>
            </Modal.Body>
        </Modal> 

        {project ? (
            <Row className="project_container">
                <Col className="project_details" lg={6} md={12}>
                    <div className="pic_and_info">
                        <div className="pic_row">
                            {!edit && 
                                <div onClick={()=>setProjectModalShow(true)} style={{backgroundImage:`url(${projectPic})`}} id="project-pic">
                                    <CropImage 
                                        show={projectModalShow}
                                        onHide={() => {
                                            setProjectModalShow(false)
                                            myId===project.owner_id && setProjectPic(`https://crewbite-project-pictures.s3.amazonaws.com/${id}-compact.jpg?${new Date().getTime()}`)
                                        }}
                                        id={id}
                                        type="project"
                                        editable={myId===project.owner_id}
                                    />
                                </div>
                            }
                            <div className="project_data">
                                <div className="data">
                                    {!edit ? (
                                        <>      
                                            {project.producers && <p className="project_data_element"><span>Producers</span>&nbsp;{project.producers}</p>}
                                            {project.location_addr && <p className="project_data_element"><span>Location</span>&nbsp;{project.location_addr}</p>}
                                            {project.production_date && <p className="project_data_element"><span>Production Date</span>&nbsp;{project.production_date.replace(","," - ")}</p>}
                                            {project.description && 
                                                <p className="project_data_element description">
                                                    {!partialDescription ?
                                                        <>
                                                            {parse(project.description.replaceAll("\n","<br/>"))}
                                                            <span className="see_more" onClick={()=>setPartialDescription(true)}> hide</span> 
                                                        </>
                                                        : 
                                                        <>
                                                            {project.description.slice(0,35) + "... "}
                                                            <span className="see_more" onClick={()=>setPartialDescription(false)}>see more</span> 
                                                        </>
                                                    }
                                                </p>
                                            } 
                                        </>
                                    ) : (
                                        <>
                                            <label htmlFor="edit_producers">Producers</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter Producers..." 
                                                id="edit_producers" 
                                                className="edit_producers" 
                                                maxLength="200"
                                                value={producers} 
                                                onChange={(e)=>setProducers(e.target.value)}
                                            />
                                            <div className="edit_location">
                                                <label htmlFor="edit_location">Location</label>
                                                <Autocomplete
                                                    filterOptions={filterOptions}
                                                    disablePortal
                                                    id="edit_location"
                                                    options={Cities}
                                                    value = {location}
                                                    fullWidth={true}
                                                    renderInput={(params) => <TextField {...params} placeholder="Enter location..."/>}
                                                    onChange={(e,value)=>setLocation(value)}
                                                />
                                            </div>
                                            <div className="availability edit_dates">
                                                <label>Production Date</label>
                                                <CalendarFilter className="calendar" prevDates={prevDates} getDays={getDays}/>
                                            </div>
                                            <label htmlFor="edit_description">Description</label>
                                            <textarea 
                                                id="edit_description" 
                                                className="edit_description" 
                                                placeholder="Production description..." 
                                                maxLength="3000"
                                                value={description} 
                                                onChange={(e)=>setDescription(e.target.value)}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>  
                        <Popper placement="bottom-end" id={edit_id} open={edit_open} anchorEl={editAnchorEl} className="edit_popper">
                            <ClickAwayListener onClickAway={()=>setEditAnchorEl(null)}>
                                <div onClick={()=>setEditAnchorEl(null)}>
                                    {myId === project.owner_id ? (
                                        <>
                                            <PrivacySetting id={id} handlePrivacy={handlePrivacy} privacySetting={privacySetting}/>
                                            <div className="edit_button" onClick={()=>setEdit(true)}>
                                                <EditIcon className="edit_icon"/>
                                                <p>Edit</p>
                                            </div>
                                            <div className="delete_button" onClick={()=>setShowModalDelete("delete")}>
                                                <CloseIcon className="delete_icon"/>
                                                <p>Delete production</p>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="delete_button" onClick={()=>setShowModalDelete("leave")}>
                                            <CloseIcon className="delete_icon"/>
                                            <p>Leave production</p>
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                        </Popper>
                        {!edit && 
                            <div className="project_title">
                                <IoIosArrowBack className="icon" onClick={()=>navigate(-1)}/>
                                <h1>{project.title}</h1>
                                <img alt="settings icon" src={require("../../icons/gear.png")} className="edit_settings" onClick={(e)=>setEditAnchorEl(e.currentTarget)}/>
                            </div>
                        }
                    </div>
                    <div className="project_members_container">
                        <div className="project_members_heading">
                            <h2>{numOfMembers + " members"}</h2>
                            {myId === project.owner_id && <p className="invite_member" onClick={()=>setShowInviteModal(true)}>+ invite</p>}
                            <InviteMember 
                                showInviteModal={showInviteModal} 
                                id={id} 
                                closeForm={() => {
                                    setShowInviteModal(false)
                                    getMembers()
                                }}
                            />
                        </div>
                        {members ? (
                            <ProjectMembers 
                                myId={myId} 
                                owner={project.owner_id} 
                                members={members} 
                                edit={edit}
                                updateProject={updateProject}   
                                cancelEdit={cancelEdit} 
                            /> 
                        ) : (  
                            <Spinner className="small-loader" animation="border" />
                        )}
                    </div>
                </Col>
                <Col className="project_chatbox_container" lg={6} md={12}>
                    <div className="project_chatbox">
                        <ProjectChat channel={project}/>
                    </div>
                </Col>
            </Row>
        ) : (
            <Loader />
        )}
        </>
    )
}

export default Project;