import { API, Auth } from "aws-amplify";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import "./SendEmail.scss"


const SendEmail = (props) => {
    const dispatch = useDispatch();

    const [subject,setSubject] = useState("")
    const [message,setMessage] = useState("")
    const [copy,setCopy] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [sending,setSending] = useState(false)

    const handleSubmit = async () => {
        setSending(true)
        const apiName = "CrewbiteAPI";
        const path = "send-invite-message";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: { 
                user_id: props.id,
                message_subject: subject,
                message_body: message,
                self_copy: copy
            }
        }
        try {
            await API.post(apiName, path, myInit)
            props.onHide()
            setSending(false)
            setShowModal(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
      <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="success-modal send_message_and_email"
      >
        <Modal.Body>
            <CloseIcon onClick={props.onHide} className="close_icon"/>
            <div className="modalform">
                <input 
                    type="text" 
                    placeholder="Subject" 
                    value={subject} 
                    maxLength="200" 
                    autoComplete="off"
                    onChange={(e)=>setSubject(e.target.value)}
                />
                <textarea 
                    placeholder="Your message"  
                    value={message} 
                    maxLength="3000" 
                    autoComplete="off"
                    onChange={(e)=>setMessage(e.target.value)} 
                />
                <div className="checkbox">
                    <input 
                        type="checkbox" 
                        name="copy"  
                        checked={copy} 
                        autoComplete="off"
                        onChange={(e)=>setCopy(e.target.checked)}
                    />
                    <label htmlFor="copy">Send a copy to my mailbox</label>
                </div>
                <button type="button" className="submitButton" disabled={!message || sending} onClick={()=>!sending && message && handleSubmit()}>Send</button>
            </div> 
        </Modal.Body>
      </Modal>
      
      <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
          <Modal.Body>
              <CloseIcon className="closeButton" onClick={()=>setShowModal(false)} />
              <img alt="message sent icon" src={require("../../icons/mailbox.png")}/>
              <h5 className="modal-heading">The message has been sent!</h5>
              <p>You can continue/manage all ongoing conversations via <a href="/messages" className="messenger_link">Crewbite Messenger</a> </p>
          </Modal.Body>
      </Modal>
      </>
    );
  }
  export default SendEmail;
