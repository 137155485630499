import { useEffect } from "react"
import { useSelector } from "react-redux"
import parse from 'html-react-parser'

const ChatMessage = (props) => {
    const user_id = useSelector(state => state.id)

    const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric"
    }

    return (
        <div 
            className={`message ${user_id === props.message.msg_sender_id ? "sender_message" : "receiver_message"}`}
        >
            <div className="message_sender_and_time">
                <p className="sender_name">{props.message.msg_sender_first_name} {props.message.msg_sender_last_name}</p>
                <p className="message_time">
                    {(new Date(props.message.msg_tstamp * 1000)).toLocaleString("en-US",options)}
                </p>
            </div>
            <div className="message_div">
                <img alt="one on one chat pic" src={`https://crewbite-user-profile-pictures.s3.amazonaws.com/${props.message.msg_sender_id}-compact.jpg`}/>
                <div className="message_content">
                    {JSON.parse(props.message.msg_type) === 1 && <p>{parse(props.message.msg_body.replaceAll("\n","<br/>"))}</p>}
                    {JSON.parse(props.message.msg_type) === 2 && <img height="100px" width="100px" alt="chat image" src={"data:image/jpeg;base64," + props.message.msg_body}/>}
                </div>
            </div>
        </div>
    )
}

export default ChatMessage