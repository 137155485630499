import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Footer.scss"

const Footer = () => {

    const user_id = useSelector(state => state.id)

    return (
        <div className="footer">
            <div className="copyrights">
                <div className="socialmedia">
                    <a href="https://www.facebook.com/crewbites"><img alt="facebook logo" src={require("../../icons/facebook.png")}/></a>
                    <a href="https://www.instagram.com/crewbite/"><img alt="instagram logo" src={require("../../icons/instagram-white.png")}/></a>
                    <a href="https://www.linkedin.com/company/crewbite/"><img alt="linkedin logo" src={require("../../icons/linkedin-square.png")}/></a>
                </div>
                <h2 className="footer-content">Copyright @ Crewbite, Inc. 2022</h2> 
                <Link className="about-link" to={"/about-us"}>More about Crewbite here</Link>
                <p className="policy-terms">
                    <Link className="footer-content" to={"/privacy-policy"}>Privacy policy</Link>
                    <span>|</span>
                    <Link className="footer-content" to={"/terms-and-conditions"}>Terms of service</Link>
                </p>
            </div>
            {!user_id &&
                <div className="join-now">
                    <h3 className="heading">Join Crewbite and get connected with the industry</h3>
                    <Link to="/signin" state={{ page: "signUp" }} className="signup">Sign Up!</Link>
                </div>
            }

            <div className="quick-links">
                    <h3 className="heading">Quick Links</h3>
                    <Link to={"/hire-crew?page=1&sort=default&position=Producer"} className="search-link">Hire Producers</Link>
                    <Link to={"/hire-crew?page=1&sort=default&position=Sound Designer"} className="search-link">Hire Sound Designers</Link>
                    <Link to={"/hire-crew?page=1&sort=default&position=Photographer"} className="search-link">Hire Photographers</Link>
                    <Link to={"/hire-crew?page=1&sort=default&city=Vancouver, BC, CA&radius=150"} className="search-link">Hire Crew in Vancouver</Link>
                </div>
        </div>
    )
}

export default Footer;