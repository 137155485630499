import { LinearProgress } from "@mui/material";
import Logo from "../../icons/crewbite-full-logo.png";

import "./Loader.scss";

const Loader = () => {
    return (
        <div className="loader">
            <img alt="Crewbite logo" src={Logo}/>
            <LinearProgress className="progress"/>
        </div>
    )
}

export default Loader;