import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { v4 as uuid } from 'uuid';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { API, Auth } from "aws-amplify";
import { Helmet } from "react-helmet-async";

import { Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

import Tips from "../../components/Tips/Tips";
import PastConnections from "../../components/PastConnections/PastConnections";
import Skills from "../../components/Skills/Skills";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";

import GenderEthics from "./GenderEthics";
import Cities from '../../data/cities.json';
import industryRoles from '../../data/industryRoles.json';

import "../Profile/EditProfile.scss";
import "./CreateProfile.scss";

const CreateProfile = () => {

    const hasProfile = useSelector((state) => state.hasProfile)
    const user_id = useSelector((state) => state.id)
    const { route } = useAuthenticator((context) => [context.route]);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [firstName,setFirstName] = useState("")
    const [lastName,setLastName] = useState("")
    const [roleInput,setRoleInput] = useState(null)
    const [roles,setRoles] = useState([])
    const [location,setLocation] = useState(null)
    const [union,setUnion] = useState("")
    const [covid,setCovid] = useState("")
    const [volunteer,setVolunteer] = useState("")
    const [instagram,setInstagram]=useState("")
    const [demoReel,setDemoReel] = useState("")
    const [imdbProfile,setImdbProfile] = useState("")
    const [about,setAbout] = useState("")
    const [errorMessage,setErrorMessage] = useState(undefined)
    const [phone,setPhone] = useState("")
    const [saveChange,setSaveChange] = useState(false)
    const [showModal,setShowModal] = useState(false)
    const [showModalConfirmation,setShowModalConfirmation] = useState(false)
    const [displayPhone,setDisplayPhone] = useState(false)
    const [newConnections,setNewConnections] = useState([])
    const [newSkills,setNewSkills] = useState([])
    const [canSubmit,setCanSubmit] = useState(true)
    const [showGenderEthics,setShowGenderEthics] = useState(false)


    const options = [].concat(...Object.values(industryRoles)).map(role => {
        const category = Object.keys(industryRoles).find(key => industryRoles[key].includes(role));
        return {
            category,
            role
        }
    })

    useEffect(()=>{
        getContactInfo()
    },[])

    useEffect(()=>{
        if (hasProfile) {
            dispatch({type:'SHOW_ERROR', payload:"You already have a Profile"})
        }
    },[hasProfile])

    //Handlers
    const addRole = (e,value) => {
        setRoleInput(value)
        setRoles(prev=> {
            if (value !== null && !prev.some(val=>val===value) && prev.length<5) return [...prev,value]
            else return prev
        })

        setTimeout(()=>{
            setRoleInput(null)
        },50)
    }

    const handleNewConnections = async (user_name) => {
        setNewConnections(prev=> [...prev,{id:uuid(),user_name}])
    }

    const handleRemoveConnection = async (id) => {
        setNewConnections(prev=>prev.filter(c=>c.id!==id))
    }

    const handleNewSkills = async (skill) => {
        setNewSkills(prev=> {
            if (!prev.some(val=>val.value===skill) && skill!=="") return [...prev,{id:uuid(),value:skill}]
            else return prev
        })
    }

    const handleRemoveSkill = async (id) => {
        setNewSkills(prev=>prev.filter(s=>s.id!==id))
    }

    const handlePhoneChange = (val) => {
        setPhone(val)
    }
    
    const handleSaveChange = (val) => {
        setSaveChange(val)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'  && canSubmit) {
          e.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        phone ? setShowModalConfirmation(true) : setShowGenderEthics(true)
        window.scrollTo(0,0)
    }

    const handleErrorMessage = (error) => {
        setErrorMessage(error)
    }

    const handleEthicsForm = () => {
        setShowGenderEthics(false)
    } 

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    //Api-calls
    const getContactInfo = async () => {
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const apiName = "CrewbiteAPI"
        const path = "get-contact-info"
        const myInit = {
          headers:{
            Authorization: token
          }
        }
        try {
            const response = await API.get(apiName, path, myInit)
            if (response.phone_number) setDisplayPhone(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const updatePhone = async () => {
        const apiName = "CrewbiteAPI";
        const path = "update-phone-number";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: { 
                phone_number: phone ? `+${phone}` : ""
            }
        }
        try {
            await API.post(apiName, path, myInit)
            setShowModalConfirmation(false)
            setShowModal(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    const createProfile = async (gender,ethics,race) => {   
        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        
        const metaData = [...ethics]
        
        if (union) metaData.push("union")
        if (volunteer) metaData.push("volunteer_work")
        if (covid) metaData.push("covid_vac")
        
        const body = {}

        if (firstName) body["first_name"] = firstName
        if (lastName) body["last_name"] = lastName       
        if (location) body["location_addr"] = location      
        if (roles.length>0) body["industry_role"] = roles 
        if (about) body["about_me"] = about   
        if (newConnections.length>0) body["past_connections"] = newConnections.map(c=>c.user_name)
        if (newSkills.length>0) body["skill_sets"] = newSkills.map(s=>s.value)
        if (metaData.length>0) body["metadata"] = metaData
        if (demoReel) body["demo_reel"] = demoReel
        if (imdbProfile) body["imdb_profile"] = imdbProfile
        if (instagram) body["instagram"] = instagram
        if (gender.length>0) body["gender_id"] = gender 
        if (race.length>0) body["ethics_id"] = race

        const apiName = "CrewbiteAPI";
        const path = "create-profile";

        const myInit = { 
            headers:{
                Authorization: token
            },
            body
        }
        try {
            await API.post(apiName, path, myInit)
            updatePhone()
            dispatch({type:"HAS_PROFILE",payload:true})
            navigate(`../profile/${user_id}`)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }

    }

    return (
        <>
        <Helmet>
            <title>Create Profile - Crewbite Inc.</title>
            <meta name="robots" content="noindex, follow"/>
        </Helmet>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModalConfirmation}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModalConfirmation(false)} />
                <img alt="telephone blue icon" src={require("../../icons/telephone-blue.png")}/>
                <h5 className="modal-heading">Crewbite guards your privacy with great effort.</h5>
                <p>Your contact information is only visible to the job owner after you submitted your job application.</p>
                <div className='buttons'>
                    <button className='cancel' onClick={()=>setShowModalConfirmation(false)}>Cancel</button>
                    <button 
                        className='close' 
                        onClick={()=>{
                            setShowModalConfirmation(false)
                            setShowGenderEthics(true)
                        }}
                    >
                        Continue
                    </button>
                </div>
            </Modal.Body>
        </Modal>

        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>setShowModal(false)} />
                <img alt="save phone number icon" src={require("../../icons/save.png")}/>
                <h5 className="modal-heading">Saved!</h5>
                <p>Your phone number has been updated.</p>
            </Modal.Body>
        </Modal>

        {route === 'authenticated' &&

        <div className="createContainer" >

            <div className="selectors">
                <div className="section-selector"></div>
                <div className={"section-selector " + (!showGenderEthics ? "active " : "")} ></div>
            </div>

            {showGenderEthics ? <GenderEthics goBack={handleEthicsForm} createProfile={createProfile}/> : (
                <form 
                    className="create-form" 
                    onSubmit={handleSubmit}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                >
                    <div className="editContainer">
                        <h1 className="title">Create Profile</h1>
                        <label className="label-with-tip" htmlFor="name"><p>What's your name? <span className="required">*</span> </p>
                            <Tips content="Leave your name to allow others find you easier"/>
                        </label>
                        <div className="name-inputs">
                            <input 
                                type="text" 
                                name="name" 
                                value={firstName} 
                                onChange={(e)=>setFirstName(e.target.value)} 
                                placeholder="First Name" 
                                maxLength="30" 
                                required
                            />
                            <input 
                                type="text" 
                                name="name" 
                                value={lastName} 
                                onChange={(e)=>setLastName(e.target.value)} 
                                placeholder="Last Name" 
                                maxLength="30" 
                                required/>
                        </div>

                        <h5 className="header label-with-tip"><p>Your industry role <span className="required">*</span></p>
                            <Tips content="We know you can be anything you want. Crewbite allows you to list down up to five roles that you do in the industry based on your own preference of priority."/>
                        </h5>
                        <div className="inputs">
                            <div className="roleInput">
                                <Autocomplete
                                    disablePortal
                                    options={options.sort((a,b)=>-b.category.localeCompare(a.category))}
                                    groupBy={(option) => option.category}
                                    getOptionLabel={(option) => option.role || ""}
                                    fullWidth={true}
                                    renderInput={(params) => <TextField {...params} label="Roles (choose up to 5 roles)" required={roles.length===0}/>}
                                    onChange={(e,value) => addRole(e,value.role)}
                                    value={roleInput}
                                />
                            </div>
                        </div>

                        {roles && roles.length>0 &&
                            <div className="skillTags">
                                {roles.map(r=>(
                                    <div className="skill" key={r}>
                                        <p>{r}</p>
                                        <CloseIcon onClick={()=>setRoles(prev=>prev.filter(val=>val!==r))}/>
                                    </div>
                                ))}
                            </div>
                        }

                        {roles.length >= 5 && <p className="roles-error">You can have a maximum of 5 roles</p>}

                        <div className="cityInput">
                            <Autocomplete
                                filterOptions={filterOptions}
                                disablePortal
                                id="city"
                                options={Cities}
                                value = {location || null}
                                fullWidth={true}
                                renderInput={(params) => <TextField {...params} label="Where are you located?" required/>}
                                onChange={(e,value)=>setLocation(value)}
                            />
                            <Tips content="Tell us where you’re based in and let us connect you with local productions and professionals."/>
                        </div>

                        {!displayPhone && 
                            <PhoneNumber 
                                phone={phone} 
                                handlePhoneChange={handlePhoneChange} 
                                saveChange={saveChange}
                                handleSaveChange={handleSaveChange}
                            />
                        }

                        <div className="checkbox">
                            <input type="checkbox" name="union" id="union" checked={union} onChange={(e)=>setUnion(e.target.checked)} />
                            <label htmlFor="union">I'm a union member</label>
                        </div>

                        <div className="checkbox">
                            <input type="checkbox" name="covid" id="covid" checked={covid} onChange={(e)=>setCovid(e.target.checked)} />
                            <label htmlFor="covid">I'm a Covid-19 Vaccinated</label>
                        </div>
                        
                        <div className="checkbox">
                            <input type="checkbox" name="volunteer" id="volunteer" checked={volunteer} onChange={(e)=>setVolunteer(e.target.checked)} />
                            <label htmlFor="volunteer">I'm open for volunteer work</label>
                        </div>
                            
                        <label className="label-with-tip" htmlFor="demo">Link to your website/demo reel
                            <Tips content="Share the link to your demo reel with others and show off your work."/>
                        </label>
                        <input 
                            className="metainput" 
                            type="text" 
                            name="demo" 
                            placeholder="Vimeo, YouTube, etc" 
                            value={demoReel} 
                            maxLength="100" 
                            onChange={(e)=>setDemoReel(e.target.value)} 
                        />

                        <label htmlFor="imdb">Link to your IMDb</label>
                        <input 
                            className="metainput" 
                            type="text" 
                            name="imdb" 
                            placeholder="https://" 
                            value={imdbProfile} 
                            maxLength="100" 
                            onChange={(e)=>setImdbProfile(e.target.value)} 
                        />

                        <label htmlFor="insta">Link to your Instagram account</label>
                        <input 
                            className="metainput" 
                            type="text" 
                            name="insta" 
                            placeholder="https://" 
                            value={instagram}
                            maxLength="100" 
                            onChange={(e)=>setInstagram(e.target.value)} 
                        />

                        <label className="label-with-tip" htmlFor="about">About you
                            <Tips content="How long have you been working in the industry? Telling people more about yourself will help boost your profile and increase the opportunity to get hired."/>
                        </label>
                        <textarea 
                            name="about" 
                            value={about} 
                            maxLength="3000" 
                            placeholder=" Maximum 500 words.
                            What is your past working experience?
                            How long have you been working in the industry?
                            What is your biggest achievement?" 
                            onChange={(e)=>setAbout(e.target.value)}
                            onFocus={()=>setCanSubmit(false)}
                            onBlur={()=>setCanSubmit(true)}
                        />
                        
                        <h5 className="header label-with-tip">Add your industry connections and coworkers to improve your profile's validation.
                            <Tips content="E-mail of producers & directors that you have been working with recently. You must enter the exact e-mail of the desired connection"/>
                        </h5>

                        <PastConnections 
                            newConnections = {newConnections}
                            handleNewConnections={handleNewConnections} 
                            handleErrorMessage={handleErrorMessage}
                            handleRemoveConnection={handleRemoveConnection}
                        />                
                        
                        <label className="label-with-tip" htmlFor="skills">Qualifications & Skills
                            <Tips content="List all your skills and certifications to help increase your chance to get hired! Tips: education, first aid, driving license. To add a skill enter the value and click on add skill"/>
                        </label>

                        <Skills 
                            newSkills={newSkills} 
                            handleRemoveSkill={handleRemoveSkill} 
                            handleNewSkills={handleNewSkills}
                        />

                        <div className="errorMessage">{errorMessage}</div>
                        
                        <div className="buttons2">
                            <button type="button" onClick={()=>navigate(-1)} className="cancel">Cancel</button>
                            <input type="submit" className="save" value="Next" disabled={!saveChange}/>
                        </div>

                    </div>
                </form>
            )} 
        </div>}
        </>
    )
}

export default CreateProfile;