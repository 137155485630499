import { useEffect, useState } from 'react';
import {API, Auth} from "aws-amplify";
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Popper from '@mui/material/Popper';
import CloseIcon from '@mui/icons-material/Close';

import "./Invitation.scss";

const Invitation = (props) => {
    const dispatch = useDispatch();

    const [anchorEl,setAnchorEl] = useState(null)
    const [showModal, setShowModal] = useState(false);

    const sendInvitation = async (email) => {
        const apiName = "CrewbiteAPI";
        const path = "send-onboarding-invitation";
        const token = `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        const myInit = {
            headers: {
                Authorization: token
            },
            body: {
                email
            }
        }
        try {
            await API.post(apiName, path, myInit)
            props.closePopper()
            setShowModal(true)
        } catch (error) {
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
            props.closePopper()
        }
    }

    useEffect(()=> {
        setAnchorEl(props.element)
    },[props.element])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <>
        <Popper id={id} open={open} anchorEl={anchorEl}>
            <div className="invitation-to-join">
                <CloseIcon className="close-icon" onClick={()=>props.closePopper()} />
                <p><span>{props.email}</span> is not registered on Crewbite. Click here to send an invitation to join!</p>
                <button type="button" onClick={()=>sendInvitation(props.email)}>Send Invitation</button>
            </div>                  
        </Popper>
        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={showModal}>
          <Modal.Body>
              <CloseIcon className="closeButton" onClick={()=>setShowModal(false)} />
              <img alt="Invitation sent icon" src={require("../../icons/mailbox.png")}/>
              <h5 className="modal-heading">The Invitation has been sent!</h5>
          </Modal.Body>
        </Modal> 
        </>
    )
}

export default Invitation
