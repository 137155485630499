import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import {API, Auth} from "aws-amplify";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap";

import FilterJobs from "../../components/Filter/FilterJobs";
import Loader from "../../components/Loader/Loader";

import "./FindJob.scss";

const FindJob = () => {
    const dispatch = useDispatch();
    const { route } = useAuthenticator((context) => [context.route]);

    const [searchParams, setSearchParams] = useSearchParams();

    const [jobs,setJobs] = useState(undefined)
    const [filters,setFilters] = useState(undefined)
    const [numOfElements, setNumOfElements] = useState();
    const [active, setActive] = useState(1)
    const [sort,setSort] = useState("created_at")
    const [loading,setLoading] = useState(false)

    const items_per_page = 15

    useEffect(()=> {
    
        const newFilters = Object.fromEntries([...searchParams])
        if (Object.keys(newFilters).length !== 0) {
            setActive(+newFilters.page)
            setSort(newFilters.sort)
            setFilters(newFilters)
            getJobs(newFilters)
        } else {
            setFilters({page:1,sort:"created_at"})
            getJobs({page:1,sort:"created_at"}) 
        }

    },[searchParams])

    //Handlers
    const getFilters = (filters) => {
        setSearchParams(filters)
    }

    const sortResults = (order) => {
        if (order === "distance" && !filters.city) {
            dispatch({type:'SHOW_ERROR', payload:"You must provide a location in order to sort by distance"})
        } else {
            if (filters && jobs.length>1) {
                const copyFilters = filters
                copyFilters.sort=order
                setSearchParams(copyFilters) 
            }
        }
    }

    //Api calls
    const getJobs = async (data) => {
        setLoading(true)
        const meta = []
        
        if (data.remote && JSON.parse(data.remote)) meta.push("remote")
        if (data.union && JSON.parse(data.union)) meta.push("union")
        if (data.covid && JSON.parse(data.covid)) meta.push("covid_vac")
        if (data.voluntary && JSON.parse(data.voluntary)) meta.push("volunteer_work")
        
        const metaData = meta.join(',')

        const apiName = "CrewbiteAPI";
        const path = "query-job";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers: {
                Authorization: token
            },
            queryStringParameters: { 
                start_index: (data.page - 1) * items_per_page,
                end_index: (data.page * items_per_page) - 1,
                sort: data.sort
            }
        };

        if (data.position) myInit.queryStringParameters["industry_role"] = data.position
        if (data.city) myInit.queryStringParameters["location_point"] = data.city
        if (data.city) myInit.queryStringParameters["radius"] = data.radius
        if (metaData) myInit.queryStringParameters["metadata_filters"] = metaData
        if (data.union_tag) myInit.queryStringParameters["union_filter"] = data.union_tag

        try {
            const response = await API.get(apiName, path, myInit)
            setJobs(response.items)
            setNumOfElements(response.total_num)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
        }
    }

    return (
        <>
        <Helmet>
            <title>Find a Job - Crewbite Inc.</title>
            <meta name="robots" content="noindex, follow"/>
        </Helmet>

        {route !== 'authenticated' ? 
        ( 
            <Loader/> 
        ) : (
            <Container fluid>
                <Row>
                    <FilterJobs 
                        filters={filters} 
                        getFilters={getFilters} 
                        numOfElements={numOfElements}  
                        sortResults={sortResults}  
                        sort={sort}
                        jobs={jobs}
                        loading={loading}
                        active={active}
                        items_per_page={items_per_page}
                    />
                </Row>
            </Container>
        )}
        </>
    )
}

export default FindJob;