import React, {useEffect} from "react";
import {Amplify, API, Auth, graphqlOperation} from "aws-amplify";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RequireAuth } from "./components/RequireAuth/RequireAuth.js";
import { HelmetProvider } from "react-helmet-async";
import { useCookies } from 'react-cookie';
import { Authenticator } from '@aws-amplify/ui-react';
import aws_configuration from "./configurations/aws-configuration";
import * as subscriptions from './graphql/subscriptions';


import Navigation from "./components/Navigation/Navigation.js";
import Home from "./pages/Home/Home.js";
import HireCrew from "./pages/HireCrew/HireCrew.js";
import Profile from "./pages/Profile/Profile.js";
import CreateProfile from "./pages/CreateProfile/CreateProfile.js";
import PlanSelection from "./pages/PlanSelection/PlanSelection.js";
import Policy from "./pages/Policy/Policy.js";
import Terms from "./pages/Terms/Terms.js";
import PostJob from "./pages/PostJob/PostJob.js";
import FindJob from "./pages/FindJob/FindJob.js";
import ApplyJob from "./pages/FindJob/ApplyJob.js";
import MyJob from "./pages/MyJob/MyJob.js";
import SignIn from "./pages/SignIn/SignIn.js";
import JobDetails from "./pages/JobDetails/JobDetails.js";
import Settings from "./pages/Settings/Settings.js";
import About from "./pages/About/About.js";
import NotFound from "./pages/NotFound/NotFound.js";
import ProjectDashboard from "./pages/ProjectDashboard/ProjectDashboard.js";
import Project from "./pages/Project/Project.js";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe.js";
import Messages from "./pages/Messages/Messages.js";
import ErrorModal from "./components/ErrorModal/ErrorModal.js";
import PendingActions from "./pages/PendingActions/PendingActions.js";

import "./App.scss";

Amplify.configure(aws_configuration);
export const channel = new BroadcastChannel('crewbite_session');

function App() {

  const user_id = useSelector(state => state.id)
  const dispatch = useDispatch();

  const [ cookies, setCookie ] = useCookies(['union_tag']);
  
  useEffect(() => {
    getUserData()

    channel.onmessage = (e) => {
      channel.close()
      window.location.reload()
    }
  }, []);

  useEffect(()=>{
    if (user_id) {
        const subscription = API.graphql(
            graphqlOperation(subscriptions.onNewMessage, {
                receiver_id:  user_id
            })
        ).subscribe({
            next: ({ provider, value }) => {
                dispatch({type:'ADD_NEW_MESSAGE', payload:value.data.onNewMessage.channel_id});
            },
            error: (error) => console.warn(error)
        });
        addUnreadMessages()
        return () => subscription.unsubscribe()
    }
  },[user_id])

  const addUnreadMessages = async () => {
    const apiName = "CrewbiteAPI"
    const path = "fetch-message-channels"
    const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

    const myInit = { 
        headers:{
            Authorization: token
        }
    }
    try {
        const response = await API.get(apiName, path, myInit)
        for (let channel of response) {
          if (channel.is_read === "0") {
            dispatch({type:'ADD_NEW_MESSAGE', payload:channel.channel_id});
          }
        }
    } catch (error) { 
      if (error.response.status !== 429) {
        dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
      }
    }
  }

  const getPremium = async (token) => {
    const apiName = "CrewbiteAPI"
    const path = "is-premium"
    const myInit = {
      headers:{
        Authorization: token
      }
    }
    try {
      const response = await API.get(apiName, path, myInit)
      dispatch({type:'IS_PREMIUM', payload:response});
    } catch (error) {
      dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
    }
  }

  const checkProfile = async (id) => {
    const apiName = "CrewbiteAPI"
    const path = "has-profile"
    const myInit = {
      queryStringParameters: {
        user_id: id
      }
    }
    try {
      const response = await API.get(apiName, path, myInit)
      dispatch({type:'HAS_PROFILE', payload:response});
    }  catch (error) {
      dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
    }
  }

  const checkUnion = async (token) => {
    const apiName = "CrewbiteAPI"
    const path = "get-profile"
    const myInit = {
      headers:{
        Authorization: token
      }
    }
    try {
      const union_tag = (await API.get(apiName, path, myInit)).union_tag
      if (union_tag) {
        dispatch({type:'SET_UNION', payload:union_tag});
        setCookie('union_tag', union_tag, { maxAge: 86400*30})
      }
    }  catch (error) {
      dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
    }
  }

  const getActions = async (token) => {
    const apiName = "CrewbiteAPI";
    const path = "list-all-action-items";

    const myInit = { 
        headers:{
            Authorization: token
        }
    }

    try {
        const response = await API.get(apiName, path, myInit)
        if (response.some(action=>action.item_status==="0")) dispatch({type:'HAS_INVITATION',payload:true});
    } catch (error) {
        dispatch({type:'SHOW_ERROR', payload:error.response.data.message})
    }
  }

  const getUserData = async () => {
    try {
      const user_id = (await Auth.currentAuthenticatedUser()).username
      const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      dispatch({type: 'SET_USER', payload: { id: user_id, token}});

      getPremium(token)
      checkProfile(user_id)
      getActions(token)
      cookies.union_tag ? dispatch({type:'SET_UNION', payload:cookies.union_tag}) : checkUnion(token)
    } catch {
      dispatch({type: 'SET_USER', payload: { id: undefined }});
    }
  }

  return (
    <HelmetProvider>
      <Authenticator.Provider>
        <BrowserRouter>
        <ErrorModal />
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/hire-crew" element={<HireCrew />}/>
            <Route 
              path="/messages" 
              element={
              <RequireAuth>
                <Messages />
              </RequireAuth>
            }/>
            <Route 
              path="/find-job" 
              element={
              <RequireAuth>
                <FindJob />
              </RequireAuth>
            }/>
            <Route 
              path="/profile/:id" 
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route path="/signin" element={<SignIn handleSignIn={getUserData}/>}/>
            <Route 
              path="/create-profile" 
              element={
                <RequireAuth>
                  <CreateProfile />
                </RequireAuth>
              }
            />
            <Route 
              path="/plan-selection" 
              element={
                <RequireAuth>
                  <PlanSelection />
                </RequireAuth>
              }
            />
            <Route 
              exact path="/post-a-job" 
              element={
                <RequireAuth>
                  <PostJob />
                </RequireAuth>
              }
            />
            <Route 
              path="/post-a-job/:id" 
              element={
                <RequireAuth>
                  <JobDetails />
                </RequireAuth>
              }
            /> 
            <Route 
              exact path="/project-dashboard" 
              element={
                <RequireAuth>
                  <ProjectDashboard />
                </RequireAuth>
              }
            />
            <Route 
              exact path="/project/:id" 
              element={
                <RequireAuth>
                  <Project />
                </RequireAuth>
              }
            />
            <Route 
              path="/job/:id"
              element={
                <RequireAuth>
                  <ApplyJob />
                </RequireAuth>
              }
            />
            <Route 
              path="/my-job-applications" 
              element={
                <RequireAuth>
                  <MyJob />
                </RequireAuth>
              }
            />
            <Route 
              path="/settings" 
              element={
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              }
            />
            <Route 
              path="/pending-actions" 
              element={
              <RequireAuth>
                <PendingActions />
              </RequireAuth>
            }/>
            <Route path="/email-unsub" element={<Unsubscribe />}/>
            <Route path="/about-us" element={<About />}/>
            <Route path="/privacy-policy" element={<Policy />}/>
            <Route path="/terms-and-conditions" element={<Terms />}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </BrowserRouter>
      </Authenticator.Provider>
    </HelmetProvider>
  );
}

export default App;
