import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { v4 as uuid } from 'uuid';

import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import industryRoles from '../../data/industryRoles.json';
import Cities from '../../data/cities.json';

import Tips from "../../components/Tips/Tips";
import Skills from "../../components/Skills/Skills";
import PastConnections from "../../components/PastConnections/PastConnections";
import EditGenderEthics from "./EditGenderEthics";

import "./EditProfile.scss"

const EditProfile = (props) => {

    const [firstName,setFirstName] = useState(props.profile.first_name)
    const [lastName,setLastName] = useState(props.profile.last_name)
    const [roles,setRoles] = useState(props.profile.industry_role)
    const [location,setLocation] = useState(props.profile.location_addr)
    const [union,setUnion] = useState(props.profile.metadata ? props.profile.metadata.some(val=>val==="union") : false)
    const [covid,setCovid] = useState(props.profile.metadata ? props.profile.metadata.some(val=>val==="covid_vac") : false)
    const [volunteer,setVolunteer] = useState(props.profile.metadata ? props.profile.metadata.some(val=>val==="volunteer_work"): false)
    const [instagram,setInstagram]=useState(props.profile.instagram || "")
    const [demoReel,setDemoReel] = useState(props.profile.demo_reel || "")
    const [imdbProfile,setImdbProfile] = useState(props.profile.imdb_profile || "")
    const [about,setAbout] = useState(props.profile.about_me || "")
    const [roleInput,setRoleInput] = useState(null)
    const [errorMessage,setErrorMessage] = useState(undefined)
    const [newConnections,setNewConnections] = useState([])
    const [newSkills,setNewSkills] = useState([])
    const [canSubmit,setCanSubmit] = useState(true)
    const [gender,setGender] = useState(props.profile.gender_id)
    const [ethnicity,setEthnicity] = useState(props.profile.ethics_id)
    const [ethics,setEthics] = useState(undefined)

    const options = [].concat(...Object.values(industryRoles)).map(role => {
        const category = Object.keys(industryRoles).find(key => industryRoles[key].includes(role));
        return {
            category,
            role
        }
    })

    useEffect(()=> {

        if (props.profile.past_connections && props.profile.past_connections.length > 0) {
            for (let connection of props.profile.past_connections) {
                let newConnection = {
                    id: uuid(),
                    user_name: connection
                }
                setNewConnections(prev=>[...prev,newConnection])
            }
        }  

        if (props.profile.skill_sets) {
            for (let skill of props.profile.skill_sets) {
                let newSkill = {
                    id: uuid(),
                    value: skill
                }
                setNewSkills(prev=>[...prev,newSkill])
            }
        }

        const ethics_data = []
        props.profile.metadata?.forEach(val => {
            if (val==="lgbtqia2" || val==="bipoc") {
                ethics_data.push(val)
            }
        })
        setEthics(ethics_data)
        
    },[])

    //Handlers
    const handleNewConnections = async (user_name) => {
        setNewConnections(prev=> [...prev,{id:uuid(),user_name}])
    }

    const handleRemoveConnection = async (id) => {
            setNewConnections(prev=>prev.filter(c=>c.id!==id))
    }

    const handleNewSkills = async (skill) => {
        setNewSkills(prev=> {
            if (!prev.some(val=>val.value===skill) && skill!=="") return [...prev,{id:uuid(),value:skill}]
            else return prev
        })
    }

    const handleRemoveSkill = async (id) => {
        setNewSkills(prev=>prev.filter(s=>s.id!==id))
    }

    const handleErrorMessage = (error) => {
        setErrorMessage(error)
    }

    const addRole = (e,value) => {
        setRoleInput(value)
        setRoles(prev=> {
            if (value !== null && !prev.some(val=>val===value) && prev.length<5) return [...prev,value]
            else return prev
        })

        setTimeout(()=>{
            setRoleInput(null)
        },50)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && canSubmit) {
          e.preventDefault();
        }
    };

    const handleGenderEthics = (gender,ethics,ethnicity) => {
        setGender(gender)
        setEthics(ethics)
        setEthnicity(ethnicity)
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    //Api calls
    const updateProfile = async (e) => {
        e.preventDefault()

        const metaData= [...ethics]
        
        if (union) metaData.push("union")
        if (covid) metaData.push("covid_vac")
        if (volunteer) metaData.push("volunteer_work")
        
        const apiName = "CrewbiteAPI";
        const path = "update-profile";

        const token =`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

        const myInit = { 
            headers:{
                Authorization: token
            },
            body: {
                first_name: firstName,
                last_name: lastName,       
                location_addr: location,      
                industry_role: roles, 
                about_me: about,   
                past_connections: newConnections.map(c=>c.user_name),
                skill_sets: newSkills.map(s=>s.value),
                metadata: metaData,
                demo_reel: demoReel,
                imdb_profile: imdbProfile,
                instagram: instagram,
                gender_id: gender,
                ethics_id: ethnicity
            }
        }
        try {
            await API.post(apiName, path, myInit)
            props.setEdit(true)
        } catch (error) {
            setErrorMessage(error.response.data.message)
        }

    }

    return (
        <form 
            onSubmit={updateProfile}
            onKeyDown={handleKeyDown}
            className="editContainer" 
            autoComplete="off"
        >
            <div className="buttons">
                <button type="button" onClick={()=>props.setEdit()} className="cancel">Cancel</button>
                <input type="submit" className="save" value="Save"/>
            </div>

            <p className="errorMessage">{errorMessage}</p>

            <label className="label-with-tip" htmlFor="name"><p>What's your name? <span className="required">*</span></p>
                <Tips content="Leave your name to allow others find you easier"/>
            </label>
            <div className="name-inputs">
                <input type="text" name="name" value={firstName} onChange={(e)=>setFirstName(e.target.value)} maxLength="30" placeholder="First Name" required/>
                <input type="text" name="name" value={lastName} onChange={(e)=>setLastName(e.target.value)} maxLength="30" placeholder="Last Name" required/>
            </div>

            <h5 className="header label-with-tip" ><p>Your industry roles <span className="required">*</span></p>
                <Tips content="We know you can be anything you want. Crewbite allows you to list down five roles that you do in the industry based on your own preference of priority."/>
            </h5>
            <div className="roleInput">
                <Autocomplete
                    disablePortal
                    options={options.sort((a,b)=>-b.category.localeCompare(a.category))}
                    groupBy={(option) => option.category}
                    getOptionLabel={(option) => option.role || roleInput}
                    fullWidth={true}
                    renderInput={(params) => <TextField {...params} label="Roles" required={roles.length===0}/>}
                    onChange={(e,value) => addRole(e,value.role)}
                    value={roleInput}
                />
            </div>

            {roles && roles.length>0 &&
                <div className="skillTags">
                    {roles.map(r=>(
                        <div className="skill" key={r}>
                            <p>{r}</p>
                            <CloseIcon onClick={()=>setRoles(prev=>prev.filter(val=>val!==r))}/>
                        </div>
                    ))}
                </div>
            }

            {roles.length >= 5 && <p className="roles-error">You can have a maximum of 5 roles</p>}

            <div className="cityInput">
                <Autocomplete
                    filterOptions={filterOptions}
                    disablePortal
                    id="city"
                    options={Cities}
                    value = {location}
                    fullWidth={true}
                    renderInput={(params) => <TextField {...params} label="Where are you located?" required/>}
                    onChange={(e,value)=>setLocation(value)}
                />
                <Tips content="Tell us where you’re based in and let us connect you with local productions and professionals."/>
            </div>

            <div className="checkbox">
                <input type="checkbox" name="union" checked={union} onChange={(e)=>setUnion(e.target.checked)} />
                <label htmlFor="union">I'm a union member</label>
            </div>

            <div className="checkbox">
                <input type="checkbox" name="covid" checked={covid} onChange={(e)=>setCovid(e.target.checked)} />
                <label htmlFor="covid">I'm a Covid-19 Vaccinated</label>
            </div>
            
            <div className="checkbox">
                <input type="checkbox" name="volunteer" checked={volunteer} onChange={(e)=>setVolunteer(e.target.checked)} />
                <label htmlFor="volunteer">I'm open for volunteer work</label>
            </div>
                
            <label className="label-with-tip" htmlFor="demo">Link to your demo reel
                <Tips content="Share the link to your demo reel with others and show off your work."/>
            </label>
            <input className="metainput" type="text" name="demo" placeholder="Vimeo, YouTube, etc" value={demoReel} maxLength="100" onChange={(e)=>setDemoReel(e.target.value)} />

            <label htmlFor="imdb">Link to your IMDB profile</label>
            <input className="metainput" type="text" name="imdb" placeholder="https://" value={imdbProfile} maxLength="100" onChange={(e)=>setImdbProfile(e.target.value)} />

            <label htmlFor="insta">Link to your Instagram account</label>
            <input className="metainput" type="text" name="insta" placeholder="https://" value={instagram} maxLength="100" onChange={(e)=>setInstagram(e.target.value)} />

            <label className="label-with-tip" htmlFor="about">Tell us about you
                <Tips content="How long have you been working in the industry? Telling people more about yourself will help boost your profile and increase the opportunity to get hired."/>
            </label>
            <textarea 
                    name="about" 
                    value={about} 
                    maxLength="3000" 
                    placeholder=" Maximum 500 words.
                    What is your past working experience?
                    How long have you been working in the industry?
                    What is your biggest achievement?" 
                    onChange={(e)=>setAbout(e.target.value)}
                    onFocus={()=>setCanSubmit(false)}
                    onBlur={()=>setCanSubmit(true)}
                />
            
            <label className="label-with-tip" htmlFor="skills">Qualifications & Skills
                <Tips content="List all your skills and certifications to help increase your chance to get hired! Tips: education, first aid, driving license. To add a skill enter the value and click on add skill"/>
            </label>

            <Skills
                newSkills={newSkills} 
                handleRemoveSkill={handleRemoveSkill} 
                handleNewSkills={handleNewSkills}
            />

            {ethics && <EditGenderEthics genderEthics={{gender,ethics,ethnicity}} handleGenderEthics={handleGenderEthics} />}

            <h5 className="header label-with-tip">Who have you been working with in the past 6 months?
                <Tips content="Username of producers & directors that you have been working with recently. You must enter the exact username of the desired connection"/>
            </h5>
            
            <PastConnections
                newConnections = {newConnections}
                handleNewConnections={handleNewConnections} 
                handleErrorMessage={handleErrorMessage}
                handleRemoveConnection={handleRemoveConnection}
            />    

            <p className="errorMessage">{errorMessage}</p>
            
            <div className="buttons2">
                <button type="button" onClick={()=>props.setEdit()} className="cancel">Cancel</button>
                <input type="submit" className="save" value="Save"/>
            </div>

        </form>
        
    )
}

export default EditProfile;