import CloseIcon from '@mui/icons-material/Close';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const ErrorModal = () => {
    const error_message = useSelector(state => state.error_message)
    const dispatch = useDispatch();

    return (
        <Modal className="success-modal" aria-labelledby="contained-modal-title-vcenter" centered show={error_message !== undefined}>
            <Modal.Body>
                <CloseIcon className="closeButton" onClick={()=>dispatch({type:'SHOW_ERROR', payload:undefined})} />
                <div><p>{error_message}</p></div>
            </Modal.Body>
        </Modal>
    )
}

export default ErrorModal